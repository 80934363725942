import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SeachnavModule } from './seachnav/Searchnav.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DpDatePickerModule } from 'ng2-date-picker';
import { LoaderComponent } from './loader/loader.component';
import { MatDialogModule } from '@angular/material/dialog'
import { LoaderService } from './loader/loader.service';
import { ItemdetailsComponent } from './itemdetails/itemdetails.component';
import { MessageIdPopupComponent } from './message-id-popup/message-id-popup.component';
import { AsnDetailsPopupComponent } from './asn-details-popup/asn-details-popup.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { ManufacturerModule } from './manufacturer/manufacturer.module';
import { ConfigurationScreenModule } from './configuration-screen/configuration-screen.module';
import { PaginatorModule } from './manufacturer/pagination.module';
import { ConfirmPopupComponent } from './configuration-screen/confirm-popup/confirm-popup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotifierComponent } from './notifier/notifier.component';
import { DataService } from './services/data.service';
import { StoreModule } from '@ngrx/store';
import { ediSearchDetailsReducer } from './state/reducers';
import { UserPreferenceComponent } from './user-preference/user-preference.component';
import { UserPreferenceModule } from './user-preference/user-preference.module';
import { AdminScreenModule } from './admin/admin-screen.module';
import { AdminPopupComponent } from './admin/admin-popup/admin-popup.component';
import { BasicAuthHtppInterceptorService } from './services/BasicAuthHtppInterceptorService';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderByPipeModule } from './pipes/application-pipes.module';
import { InformationComponent } from './information/information.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    ItemdetailsComponent,
    MessageIdPopupComponent,
    AsnDetailsPopupComponent,
    ConfirmPopupComponent,
    NotifierComponent,
    UserPreferenceComponent,
    AdminPopupComponent,
    InformationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SeachnavModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    HttpClientModule,
    MatPaginatorModule,
    FontAwesomeModule,
    MatTooltipModule,
    DpDatePickerModule,
    MatDialogModule,
    MatTabsModule,
    MatRadioModule,
    ManufacturerModule,
    ConfigurationScreenModule,
    PaginatorModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    UserPreferenceModule,
    AdminScreenModule,
    OrderByPipeModule,
    StoreModule.forRoot({
      ediSearchDetails: ediSearchDetailsReducer
    })
  ],
  providers: [
    DatePipe,
    LoaderService,
    DataService,
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
