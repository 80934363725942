import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { LoaderService } from "../loader/loader.service";
import { MessageIdPopupComponent } from "../message-id-popup/message-id-popup.component";
import { ApiService } from "../services/api.service";

export interface dialogData {
    response: any;
    heading: any;
    className: any;
    messagePayload: any;
    terminator: any;
    shipmentKey: any;
    controlNumber: any;
}

@Component({
    selector: 'app-asn-details-popup',
    templateUrl: './asn-details-popup.component.html',
    styleUrls: ['./asn-details-popup.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class AsnDetailsPopupComponent implements OnInit {
    temp: string;
    acknowledgeCodeMessage: string = 'null';
    asnDetailsData: any = [];
    functionalAckData: any = [];
    applicationAdviceData: any = [];
    dataSource = new MatTableDataSource<any>();
    columnNames: string[];
    nonAsnDetailsData: any;
    applicationAdviceTableData: any = [];
    rowNum: any;
    showDiv = [];
    notes: string[][] = [[],[]];
    constructor(
        private dialogRef: MatDialogRef<AsnDetailsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: dialogData,
        private apiService: ApiService,
        private dialog: MatDialog,
        private loadingService: LoaderService
    ) { }
    /**
     * In this medthod, Popup data has been processed to display in HTML
     */
    ngOnInit() {
        let messagePurposeCode;
        const headings = {
            MessageId: "Message Id",
            ShipperNumber: "Message/Shipper Number",
            CreatDateTimeFormatted: "Creation Date & Time",
            ShippedDateTimeFormatted: "Ship Date & Time",
            ShipTo: "Ship To",
            ShipFrom: "Ship From",
            MessagePurposeCode: "Message Purpose Code"
        };

        Object.entries(headings).forEach(([headKey, headVal]) => {
            Object.entries(this.data.response).forEach(([dataKey, dataVal]) => {
                if (this.data.response[headKey] !== undefined) {
                    if (headKey === dataKey) {

                        if (headKey === 'MessagePurposeCode') {
                            if (this.data.className == 'fa fa-check' || this.data.className == 'fa fa-times') {
                                this.apiService.getMessagePurposeCode(this.data.response['MessagePurposeCode']).subscribe((response: any = []) => {
                                    messagePurposeCode = response.Val;
                                    const data = {};
                                    data['key'] = headVal;
                                    data['value'] = messagePurposeCode && messagePurposeCode != "null" ? dataVal + ' - ' + messagePurposeCode : dataVal;
                                    this.asnDetailsData.push(data);
                                    // Adding empty object in order to maintain proper css on UI
                                    this.asnDetailsData.push({});
                                });
                            }
                        } else {
                            const data = {};
                            data['key'] = headVal;
                            data['value'] = dataVal;
                            this.asnDetailsData.push(data);

                        }
                    }
                }
            });
        })

        // fa-times is class name sent in case of "Functional Acknowledgement"
        if (this.data.className === 'fa fa-times') {
            this.functionalAckData.push(
                { key: 'Message Id', value: this.data.response['FaMessageId'] },
                { key: 'Group Control Number', value: this.data.response['FaGroupControlNumber'] },
                { key: 'Functional Identifier', value: this.data.response['FaGroupFunctionalId'] },
                { key: 'Document Type', value: this.data.response['FaDocTypeName'] },
                { key: 'Control Number', value: this.data.response['FaMessageSetNumber'] },
                {}
            );
            this.nonAsnDetailsData = this.functionalAckData;

        } else {
            this.apiService.getAppAdviceErrorDescription(this.data.response['AdviceErrorCode']).subscribe((data: any = []) => {
                let errorDescription = data.Val;
                // null check condition for Error Code
                if (this.data.response['AdviceErrorCode'] && errorDescription ) {
                    this.applicationAdviceData.push(
                        { key: 'Message Id', value: this.data.response['AdviceMessageId'] },
                        { key: 'Reference Number', value: this.data.response['AdviceMessageNumber'] },
                        { key: 'Creation Date & Time', value: this.data.response['AdviceCreatedDateTimeFormatted'] },
                        { key: 'Error Code', value: this.data.response['AdviceErrorCode'] + ' - ' + errorDescription }
    
                    );
                    }
                    else {
                        this.applicationAdviceData.push(
                            { key: 'Message Id', value: this.data.response['AdviceMessageId'] },
                            { key: 'Reference Number', value: this.data.response['AdviceMessageNumber'] },
                            { key: 'Creation Date & Time', value: this.data.response['AdviceCreatedDateTimeFormatted'] },
                            { key: 'Error Code', value: ' ' }
                        );
                        }
            });
            this.nonAsnDetailsData = this.applicationAdviceData;
            this.apiService.getApplicationAdviceTableData(this.data.shipmentKey).subscribe((response: any) => {
                this.processApplicationAdviceTableData(response?.applicationAdviceDetails);
            });
            this.columnNames = ['Acknowledgement Code', 'Application Description', 'Error Code', 'Error Description', 'Part Number'];
        }
    }

    /**
     * Method is used to close popup
     */
    closePopup = () => {
        this.dialogRef.close();
    }

    /**
     * Method is used to open popup and display required data to the user
     * @param data - Message Id for which data has to be fetched from API (Ex: 'AQ1AMHRKL9')
     * @param payloadType - Defined which payload is been used (Ex: 'Common', 'sender')
     */
    openPopUp = (data, payloadType) => {
        let messageType;
        if (this.data.className === 'fa fa-times') {
            messageType = 'Functional Acknowledgment';
        } else {
            messageType = 'Application Advice';
        }
        this.apiService
            .getMessageIdData(data)
            .subscribe((messageData) => {
                const popUpData = {};
                popUpData['payload'] = payloadType;
                popUpData['responseData'] = messageData;
                popUpData['terminator'] = this.data.terminator;
                popUpData['messageType'] = messageType;
                popUpData['controlNumber'] = this.data.controlNumber;
                this.dialog.open(MessageIdPopupComponent, {
                    data: popUpData,
                    disableClose: true,
                    width: '70em',
                    minHeight: 'calc(100vh - 230px)',
                });
                this.loadingService.closeLoadingIndicator();
            });
    }

    /**
     * Method is used to format the data and assign to datatable inorder to dispay in HTML
     * @param data - Response from API for forming Table data
     */
    processApplicationAdviceTableData = (data) => {
        const headers = {
            AckCode: 'Acknowledgement Code',
            AppDesc: 'Application Description',
            ErrorCode: 'Error Code',
            ErrorDesc: 'Error Description',
            ItemNumber: 'Part Number',
            DetailNotes: 'Notes'
        };
        let obj = {};
        data.forEach(dataVal => {
            Object.entries(headers).forEach(([headKey, headVal]) => {
                if (dataVal[headKey] !== undefined && !(dataVal[headKey]?.includes(this.acknowledgeCodeMessage))) {
                    obj[headVal] = dataVal[headKey];
                //whenever acknowledge code value is null i.e., associated with extra characters example:null(RE) it should be displayed as only RE
                } else if(dataVal[headKey]?.includes(this.acknowledgeCodeMessage)) {
                    this.temp = dataVal[headKey].replace(this.acknowledgeCodeMessage, "");
                    obj[headVal] = this.temp.replace(/[^a-zA-Z ]/g, "");
                }
                 else {
                    obj[headVal] = null;
                }
            });
            const copiedObject = Object.assign({}, obj);
            this.applicationAdviceTableData.push(copiedObject);
        });
        //this.applicationAdviceTableData = this.fetchErrorDescription(this.applicationAdviceTableData);
        this.expandRowsAndShowNotes(this.applicationAdviceTableData);
        this.dataSource = this.applicationAdviceTableData;
        // this.applicationAdviceTableData.forEach(element => {
        //     this.columnNames.forEach(colname => {
        //       if (element[colname] && element[colname] === "null") {
        //         element[colname] = '';
        //       }
        //     })
        //   });

    };

    fetchErrorDescription = (tableData) => {
        let errorDesc = {};
        tableData.forEach(tableRow => {
            if (errorDesc[tableRow['Error Code']] === undefined ) {
                if (errorDesc[tableRow['Error Description']] !== undefined ){
                this.apiService.getAppAdviceErrorDescription(tableRow['Error Code']).subscribe((response: any) => {
                    if (response !== undefined && response.Val !== undefined) {
                        tableRow['Error Description'] = response.Val;
                        errorDesc[tableRow['Error Code']] = response.Val;
                    }
                });
            }
            else {
                tableRow['Error Description'] = '';
            }
            }
            else{
                tableRow['Error Description'] = errorDesc[tableRow['Error Code']];
            }
            
        });
        return tableData;
    }

    expandRowsAndShowNotes = (tableData) => { 
        let rowIndex = 0;  
        tableData.forEach(rowElement => {
            this.notes[rowIndex] = [];
            rowElement['Notes'].forEach((element,index) => {
                this.notes[rowIndex][index] = element['NoteData'];       
                this.showDiv[rowIndex] = true;
            });
            rowIndex++;            
        });
    }
}