import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from 'src/app/loader/loader.service';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { popup } from '../../config/pop-up-content.json';
import { NotificationService } from '../../services/notification.service';
import { DataService } from '../../services/data.service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

export interface DialogData { }

@Component({
  selector: 'app-config-fields',
  templateUrl: './config-fields.component.html',
  styleUrls: ['./config-fields.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigFieldsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: any;
  @Input() index: any;
  @Input() dataReferenceData: any;
  @Output() closeDivEmission = new EventEmitter();
  @Input() configData: any;
  @Output() configResponse = new EventEmitter();
  @Output() onPopUpClosed = new EventEmitter();
  @Output() onShowTabs = new EventEmitter<boolean>();

  linkableDisplayOrder: any;
  changeDetailDisplayOrder: any;
  communityId: any = 'default';
  applicationName: any = 'default';
  messageType: any = 'default';
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  searchFieldsArray = [{}];
  detailArray = [{}];
  headerArray = [{}];
  detailLength = 6;
  headerLength = 16;
  communityArray = [];
  applicationArray = [];
  messageTypeArray = [];
  displayOrder = [];
  displayOrderError = [];
  dataReference = [];
  dataReferenceError = [];
  displayName = [];
  displayNameError = [];
  helpText = [];
  helpTextError = [];
  staticFieldsArray: any;
  dynamicFieldDisplayOrder = [];
  dynamicFieldDisplayOrderError = [];
  dynamicFieldDisplayOrderDuplicateError = [];
  dynamicFieldDisplayOrderValidationError = [];
  dynamicFieldDataReference = [];
  dynamicFieldDataReferenceError = [];
  dynamicFieldDataReferenceDuplicateError = [];
  dynamicFieldDisplayName = [];
  dynamicFieldDisplayNameError = [];
  dynamicFieldDisplayNameDuplicateError = [];
  dynamicFieldHelpText = [];
  dynamicFieldHelpTextError = [];
  headerDataReference = [];
  headerDataReferenceError = [];
  headerDataReferenceDuplicateError = [];
  headerDisplayName = [];
  headerDisplayNameError = [];
  headerDisplayNameDuplicateError = [];
  headerColumnWidth = [];
  headerColumnWidthError = [];
  headerColumnWidthValidationError = [];
  headerDisplayOrder = [];
  headerDisplayOrderError = [];
  headerDisplayOrderDuplicateError = [];
  headerSortable = [];
  headerSortableError = [];
  headerLinkReference = [];
  detailDataReference = [];
  detailDataReferenceError = [];
  detailDataReferenceDuplicateError = [];
  detailDisplayName = [];
  detailDisplayNameError = [];
  detailDisplayNameDuplicateError = [];
  detailDisplayOrder = [];
  detailDisplayOrderError = [];
  detailDisplayOrderDuplicateError = [];
  formStatus = [false];
  rowCount = 0;
  buttonText: any = 'Next';
  searchFieldsDataRefList: any;
  resultFieldsDataRefList: any;
  detailFieldsDataRefList: any;
  isLinkSelected = [];
  searchFields: any;
  showDetail = false;
  allowEditing = [];
  headerStatus = [false];
  headerCount = 0;
  detailStatus = [false];
  detailCount = 0;
  detailFields: any;
  disableLink = [];
  staticRequiredFields = ['Application Name', 'Message Type'];
  reqFieldDataReference = ['meta.app', 'meta.msgtyp'];
  reqStaticDisplayName = [];
  reqStatichelpText = [];
  reqStaticFieldsArray = [];
  showTabs = false;
  selectedTab = 0;
  searchTabData: any;
  configurationName: any;
  applicationFullName: any;
  messageTypeFullName: any;
  communityFullName: any;
  itemDetailTitle: any;
  asnDashBoardEnabled : String = '';
  virtualCoumn = [];
  indexColumn = [];
  searchFieldPayload = [];
  headerFieldPayload = [];
  detailFieldPayload = [];
  dynamicFieldsValid = false;
  staticFieldsValid = false;
  headerTabValid = false;
  configTabsStatus = [];
  detailTabValid = false;
  auditNotes: any;
  staticSearchFields: any;
  searchTabDisplayOrderList = [];
  searchTabDataRefList = [];
  headerTabDisplayOrderList = [];
  headerTabDataRefList = [];
  detailTabDisplayOrderList = [];
  detailTabDataRefList = [];
  configStatus: any;
  isConfigValid: any;
  dataServiceSubscription: Subscription;
  fileUploaded: any;
  importedConfig: any;
  // staticHeaderFields = [...ConfigScreenData.headerTabStaticFields];

  @ViewChildren('headerDisplayOrderEle') headerDisplayOrderList: QueryList<any>;
  @ViewChildren('detailDisplayOrderEle') detailDisplayOrderList: QueryList<HTMLCollection>;
  @ViewChildren('displayOrderEle') displayOrderList: QueryList<HTMLCollection>;
  @ViewChildren('dynamicDisplayOrderEle') dynamicDisplayOrderList: QueryList<HTMLCollection>;
  @ViewChild('auditNoteRef') auditNotesRef;
  @ViewChild('importFile') fileUpload;

  private dialogRef = null;
  private dialogData;
  selectedCommunityValue: any;
  logs: any;
  headerLinkDataRefPayloadTypeError = [];
  headerLinkdataRefPayloadType = [];
  tabLabel: any;
  detailFieldHeaderDataRef: any;
  duplicateStaticValue = [];
  headerTabStaticData: any[];
  searchTabStaticData: any[];
  searchTabStaticFields=[];
  headerTabStaticFields=[];
  

  constructor(
    private _cd: ChangeDetectorRef,
    private apiService: ApiService,
    private loadingService: LoaderService,
    private injector: Injector,
    private dataService: DataService,
    private dialog: MatDialog,
    private router: Router,
    private notifyService: NotificationService
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.dataServiceSubscription = this.dataService.configurationSaveClick$.subscribe(data => {
      this.validateOnButtonClick(data);
    });
  }

  ngOnInit(): void {
    this.dataService.getConfigurationButtonName('');
    this.fetchDataReferenceList();
    this.assignInitialValues();
    this.prefillConfigData();
    this.getCommunityDetails();
    this.getMessageDetails();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      map(value => this._filter(value))
    );
  }

  updateRequiredStaticFields = () => {
    const requiredStaticSearchFields = [];
    requiredStaticSearchFields.push(
      { displayOrder: 1, dataReference: 'meta.app', displayName: 'Message Application', helpText: 'Select the GM application (i.e. MGO NA) from the drop down list.' },
      { displayOrder: 2, dataReference: 'meta.msgtyp', displayName: 'Message Type', helpText: 'Select the Message Type from the drop down list.' }
    );
    return requiredStaticSearchFields;
  }

  assignInitialValues = () => {
    // this.headerArray = [...ConfigScreenData.headerTabStaticData];
    this.headerArray = this.headerTabStaticData;
    // this.staticFieldsArray = ConfigScreenData.searchTabStaticData;
    this.staticFieldsArray = this.searchTabStaticData;
    //this.staticSearchFields = ConfigScreenData.searchTabStaticFields;
    this.staticSearchFields = this.searchTabStaticFields
    this.reqStaticFieldsArray = this.updateRequiredStaticFields();
    this.searchTabDataRefList.push('meta.app');
    this.searchTabDataRefList.push('meta.msgtyp');
    this.reqStaticDisplayName[0] = 'Message Application';
    this.reqStaticDisplayName[1] = 'Message Type';
    this.reqStatichelpText[0] = 'Select the GM application (i.e. MGO NA) from the drop down list.';
    this.reqStatichelpText[1] = 'Select the Message Type from the drop down list.';
    this.searchTabDisplayOrderList.push(1);
    this.searchTabDisplayOrderList.push(2);
    for (let i = 0; i < this.headerLength; i++) {
      this.headerDataReference[i] = 'default';
    }
    for (let i = 0; i < this.detailLength; i++) {
      this.detailDataReference[i] = 'default';
    }
    this.dynamicFieldDataReference[0] = 'default';
    this.dynamicFieldDisplayOrder[0] = 3;
    this.searchTabDisplayOrderList.push(parseInt(this.dynamicFieldDisplayOrder[0], 0));
    this.searchTabDataRefList.push(this.dynamicFieldDataReference[0]);
    this.staticFieldsArray.forEach((val, index) => {
      this.displayName[index] = val.displayName;
      if (this.data === 'New') {
        this.displayOrder[index] = val.displayOrder;
        this.searchTabDisplayOrderList.push(parseInt(this.displayOrder[index], 0));
      }
      this.helpText[index] = val.helpText;
      this.dataReference[index] = val.dataReference;
      this.searchTabDataRefList.push(this.dataReference[index]);
    });
    this.selectedTab = 0;
    this.configTabsStatus = [];
    if (this.data === 'New') {
      // this.headerArray = [...ConfigScreenData.headerTabStaticData];
      this.headerArray = this.headerTabStaticData;
      this.headerArray.forEach((val, index) => {
        this.headerDisplayOrder[index] = val['displayOrder'];
        this.headerDataReference[index] = val['dataReference'];
        this.headerDisplayName[index] = val['displayName'];
        this.headerColumnWidth[index] = val['headerColumnWidth'];
        /** To set default Header column Width to 210 . */
        // if(val['headerColumnWidth'] === 0){
        //   this.headerColumnWidth[index] = 210;
        // }
        // else {
        //   this.headerColumnWidth[index] = val['headerColumnWidth'];
        // }
        this.headerSortable[index] = val['sortable'];
        this.headerStatus[index] = true;

        if (this.headerDataReference[index] === 'meta.msgId') {
          this.headerLinkdataRefPayloadType[index] = 'sender';
        }
        this.headerTabDataRefList[index] = this.headerDataReference[index];
        this.headerTabDisplayOrderList[index] = parseInt(this.headerDisplayOrder[index], 0);
      });
      this.headerCount = this.headerArray.length - 1;
    } else {
      this.headerArray = [];
    }
  }

  prefillConfigData = () => {
    if (this.configData !== undefined) {
      this.loadingService.openLoadingIndicator();
      this.showTabs = true;
      this.onShowTabs.emit(true);
      const configName = this.configData.configuration.configNme;
      this.configStatus = this.configData.status !== undefined ? this.configData.status : '';
      this.logs = this.configData?.auditNotes;
      this.configurationName = configName;
      this.communityId = configName.split('_')[0];
      this.applicationName = configName.split('_')[1];
      this.messageType = configName.split('_')[2] + '_' + configName.split('_')[3];
      this.searchFields = this.configData.configuration.searchFields;
      this.searchFields.sort((a: any, b: any) => {
        return a.displayOrder - b.displayOrder;
      });
      const headerResult = this.configData.configuration.dataViews.searchResult.fields;
      headerResult.sort((a: any, b: any) => {
        return a.columnPosition - b.columnPosition;
      });
      const staticSearch = this.searchFields.slice(-5);
      const dynamicFields = this.searchFields.slice(2, -5);
      const reqStaticFields = this.searchFields.slice(0, 2);
      this.searchFields.forEach((searchVal, searchIndex) => {
        if (searchIndex > 1) {
          this.searchTabDisplayOrderList[searchIndex] = parseInt(searchVal['displayOrder'], 0);
          this.searchTabDataRefList[searchIndex] = searchVal['dataReference'];
        }
      });
      for (let i = 0; i < dynamicFields.length - 1; i++) {
        this.searchFieldsArray.push({});
      }
      this.searchFieldsArray = dynamicFields;
      this.rowCount = dynamicFields.length - 1;
      this.searchFieldsArray.forEach((val, index) => {
        dynamicFields.forEach((sVal, sIndex) => {
          if (index === sIndex) {
            this.dynamicFieldDisplayName[index] = sVal.displayName;
            this.dynamicFieldDisplayOrder[index] = sVal.displayOrder;
            this.dynamicFieldHelpText[index] = sVal.helpText;
            this.dynamicFieldDataReference[index] = sVal.dataReference;
            this.formStatus[index] = true;
          }
        });
      });
      this.reqFieldDataReference.forEach((val, index) => {
        reqStaticFields.forEach(sVal => {
          if (val === sVal.dataReference) {
            this.reqStaticDisplayName[index] = sVal.displayName;
            this.reqStatichelpText[index] = sVal.helpText;
            this.reqFieldDataReference[index] = sVal.dataReference;
          }
        });
      });
      const lastDynamicFieldOrder = parseInt(this.dynamicFieldDisplayOrder[this.rowCount], 0) + 1;
      this.staticFieldsArray.forEach((val, index) => {
        staticSearch.forEach((sVal) => {
          if (val.dataReference === sVal.dataReference && val.displayName === sVal.displayName) {
            this.helpText[index] = sVal.helpText;
            this.staticFieldsArray[index]['helpText'] = sVal.helpText;
          }
          if (index === 0 || index === '0') {
            this.displayOrder[index] = lastDynamicFieldOrder;
          } else {
            this.displayOrder[index] = parseInt(this.displayOrder[index - 1], 0) + 1;
          }
        });
      });
      this.staticFieldsArray = [...staticSearch];
      let headerCounter = 0;
      headerResult.forEach((sVal) => {
        if (sVal.displayName) {
          this.headerCount = this.headerCount + 1;
          if (this.ispopUpDataViewReference(sVal.dataReference) === true) {  
            this.isLinkSelected[headerCounter] = true;
            if (sVal.link !== null) {
              this.headerLinkReference[headerCounter] = true;
              this.detailFieldHeaderDataRef = sVal.dataReference;
              this.showDetail = true;
              this.disableLink[headerCounter] = true;
              this.detailFields = this.configData.configuration.dataViews.itemDetail.fields;
            }
          }
          this.headerDataReference[headerCounter] = sVal.dataReference;
          this.headerDisplayName[headerCounter] = sVal.displayName;
          this.headerDisplayOrder[headerCounter] = sVal.columnPosition;
          this.headerColumnWidth[headerCounter] = sVal.headerColumnWidth;
          /** To set default Header column Width to 210 . */
          // if(sVal.headerColumnWidth !== 0){
          //   this.headerColumnWidth[headerCounter] = sVal.headerColumnWidth;
          // }
          // else{
          //   this.headerColumnWidth[headerCounter] = 210;
          // }   
          if(sVal.headerColumnWidth !== 0){
            this.headerColumnWidthValidationError[headerCounter] = false;
          }
          else{
            this.headerColumnWidthValidationError[headerCounter] = true;
          }             
          this.headerSortable[headerCounter] = sVal.sortable.toString();
          this.headerStatus[headerCounter] = true;
          const header = { ...sVal };
          header["displayOrder"] = this.headerDisplayOrder[headerCounter];
          header["headerColumnWidth"] = this.headerColumnWidth[headerCounter];
          delete header["columnPosition"]; //Delete the columnPosition
          this.headerTabDisplayOrderList[headerCounter] = this.headerDisplayOrder[headerCounter];
          this.headerTabDataRefList[headerCounter] = this.headerDataReference[headerCounter];
          if (header['dataReference'] === 'meta.msgId' && header.link !== null) {
            this.headerLinkdataRefPayloadType[headerCounter] = header.link.payload[1];
            header["messagePayloadType"] = this.headerLinkdataRefPayloadType[headerCounter];
          }
          if (sVal.dataReference === 'meta.dlvyStat' || sVal.dataReference === 'meta.dlvyDte') {
            this.headerSortable[headerCounter] = false;
          }
          this.headerArray.push(header);
          headerCounter++;
        }
      });
      // this.staticHeaderFields.forEach((event, index) => {
        this.headerTabStaticFields.forEach((event) => {
        let counter = 0;
        this.headerArray.forEach((val,index) => {
          if (event === val['dataReference']) {
            counter++;
            if (counter > 1) {
              this.duplicateStaticValue[index] = true;
            }
          }
        });
      });
      

      const linkableDatRefList = this.resultFieldsDataRefList.filter(x => x.dataViewResultsDetail === 'T');
      if (linkableDatRefList.length > 0) {
        linkableDatRefList.forEach(val => {
          this.headerArray.forEach((existingVal, index) => {
            if (val.jsonTag === existingVal['dataReference']) {
              this.isLinkSelected[index] = true;
            }
          });
        });
      }

      // this.headerCount = this.data === "New_Import" ? this.headerCount - ConfigScreenData.headerTabStaticData.length : this.headerCount - 1;
      this.headerCount = this.data === "New_Import" ? this.headerCount - this.headerTabStaticData.length : this.headerCount - 1;
      
      if (this.showDetail) {
        if (this.detailFields.length === 1) {
          this.detailFields.push({ columnPosition: 2, dataReference: 'default', sortable: 'default' })
        }
        this.detailFields.sort((a: any, b: any) => {
          return a.columnPosition - b.columnPosition;
        });
        this.detailFields.forEach((sVal, sIndex) => {
          this.detailStatus[sIndex] = true;
          this.detailDataReference[sIndex] = sVal.dataReference;
          this.detailDisplayName[sIndex] = sVal.displayName;
          this.detailDisplayOrder[sIndex] = sVal.columnPosition;
          this.detailArray[sIndex] = {};
          this.detailArray[sIndex]['displayOrder'] = sVal.columnPosition;
          this.detailArray[sIndex]['dataReference'] = sVal.dataReference;
          this.detailArray[sIndex]['displayName'] = sVal.displayName;
          if (this.detailFieldHeaderDataRef === sVal.dataReference) {
            this.detailArray[sIndex]['linkable'] = true;
          }

          this.detailTabDisplayOrderList[sIndex] = sVal.columnPosition;
          this.detailTabDataRefList[sIndex] = sVal.dataReference;
        });
        this.detailCount = this.detailFields.length - 1;
      }
      this.loadingService.closeLoadingIndicator();
    }
  }

  fetchDataReferenceList = () => {
    const arr1 = [];
    const arr2 = [];
    let headerStaticDisplayorder = 1;
    let searchStaticDisplayorder = 4;
    const response: any = JSON.parse(sessionStorage.getItem('DataReferenceList'));
    this.searchFieldsDataRefList = response?.dataReference?.search ? (response.dataReference.search.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
    this.searchFieldsDataRefList.forEach((element) => {
      const obj = {};
      const obj1 = {};
      if (element.searchField === "S") {
        if (element.jsonTag !== "meta.app" && element.jsonTag !== "meta.msgtyp") {
          if (element.jsonTag !== "meta.sentDte") {
            obj['displayOrder'] = searchStaticDisplayorder;
            obj['dataReference'] = element.jsonTag;
            obj['displayName'] = element.displayName;
            obj['helpText'] = element.defaultHelpText;
            arr2.push(obj);
            this.searchTabStaticFields[searchStaticDisplayorder-4] = element.displayName;
            searchStaticDisplayorder = searchStaticDisplayorder + 1;
          } else {
            obj['displayOrder'] = searchStaticDisplayorder;
            obj['dataReference'] = element.jsonTag;
            obj['displayName'] = "Start Date/Time";
            obj['helpText'] = element.defaultHelpText;
            arr2.push(obj);
            this.searchTabStaticFields[searchStaticDisplayorder-4] = "Start Date/Time";
            obj1['displayOrder'] = searchStaticDisplayorder + 1;
            obj1['dataReference'] = element.jsonTag;
            obj1['displayName'] = "End Date/Time";
            obj1['helpText'] = element.defaultHelpText;
            arr2.push(obj1);
            this.searchTabStaticFields[searchStaticDisplayorder -3] = "End Date/Time";
            searchStaticDisplayorder = searchStaticDisplayorder + 1;
          }
        }
      }
      this.searchTabStaticData = arr2;
    });
    this.resultFieldsDataRefList = response?.dataReference?.result ? (response.dataReference.result.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
    this.resultFieldsDataRefList.forEach((element) => {
      const obj = {};
      if (element.resultField === "S") {
        if (element.jsonTag !== 'meta.msgId') {
          obj['displayOrder'] = headerStaticDisplayorder;
          obj['dataReference'] = element.jsonTag;
          obj['displayName'] = element.displayName;
          obj['headerColumnWidth'] = element.headerColumnWidth;
          // obj['headerColumnWidth'] = 210;
          obj['sortable'] = "false";
          arr1.push(obj);
          this.headerTabStaticFields[headerStaticDisplayorder-1] = element.jsonTag;
          headerStaticDisplayorder = headerStaticDisplayorder + 1;          
        }
        else {
          obj['displayOrder'] = headerStaticDisplayorder;
          obj['dataReference'] = element.jsonTag;
          obj['displayName'] = element.displayName;
          obj['headerColumnWidth'] = element.headerColumnWidth;
          // obj['headerColumnWidth'] = 210;
          obj['sortable'] = "false";
          obj['messagePayloadType'] = "sender";
          arr1.push(obj);
          this.headerTabStaticFields[headerStaticDisplayorder-1] = element.jsonTag;
          headerStaticDisplayorder = headerStaticDisplayorder + 1;         
        }
      }
      this.headerTabStaticData = arr1;
    });
    this.detailFieldsDataRefList = response?.dataReference?.dataView ? (response.dataReference.dataView.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.messageTypeArray.filter(option => option.toLowerCase().includes(filterValue));
  }

  displayFunction = (messageTypeArray) => {
    return messageTypeArray ? messageTypeArray.shortName : undefined;
  }

  getCommunityValue = (event) => {
    this.communityId = event;
    const selectedValue = this.communityArray.filter(val => val.shortName === event)[0];
    this.selectedCommunityValue = selectedValue.commId;
    this.getApplicationDetails(this.selectedCommunityValue);
    this.communityFullName = selectedValue['communityName'];
    this.callConfigValidationApi();
  }

  getApplicationValue = (event) => {
    this.applicationName = event;
    const appName = this.applicationArray.filter(x => x.shortName === event)[0];
    this.applicationFullName = appName['applicationName'];
    this.callConfigValidationApi();
  }

  getMessageValue = (event) => {
    this.messageType = event;
    const msgTyp = this.messageTypeArray.filter(x => x.shortName === event)[0];
    this.messageTypeFullName = msgTyp['messageType'];
    this.itemDetailTitle = msgTyp['defaultPopUpWindowTitle'];
    this.asnDashBoardEnabled = msgTyp.asnDashBoardEnabled;
    this.callConfigValidationApi();
  }

  callConfigValidationApi = () => {
    if (
      this.communityId !== undefined &&
      this.communityId !== 'default' &&
      this.applicationName !== undefined &&
      this.applicationName !== 'default' &&
      this.messageType !== undefined &&
      this.messageType !== 'default' &&
      (this.data === 'New' || this.data === 'New_Import')
    ) {
      const configName = this.communityId.split(/[ ]+/, 1) + '_' + this.applicationName + '_' + this.messageType;
      this.configurationName = configName;
      this.apiService.getConfigurationStatus(configName).subscribe((res: any = []) => {
        this.isConfigValid = true;
        this.dataService.getConfigurationButtonName('Next');
        if (this.showTabs) {
          this.removeSearchTabFields();
          this.removeHeaderTabFields();
        }
      }, (error) => {
        this.isConfigValid = false;
        if (error.error.message != null) {
          this.showTabs = false;
          this.dataService.getConfigurationButtonName('');
          this.showConfigExits();
        }
      });
    }
  }

  //Refreshing default entries for Search tab
  removeSearchTabFields = () => {
    const searchFieldsLength = this.searchFieldsArray.length;
    for (let i = searchFieldsLength - 1; i > 0; i--) {
      this.removeSearchField(i, true);
    }
    this.rowCount = 0;
    this.dynamicFieldDataReference[0] = 'default';
    this.dynamicDisplayOrderList[0] = this.reqStaticFieldsArray.length + 1;
    this.dynamicFieldDisplayName[0] = '';
    this.formStatus[this.rowCount] = false;
  }
  //Refreshing default entries for header tab
  removeHeaderTabFields = () => {
    const headerFieldLength = this.headerArray.length;
    for (let i = headerFieldLength - 1; i > 3; i--) {
      this.removeHeaderField(i);
    }
    // this.headerDisplayOrder[4] = ConfigScreenData.headerTabStaticData.length + 1;
    this.headerDisplayOrder[4] = this.headerTabStaticData.length + 1;
    this.headerDisplayName[4] = '';
    this.headerDataReference[4] = 'default';
  }

  onFileUpload = (event) => {
    if (event.length > 0) {
      if(event[0].type === "application/json")
      {
      this.fileUploaded = event[0];
      const formData = new FormData();
      formData.append('file', this.fileUploaded);
      this.loadingService.openLoadingIndicator();
      this.apiService.importConfiguration(formData, this.configurationName, this.applicationFullName, this.messageTypeFullName).subscribe((res) => {
        this.configData = { configuration: {} };
        this.configData.configuration = res;
        this.loadingService.closeLoadingIndicator();
        this.showTabs = true;
        this.onShowTabs.emit(true);
        this.data = 'New_Import';
        this.headerArray = [];
        this.prefillConfigData();
        this.notifyService.showNotification(popup.importSuccessMessage, 'success');
      }, (error) => {
        this.loadingService.closeLoadingIndicator();
        this.showTabs = false;
        this.onShowTabs.emit(false);
        if (error !== undefined && error.error !== undefined && error.error.message !== null) {
          this.notifyService.showNotification(error.error.message, 'error');
        } 

      });
     }
     else {
      this.loadingService.closeLoadingIndicator();
      this.showTabs = false;
      this.onShowTabs.emit(false);
      this.notifyService.showNotification(popup.importFailureMessage, 'error');
     }

    }
  }

  createConfig = () => {
    this.showTabs = true;
    this.onShowTabs.emit(true);
    this.focusSelectedTab({ matTabName: 'Search' });
  }

  importConfig = () => {
    this.fileUpload.nativeElement.value = '';
    document.getElementById('import-file').click();
  }

  showConfigExits = () => {
    if (this.communityId !== undefined && this.applicationName !== undefined && this.messageType !== undefined) {
      const configName = this.communityId.split(/[ ]+/, 1) + '_' + this.applicationName + '_' + this.messageType;
      this.notifyService.showConfigNotification(popup.configurationExits, configName, 'info');
    }
  }

  getCommunityDetails = () => {
    this.apiService.getCommunity().subscribe((response: any) => {
      if (response !== undefined && response !== null) {
        this.communityArray = response['communities'];
        if (this.data !== 'New') {
          this.getCommunityValue(this.communityId);
        }
      }
    });
  }

  getApplicationDetails = (val: any) => {
    this.loadingService.openLoadingIndicator();
    this.apiService.getApplication(val).subscribe((response: any) => {
      if (response !== undefined && response !== null) {
        this.applicationArray = response['applications'];
        this.loadingService.closeLoadingIndicator();
      }
    });
  }

  getMessageDetails = () => {
    this.apiService.getMessage().subscribe((response: any) => {
      if (response !== undefined && response !== null) {
        this.messageTypeArray = response['messages'];
      }
    });
  }

  ngAfterViewInit(): void {
    this.detailArray[0]['displayOrder'] = 1;
    this._cd.detectChanges();
  }

  close = () => {
    this.closeDivEmission.emit(false);
    if (
      document.getElementsByClassName('expandible-row-div') !== undefined &&
      document.getElementsByClassName('expandible-row-div')[this.index] !== undefined
    ) {
      document.getElementsByClassName('expandible-row-div')[this.index]['style'].height = '0';
    }
    let currentUrl = this.router.url; 
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  allowOnlyCharacters = (event) => {
    if (event.type === "paste") {
      return this.allowOnlyCharactersOnPaste(event);
    } else {
      return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode === 32);
    }
  }

  allowOnlyCharactersOnPaste = (event) => {
    const pastedText = event.clipboardData.getData('Text');
    if (pastedText) {
      for (let i = 0; i < pastedText.length; i++) {
        const charCode = pastedText.charCodeAt(i);
        if (!((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode === 32))) {
          return false;
        }
      }
    }
    return true;
  }

  allowOnlyNumbers = (event) => {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  addSearchField = () => {
    if (this.rowCount < 6) {
      this.searchFieldsArray.push({});
      this.formStatus[this.rowCount + 1] = false;
      this.rowCount = this.rowCount + 1;
      this.dynamicFieldDataReference[this.rowCount] = 'default';
      if (this.data === 'Edit' || this.data === 'Import' || this.data === 'New_Import') {
        this.allowEditing[this.rowCount] = true;
      }
      if (this.rowCount > 0) {
        // this.dynamicFieldDisplayOrder[this.rowCount] = parseInt(this.dynamicFieldDisplayOrder[this.rowCount - 1], 0) + 1;
        this.dynamicFieldDisplayOrder[this.rowCount] = this.rowCount + 3;
      } else {
        this.dynamicFieldDisplayOrder[this.rowCount] = 3;
      }
      // this.searchTabDataRefList[this.rowCount + 3] = 'default';
      this.searchTabDataRefList.splice(-5, 0, 'default');
      this.staticFieldsArray.forEach((val, index) => {
        this.displayOrder[index] = parseInt(this.displayOrder[index], 0) + 1;
        this.searchTabDisplayOrderList[this.rowCount + index + 3] = parseInt(this.displayOrder[index], 0);
      });
      setTimeout(() => {
        this.displayOrderList.forEach((val) => {
          if (val !== undefined) {
            val['nativeElement'].focus();
            val['nativeElement'].blur();
          }
        });
        this.dynamicDisplayOrderList['_results'][this.rowCount].nativeElement.focus();
      });
    }
  }

  removeSearchField = (index: number, onAppOrMsgTypeChange: boolean) => {
    this.searchFieldsArray.splice(index, 1);
    this.formStatus.splice(index, 1);
    this.rowCount = this.rowCount - 1;
    this.dynamicFieldDisplayOrder.splice(index, 1);
    this.dynamicFieldDataReference.splice(index, 1);
    this.dynamicFieldDisplayName.splice(index, 1);
    this.dynamicFieldHelpText.splice(index, 1);
    this.dynamicFieldDisplayOrderError.splice(index, 1);
    this.dynamicFieldDisplayOrderDuplicateError.splice(index, 1);
    this.dynamicFieldDataReferenceError.splice(index, 1);
    this.dynamicFieldDataReferenceDuplicateError.splice(index, 1);
    this.dynamicFieldDisplayNameError.splice(index, 1);
    this.searchTabDisplayOrderList.pop();
    for (let i = index; i <= this.rowCount; i++) {
      this.dynamicFieldDisplayOrder[-1] = 2;
      this.dynamicFieldDisplayOrder[i] = parseInt(this.dynamicFieldDisplayOrder[i - 1], 0) + 1;
      this.searchFieldsArray[i]['displayOrder'] = this.dynamicFieldDisplayOrder[i];
    }
    this.staticFieldsArray.forEach((val, index) => {
      this.displayOrder[index] = parseInt(this.displayOrder[index], 0) - 1;
      this.searchTabDisplayOrderList[this.rowCount + index + 3] = this.displayOrder[index];
      this.staticFieldsArray[index]['displayOrder'] = this.displayOrder[index];
    });
    if (this.rowCount < 0 && !onAppOrMsgTypeChange) {
      this.openAlertPopup('Atleast one dynamic search field is mandatory. Please add search field to continue.');
    }
    this.searchTabDataRefList.splice(index + 2, 1);
  }

  openAlertPopup = (msg) => {
    const alertPopup = this.dialog.open(ConfirmPopupComponent, {
      data: { isAlert: true, message: msg },
      disableClose: true
    });
    alertPopup.afterClosed().subscribe(res => {
      if (this.selectedTab === 0) {
        document.getElementById('add_search_button').click();
      } else if (this.selectedTab === 1) {
        document.getElementById('add_header_button').click();
      } else if (this.showDetail && this.selectedTab === 2) {
        document.getElementById('add_detail_button').click();
      }
    });
  }

  addHeaderField = () => {
    if (this.headerCount < this.headerLength - 1) {
      this.headerArray.push({});
      this.headerStatus[this.headerCount + 1] = false;
      this.headerCount = this.headerCount + 1;
      this.headerDataReference[this.headerCount] = 'default';
      this.headerDisplayOrder[this.headerCount] = this.headerCount + 1;
      setTimeout(() => {
        this.headerDisplayOrderList['_results'][this.headerCount]?.nativeElement.focus();
        this.headerDisplayOrderList['_results'][this.headerCount]?.nativeElement.blur();
      }, 100);
    }
  }

  removeHeaderField = (index) => {
    this.headerArray.splice(index, 1);
    this.headerStatus.splice(index, 1);
    this.headerCount = this.headerCount - 1;
    this.headerDisplayOrder.splice(index, 1);
    this.headerDataReference.splice(index, 1);
    this.headerDisplayName.splice(index, 1);
    this.headerColumnWidth.splice(index, 1);
    this.headerColumnWidthError.splice(index, 1);
    this.headerColumnWidthValidationError.splice(index, 1);
    this.headerSortable.splice(index, 1);
    this.headerDisplayOrderError.splice(index, 1);
    this.headerDisplayOrderDuplicateError.splice(index, 1);
    this.headerDataReferenceError.splice(index, 1);
    this.headerDataReferenceDuplicateError.splice(index, 1);
    this.headerDisplayNameError.splice(index, 1);
    this.headerSortableError.splice(index, 1);
    this.isLinkSelected.splice(index,1);
    this.disableLink.splice(index,1);
    this.duplicateStaticValue[index] = false;

    for (let i = index; i <= this.headerCount; i++) {
      this.headerDisplayOrder[-1] = 0;
      this.headerDisplayOrder[i] = parseInt(this.headerDisplayOrder[i - 1], 0) + 1;
      this.headerArray[i]['displayOrder'] = this.headerDisplayOrder[i];
    }
    if (this.headerLinkReference[index] !== undefined) {
      this.showDetail = false;
    }
    this.headerLinkReference.splice(index, 1);

    this.headerTabDisplayOrderList.pop();
    if (this.headerCount < 0) {
      this.openAlertPopup('Atleast one header result coulmn is mandatory. Please add result column to continue.');
    }
    this.headerTabDataRefList.splice(index, 1);
  }

  addDetailField = () => {
    if (this.detailCount < this.detailLength - 1) {
      this.detailArray.push({});
      this.detailStatus[this.detailCount + 1] = false;
      this.detailCount = this.detailCount + 1;
      this.detailDataReference[this.detailCount] = 'default';
      this.detailDisplayOrder[this.detailCount] = this.detailCount + 1;
      setTimeout(() => {
        this.detailDisplayOrderList['_results'][this.detailCount]?.nativeElement.focus();
        this.detailDisplayOrderList['_results'][this.detailCount]?.nativeElement.blur();
      }, 100);
    }
  }

  removeDetailField = (index) => {
    this.detailArray.splice(index, 1);
    this.detailStatus.splice(index, 1);
    this.detailCount = this.detailCount - 1;
    this.detailDisplayOrder.splice(index, 1);
    this.detailDataReference.splice(index, 1);
    this.detailDisplayName.splice(index, 1);
    this.detailDisplayOrderError.splice(index, 1);
    this.detailDisplayOrderDuplicateError.splice(index, 1);
    this.detailDataReferenceError.splice(index, 1);
    this.detailDataReferenceDuplicateError.splice(index, 1);
    this.detailDisplayNameError.splice(index, 1);
    for (let i = index; i <= this.detailCount; i++) {
      this.detailDisplayOrder[-1] = 0;
      this.detailDisplayOrder[i] = parseInt(this.detailDisplayOrder[i - 1], 0) + 1;
      this.detailArray[i]['displayOrder'] = this.detailDisplayOrder[i];
    }
    setTimeout(() => {
      this.detailDisplayOrderList['_results'][index - 1]?.nativeElement?.focus();
    }, 100);
    this.detailTabDisplayOrderList.pop();
    this.detailTabDataRefList.splice(index, 1);
    if (this.detailCount < 0) {
      this.openAlertPopup('Atleast one detail result column is mandatory. Please add result column to continue');
    }
  }

  validateDisplayOrder = (event) => {
    const regex = '^[0-9]{1,2}$';
    if (event.target.value !== undefined && event.target.value !== '') {
      if (!(event.target.value.match(regex))) {
        event.preventDefault();
      }
    }
  }

  focusSelectedTab = (event: any) => {
    const clickedTabIndex = this.getTabIndex((event.matTabName !== undefined) ? event.matTabName : event.target.innerText);

    if (clickedTabIndex === -1) {
      return;
    }

    if (!(this.selectedTab === clickedTabIndex)) {
      if (
        ((this.selectedTab < clickedTabIndex) && this.configTabsStatus[this.selectedTab] === true) ||
        ((this.selectedTab > clickedTabIndex) && this.configTabsStatus[this.selectedTab - 1] === true)
      ) {
        this.selectedTab = clickedTabIndex;
        this.focusTab(this.selectedTab);
      } else {
        return;
      }
    }
  }

  private getTabIndex(tabName: string): number {
    switch (tabName) {
      case 'Search':
        this.focusTab(this.selectedTab);
        return 0;
      case 'Header':
        this.validateSearchTab();
        return 1;
      case 'Detail':
        this.validateHeaderTab();
        return 2;
      case 'Audit Notes':
        if (this.showDetail) {
          this.validateDetailTab();
          return 3;
        } else {
          this.validateHeaderTab();
          return 2;
        }
      default:
        return -1;
    }
  }

  focusTab = (index) => {
    if (index === 0) {
      setTimeout(() => {
        this.dynamicDisplayOrderList['_results'][0].nativeElement.focus();
        this.dynamicDisplayOrderList['_results'][0].nativeElement.blur();
        this.displayOrderList.forEach((val) => {
          if (val !== undefined) {
            val['nativeElement'].focus();
            val['nativeElement'].blur();
          }
        });
      }, 500);
      this.buttonText = 'Next';
      this.dataService.getConfigurationButtonName('Next');
    } else if (index === 1) {
      this.headerDisplayOrderList['_results'][0].nativeElement.focus();
      this.headerDisplayOrderList['_results'][0].nativeElement.blur();
      this.buttonText = 'Next';
      this.dataService.getConfigurationButtonName('Next');
    } else if (index === 2) {
      if (this.showDetail) {
        this.detailDisplayOrderList['_results'][0].nativeElement.focus();
        this.detailDisplayOrderList['_results'][0].nativeElement.blur();
        this.buttonText = 'Next';
        this.dataService.getConfigurationButtonName('Next');
      } else {
        if (this.configStatus === 'Active') {
          this.buttonText = 'Save';
        } else {
          this.buttonText = 'Save & Activate';
        }
        this.dataService.getConfigurationButtonName('Save');
      }
    } else if (index === 3) {
      if (this.configStatus === 'Active') {
        this.buttonText = 'Save';
      } else {
        this.buttonText = 'Save & Activate';
      }
      this.dataService.getConfigurationButtonName('Save');
    }
  }

  getDisplayOrder = (event, index) => {
    if (event.target.value !== '') {
      if (this.displayOrder[index] === undefined) {
        this.displayOrder[index] = parseInt(event.target.value);
      }
      this.displayOrderError[index] = false;
      this.staticFieldsArray[index]['displayOrder'] = this.displayOrder[index];
    } else {
      this.displayOrderError[index] = true;
    }
  }

  checkError = (event, index) => {
    if (event.target.value !== 'default') {
      this.dataReferenceError[index] = false;
    } else {
      this.dataReferenceError[index] = true;
    }
  }

  getDataReference = (event, index) => {
    if (event !== '') {
      this.dataReferenceError[index] = false;
      this.dataReference[index] = event;
      this.staticFieldsArray[index]['dataReference'] = event;
      const filterData = this.searchFieldsDataRefList.filter(x => x.jsonTag === event);
      this.displayName[index] = filterData[0].displayName;
      document.getElementById('displayName_' + index).focus();
    } else {
      this.dataReferenceError[index] = true;
    }
  }

  getDisplayName = (event, index) => {
    if (event.target.value !== '') {
      this.displayNameError[index] = false;
      this.displayName[index] = event.target.value;
      this.staticFieldsArray[index]['displayName'] = event.target.value;
    } else {
      this.displayNameError[index] = true;
      if (this.staticFieldsArray[index] !== undefined && this.staticFieldsArray[index]['displayName'] !== undefined) {
        delete this.staticFieldsArray[index]['displayName'];
      }
    }
  }

  getHelpText = (event, index) => {
    this.helpText[index] = event.target.value;
    this.staticFieldsArray[index]['helpText'] = event.target.value;
  }

  getDynamicDisplayOrder = (event, index) => {
    if (event.target.value !== undefined && event.target.value !== '') {
      const existingValueCount = this.searchTabDisplayOrderList.filter(x => x === parseInt(event.target.value, 0));
      if (
        this.searchTabDisplayOrderList.indexOf(parseInt(event.target.value, 0)) > -1 &&
        existingValueCount.length > 1
      ) {
        this.dynamicFieldDisplayOrderDuplicateError[index] = true;
        this.dynamicFieldDisplayOrderError[index] = false;
        // this.dynamicFieldDisplayOrderValidationError[index] = false;
      } else {
        this.dynamicFieldDisplayOrderDuplicateError[index] = false;
        this.dynamicFieldDisplayOrderError[index] = false;
        // this.dynamicFieldDisplayOrderValidationError[index] = false;
        this.searchTabDisplayOrderList[index + 2] = parseInt(event.target.value, 0);
        this.dynamicFieldDisplayOrder[index] = parseInt(event.target.value);
        this.searchFieldsArray[index]['displayOrder'] = parseInt(event.target.value);
      }
    }else {
      this.dynamicFieldDisplayOrderError[index] = true;
      this.dynamicFieldDisplayOrderDuplicateError[index] = false;
    }
//     let position = this.searchTabDisplayOrderList[this.searchTabDisplayOrderList.length-this.staticFieldsArray.length];
//     if(event.target.value > position){
//       this.dynamicFieldDisplayOrderValidationError[index] = true;
//       this.dynamicFieldDisplayOrderDuplicateError[index] = false;
//       this.dynamicFieldDisplayOrderError[index] = false;
// }
    this.getRowStatus(index);
  }

  checkDuplicateValues = (event, index, fieldType) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (fieldType === 'search') {
        this.searchTabDisplayOrderList[index + 2] = '';
      } else if (fieldType === 'header') {
        this.headerTabDisplayOrderList[index] = '';
      } else if (fieldType === 'detail') {
        this.detailTabDisplayOrderList[index] = '';
      }
    } else {
      if (fieldType === 'search') {
        this.searchTabDisplayOrderList[index + 2] = parseInt(event.target.value, 0);
      } else if (fieldType === 'header') {
        this.headerTabDisplayOrderList[index] = parseInt(event.target.value, 0);
      } else if (fieldType === 'detail') {
        this.detailTabDisplayOrderList[index] = parseInt(event.target.value, 0);
      }
    }
    setTimeout(() => {
      this.dynamicDisplayOrderList.forEach((val) => {
        if (val !== undefined) {
          val['nativeElement'].focus();
          val['nativeElement'].blur();
        }
      });
      this.headerDisplayOrderList.forEach((val) => {
        if (val !== undefined) {
          val['nativeElement'].focus();
          val['nativeElement'].blur();
        }
      });
      this.detailDisplayOrderList.forEach((val) => {
        if (val !== undefined) {
          val['nativeElement'].focus();
          val['nativeElement'].blur();
        }
      });
    }, 500);
  }

  getDynamicDataRef = (event, index) => {
    if (event !== undefined && event !== '') {
      this.searchTabDataRefList[index + 2] = 'default';
      if (this.searchTabDataRefList.indexOf(event) > -1) {
        this.dynamicFieldDataReferenceDuplicateError[index] = true;
        this.dynamicFieldDataReferenceError[index] = false;
      } else {
        this.dynamicFieldDataReferenceDuplicateError[index] = false;
        this.dynamicFieldDataReferenceError[index] = false;
        this.dynamicFieldDataReference[index] = event;
        this.searchFieldsArray[index]['dataReference'] = event;
        const filterData = this.searchFieldsDataRefList.filter(x => x.jsonTag === event);
        this.dynamicFieldDisplayName[index] = filterData[0].displayName;
        this.dynamicFieldHelpText[index] = filterData[0].defaultHelpText;
        setTimeout(()=>{
          document.getElementById('dynamicDisplayName_' + index).focus();
          document.getElementById('dynamicHelpText_' + index).focus();

        },500);
        this.searchTabDataRefList[index + 2] = event;
      }
    } else {
      this.dynamicFieldDataReferenceError[index] = true;
      this.dynamicFieldDataReferenceDuplicateError[index] = false;
    }
    this.getRowStatus(index);
  }

  getDynamicDisplayName = (event, index) => {
    const displayName = event.target.value;
    if (displayName && displayName !== '') {
      this.dynamicFieldDisplayNameError[index] = false;
    } else {
      this.dynamicFieldDisplayNameError[index] = true;
    }
    this.dynamicFieldDisplayName[index] = displayName;
    this.searchFieldsArray[index]['displayName'] = displayName;
    this.getRowStatus(index);
  }

  getDynamicHelpText = (event, index) => {
    this.dynamicFieldHelpText[index] = event.target.value;
    this.searchFieldsArray[index]['helpText'] = event.target.value;
    this.getRowStatus(index);
  }

  getRowStatus = (index) => {
    if (
      this.searchFieldsArray[index] !== undefined &&
      this.searchFieldsArray[index]['displayOrder'] !== undefined &&
      !this.dynamicFieldDisplayOrderDuplicateError[index] &&
      this.searchFieldsArray[index]['dataReference'] !== undefined &&
      !this.dynamicFieldDataReferenceDuplicateError[index] &&
      this.searchFieldsArray[index]['displayName'] !== undefined
    ) {
      this.formStatus[index] = true;
    } else {
      this.formStatus[index] = false;
    }
  }

  getHeaderDataRefValue = (event, index) => {
    if (event !== undefined && event !== '') {
      this.headerTabDataRefList[index] = 'default';
      if (this.headerTabDataRefList.indexOf(event) > -1) {
        this.headerDataReferenceDuplicateError[index] = true;
        this.headerDataReferenceError[index] = false;
        // this.staticHeaderFields.forEach((val) => {
          this.headerTabStaticFields.forEach((val) => {
          if (val === event) {
            this.duplicateStaticValue[index] = true;
          }
        });

      } else {
        this.headerDataReferenceError[index] = false;
        this.headerDataReferenceDuplicateError[index] = false;
        this.headerDataReference[index] = event;
        const filterData = this.resultFieldsDataRefList.filter(x => x.jsonTag === event);
        const selectedData = this.resultFieldsDataRefList.find(x => x.jsonTag === event);
        if (selectedData && selectedData.popUpDataViewReference) {
          this.isLinkSelected[index] = true;
        } else {
          this.isLinkSelected[index] = false;
        }
        this.headerArray[index]['dataReference'] = event;
        this.headerDisplayName[index] = filterData[0].displayName;
        this.headerTabDataRefList[index] = event;
        document.getElementById('headerDisplayName_' + index).focus();
        if (this.headerTabDataRefList[index] === 'meta.dlvyStat' || this.headerTabDataRefList[index] === 'meta.dlvyDte') {
          this.headerArray[index]['sortable'] = false;
        }
      }
    } else {
      this.headerDataReferenceError[index] = true;
      this.headerDataReferenceDuplicateError[index] = false;
    }
    this.getHeaderFieldStatus(index);
  }

  getHeaderDisplayName = (event, index) => {
    const displayName = event.target.value;
    if (displayName && displayName !== '') {
      this.headerDisplayNameError[index] = false;
    } else {
      this.headerDisplayNameError[index] = true;
    }
    this.headerDisplayName[index] = displayName;
    this.headerArray[index]['displayName'] = displayName;
    this.getHeaderFieldStatus(index);
  }

  getHeaderColumnWidth = (event, index) => {
    const columnWidth = parseInt(event.target.value, 0);
    // const columnWidth = event.target.value;
    // if (columnWidth && (columnWidth !== NaN || columnWidth !== undefined)) {
    if (columnWidth !== NaN || columnWidth !== undefined) {
      if (columnWidth < 20 || columnWidth === 0) {
        this.headerColumnWidthValidationError[index] = true;
        this.headerColumnWidthError[index] = false;
      } else {
        this.headerColumnWidthError[index] = false;
        this.headerColumnWidthValidationError[index] = false;
      }
    } else {
      this.headerColumnWidthError[index] = true;
      this.headerColumnWidthValidationError[index] = false;
    }
    if(!isNaN(columnWidth)) {
      this.headerColumnWidth[index] =  columnWidth;
    } else {
      this.headerColumnWidthError[index] = true;
    }
    this.headerArray[index]['headerColumnWidth'] = columnWidth;
    this.getHeaderFieldStatus(index);
  }

  getHeaderDisplayOrder = (event, index) => {
    if (event.target.value !== undefined && event.target.value !== '') {
      const existingValueCount = this.headerTabDisplayOrderList.filter(x => x === parseInt(event.target.value, 0));
      if (this.headerTabDisplayOrderList.indexOf(parseInt(event.target.value, 0)) > -1 && existingValueCount.length > 1) {
        this.headerDisplayOrderDuplicateError[index] = true;
        this.headerDisplayOrderError[index] = false;
      } else {
        this.headerDisplayOrderError[index] = false;
        this.headerDisplayOrderDuplicateError[index] = false;
        this.headerDisplayOrder[index] = parseInt(event.target.value, 0);
        this.headerArray[index]['displayOrder'] = parseInt(event.target.value, 0);
        this.headerTabDisplayOrderList[index] = parseInt(event.target.value, 0);
      }
    } else {
      this.headerDisplayOrderError[index] = true;
      this.headerDisplayOrderDuplicateError[index] = false;
    }
    this.getHeaderFieldStatus(index);
  }

  getHeaderSortableValue = (event, index) => {
    if (event.value !== '') {
      this.headerSortableError[index] = false;
      this.headerSortable[index] = event.value;
      this.headerArray[index]['sortable'] = event.value;
    } else {
      this.headerSortableError[index] = true;
    }
    this.getHeaderFieldStatus(index);
  }
  getHeaderLinkDataRefPayloadTypeValue = (event, index) => {
    if (event.value !== '') {
      this.headerLinkDataRefPayloadTypeError[index] = false;
      this.headerLinkdataRefPayloadType[index] = event.value;
      this.headerArray[index]['messagePayloadType'] = event.value;
    } else {
      this.headerLinkDataRefPayloadTypeError[index] = true;
    }
    this.getHeaderFieldStatus(index);
  }

  getHeaderFieldStatus = (index) => {
    if (
      this.headerArray[index] !== undefined &&
      this.headerArray[index]['displayOrder'] !== undefined &&
      this.headerArray[index]['dataReference'] !== undefined &&
      this.headerArray[index]['displayName'] !== undefined &&
      this.headerArray[index]['headerColumnWidth'] !== undefined &&
      this.headerArray[index]['sortable'] !== undefined
    ) {
      this.headerStatus[index] = true;
    } else {
      this.headerStatus[index] = false;
    }
    if(this.headerArray[index]['link']) {
      this.linkableDisplayOrder = this.headerArray[index]['displayOrder'];
    }
  }

  getDetailFieldStatus = (index) => {
    if (this.detailArray[index] && this.detailArray[index]['displayOrder'] && this.detailArray[index]['dataReference'] && this.detailArray[index]['displayName']) {
      this.detailStatus[index] = true;
    } else {
      this.detailStatus[index] = false;
    }
    if(this.detailArray[index]['link']) {
      this.changeDetailDisplayOrder = this.detailArray[index]['displayOrder'];
    }
  }

  getHeaderLinkReference = (event, ind) => {
    this.headerLinkReference[ind] = event.checked;
    if (event.checked && !this.showDetail) {
      this.showDetail = true;
    } else {
      this.showDetail = false;
    }
    this.disableLink[ind] = event.checked;
    this.headerArray[ind]['link'] = this.headerLinkReference[ind];
    this.linkableDisplayOrder = this.headerArray[ind]['displayOrder'];
    this.getStaticDisplayName(this.headerArray[ind]);
  }

  getStaticDisplayName(_linkReferArray = {}) {
    _linkReferArray['displayOrder'] = 1;
    this.changeDetailDisplayOrder = _linkReferArray['displayOrder'];
    _linkReferArray['linkable'] = true;
    this.detailArray = [_linkReferArray];
    this.detailArray.push({ displayOrder: 2, dataReference: 'default' })
    if (this.data === 'New' || this.data === 'Edit' || this.data === 'New_Import') {
      this.detailArray.forEach((val, index) => {
        this.detailDisplayOrder[index] = val['displayOrder'];
        this.detailDataReference[index] = val['dataReference'];
        this.detailDisplayName[index] = val['displayName'];
        this.detailTabDataRefList[index] = this.detailDataReference[index];
        this.detailTabDisplayOrderList[index] = parseInt(this.detailDisplayOrder[index], 0);
      });
      this.detailCount = this.detailArray.length - 1;
    }
  }

  getDetailDataRefValue = (event, index) => {
    if (event !== undefined && event !== '') {
      this.detailTabDataRefList[index] = 'default';
      if (this.detailTabDataRefList.indexOf(event) > -1) {
        this.detailDataReferenceDuplicateError[index] = true;
        this.detailDataReferenceError[index] = false;
      } else {
        this.detailDataReferenceError[index] = false;
        this.detailDataReferenceDuplicateError[index] = false;
        this.detailDataReference[index] = event;
        this.detailArray[index]['dataReference'] = event;
        const filterData = this.detailFieldsDataRefList.filter(x => x.jsonTag === event);
        this.detailDisplayName[index] = filterData[0].displayName;
        document.getElementById('detailDisplayName_' + index).focus();
        this.detailTabDataRefList[index] = event;
      }
    } else {
      this.detailDataReferenceError[index] = true;
      this.detailDataReferenceDuplicateError[index] = false;
    }
    this.getDetailFieldStatus(index);
  }

  getDetailDisplayName = (event, index) => {
    const displayName = event.target.value;
    if (displayName && displayName !== '') {
      this.detailDisplayNameError[index] = false;
    } else {
      this.detailDisplayNameError[index] = true;
    }
    this.detailDisplayName[index] = displayName;
    this.detailArray[index]['displayName'] = displayName;
    this.getDetailFieldStatus(index);
  }

  getDetailDisplayOrder = (event, index) => {
    if (event.target.value !== undefined && event.target.value !== '') {
      const existingValueCount = this.detailTabDisplayOrderList.filter(x => x === parseInt(event.target.value, 0));
      if (this.detailTabDisplayOrderList.indexOf(parseInt(event.target.value, 0)) > -1 && existingValueCount.length > 1) {
        this.detailDisplayOrderDuplicateError[index] = true;
        this.detailDisplayOrderError[index] = false;
      } else {
        this.detailDisplayOrderError[index] = false;
        this.detailDisplayOrderDuplicateError[index] = false;
        this.detailDisplayOrder[index] = parseInt(event.target.value, 0);
        this.detailArray[index]['displayOrder'] = parseInt(event.target.value, 0);
        this.detailTabDisplayOrderList[index] = parseInt(event.target.value, 0);
      }
    } else {
      this.detailDisplayOrderError[index] = true;
      this.detailDisplayOrderDuplicateError[index] = false;
    }
    this.getDetailFieldStatus(index);
  }

  validateOnButtonClick = (data) => {
    let isFormValid = false;
    if (data !== undefined && data !== null && data !== '' && this.searchFieldsDataRefList !== undefined && this.resultFieldsDataRefList !== undefined && this.detailFieldsDataRefList !== undefined) {
      if (data === 'Next' && this.selectedTab !== undefined) {
        if (this.selectedTab === 0) {
          this.configTabsStatus[0] = this.validateSearchTab();
          isFormValid = false;
        } else if (this.selectedTab === 1) {
          this.validateHeaderTab();
          isFormValid = false;
        } else if (this.showDetail && this.selectedTab === 2) {
          this.validateDetailTab();
          isFormValid = false;
        }

        if (this.configTabsStatus[this.selectedTab] === true) {
          this.selectedTab = this.selectedTab + 1;
          if ((this.showDetail && this.selectedTab === 3) || (!this.showDetail && this.selectedTab === 2)) {
            this.focusTab(this.selectedTab);
          }
        }
      } else if (data === 'Save' || data === 'Save & Activate') {
        let searchFieldsArray;
        isFormValid = true;
        if (this.searchTabData === undefined) {
          searchFieldsArray = this.reqStaticFieldsArray.concat(this.searchFieldsArray);
          searchFieldsArray = searchFieldsArray.concat(this.staticFieldsArray);
          this.searchTabData = searchFieldsArray;
        }
        const payload = this.buildConfigPayload();
        if (this.data === 'New' || this.data === 'New_Import') {
          if (this.auditNotesRef !== undefined && this.auditNotesRef !== null && this.auditNotesRef !== '') {
            this.dataService.getConfigurationPayload({ configPayload: payload, isValid: isFormValid });
          }
        } else if (this.data === 'Edit' || this.data === 'Import') {
          this.callSaveConfigAPI(payload, data);
        }
      } else if (data === 'Back') {
        this.focusTab(this.selectedTab - 1);
        this.validateOnBackButtonClick();
      }
    } else {
      this.selectedTab = 0
    }
  }

  tabClick = (event) => {
    this.tabLabel = event.index;
  }

  validateOnBackButtonClick = () => {
    if (this.selectedTab != undefined && this.selectedTab >= 1) {
      this.selectedTab = this.selectedTab - 1;
    }
    this.buttonText = 'Next';
  }

  callSaveConfigAPI = (payload, actionType) => {
    let popupMsg;
    if (actionType === 'Save') {
      popupMsg = popup.ackContent;
    } else {
      popupMsg = popup.reactivateMessage;
    }
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popupMsg, configName: this.configurationName, action: 'save' },
        disableClose: true,
      });
    confirmPopupInstance.afterClosed().subscribe(data => {
      if (data === 'close') {
        this.dialogRef.close();
        this.notifyService.showNotification(popup.successContent, 'success');
      } else if (data === 'OK') {
        this.loadingService.openLoadingIndicator();
        this.apiService.getEditSaveConfiguration(payload).subscribe((response) => {
          this.close();
          this.callConfigurationAPI();
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.updateConfiguraion, 'success');
        }, (error) => {
          if (this.configData && this.configData.auditNotes) { //Retain the existing notes
            this.configData.auditNotes = this.logs;
          }
          this.loadingService.closeLoadingIndicator();
          this.close();
          this.notifyService.showNotification(popup.errorNotification, 'error');
        });
      }
    });
  }

  callConfigurationAPI = () => {
    this.configResponse.emit(true);
  }

  validateSearchTab = () => {
    let isSearchDynamicFieldValid: boolean = true;
    this.searchFieldsArray.forEach((searchField, index) => {
      if (searchField !== undefined) {
        if (searchField['displayOrder'] && searchField['displayOrder'] !== '') {
          if (this.dynamicFieldDisplayOrderDuplicateError[index]) {
            this.dynamicFieldDisplayOrderError[index] = false;
          } else {
            this.dynamicFieldDisplayOrderDuplicateError[index] = false;
          }
        } else {
          if (this.dynamicFieldDisplayOrderDuplicateError[index]) {
            this.dynamicFieldDisplayOrderError[index] = false;
          } else {
            this.dynamicFieldDisplayOrderDuplicateError[index] = false;
            this.dynamicFieldDisplayOrderError[index] = true;
          }
          this.dynamicFieldsValid = false;
        }
        if (searchField['dataReference'] && searchField['dataReference'] !== '') {
          if (this.dynamicFieldDataReferenceDuplicateError[index]) {
            this.dynamicFieldDataReferenceError[index] = false;
          } else {
            this.dynamicFieldDataReferenceDuplicateError[index] = false;
          }
        } else {
          if (this.dynamicFieldDataReferenceDuplicateError[index]) {
            this.dynamicFieldDataReferenceError[index] = false;
          } else {
            this.dynamicFieldDataReferenceDuplicateError[index] = false;
            this.dynamicFieldDataReferenceError[index] = true;
          }
          this.dynamicFieldsValid = false;
        }
        if (searchField['displayName'] && searchField['displayName'] !== '') {
          this.dynamicFieldDisplayNameError[index] = false;
        } else {
          this.dynamicFieldDisplayNameError[index] = true;
          this.dynamicFieldsValid = false;
        }
      }
      if (!this.dynamicFieldDisplayOrderError[index] && !this.dynamicFieldDisplayOrderDuplicateError[index] && !this.dynamicFieldDataReferenceError[index] && !this.dynamicFieldDataReferenceDuplicateError[index] && !this.dynamicFieldDisplayNameError[index]) {
        this.dynamicFieldsValid = true;
      } else {
        this.dynamicFieldsValid = false;
        isSearchDynamicFieldValid = false;
      }
    });
    this.staticFieldsArray.forEach((staticSearchField, index) => {
      if (staticSearchField !== undefined) {
        if (staticSearchField['displayOrder'] && staticSearchField['displayOrder'] !== '') {
          this.displayOrderError[index] = false;
          this.staticFieldsValid = true;
        } else {
          this.displayOrderError[index] = true;
          this.staticFieldsValid = false;
        }
        if (staticSearchField['dataReference'] && staticSearchField['dataReference'] !== '') {
          this.dataReferenceError[index] = false;
          this.staticFieldsValid = true;
        } else {
          this.dataReferenceError[index] = true;
          this.staticFieldsValid = false;
        }
        if (staticSearchField['displayName'] && staticSearchField['displayName'] !== '') {
          this.displayNameError[index] = false;
          this.staticFieldsValid = true;
        } else {
          this.displayNameError[index] = true;
          this.staticFieldsValid = false;
        }
      }
    });
    if (this.dynamicFieldsValid && this.staticFieldsValid && isSearchDynamicFieldValid) {
      //if (searchDynamicFieldValid && searchStaticFieldValid) {
      this.configTabsStatus[0] = true;
      return true;
    } else {
      this.configTabsStatus[0] = false;
      return false;
    }
  }

  validateHeaderTab = () => {
    let isheaderFieldsValid: boolean = true;
    this.headerArray.forEach((headerField, index) => {
      if (headerField) {
        if (headerField['displayOrder'] && headerField['displayOrder'] && headerField['displayOrder'] !== '') {
          if (this.headerDisplayOrderDuplicateError[index]) {
            this.headerDisplayOrderError[index] = false;
          } else {
            this.headerDisplayOrderDuplicateError[index] = false;
          }
        } else {
          if (this.headerDisplayOrderDuplicateError[index]) {
            this.headerDisplayOrderError[index] = false;
          } else {
            this.headerDisplayOrderDuplicateError[index] = false;
            this.headerDisplayOrderError[index] = true;
          }
          this.headerTabValid = false;
        }
        if (headerField['dataReference'] && headerField['dataReference'] && headerField['dataReference'] !== '') {
          if (this.headerDataReferenceDuplicateError[index]) {
            this.headerDataReferenceError[index] = false;
          } else {
            this.headerDataReferenceDuplicateError[index] = false;
          }
        } else {
          if (this.headerDataReferenceDuplicateError[index]) {
            this.headerDataReferenceError[index] = false;
          } else {
            this.headerDataReferenceDuplicateError[index] = false;
            this.headerDataReferenceError[index] = true;
          }
          this.headerTabValid = false;
        }
        if (headerField['displayName'] && headerField['displayName'] && headerField['displayName'] !== '') {
          this.headerDisplayNameError[index] = false;
        } else {
          this.headerDisplayNameError[index] = true;
          this.headerTabValid = false;
        }
        if (headerField['headerColumnWidth'] && headerField['headerColumnWidth'] && headerField['headerColumnWidth'] !== '') {
          if (this.headerColumnWidthValidationError[index]) {
            this.headerColumnWidthError[index] = false;
          } else {
            this.headerColumnWidthValidationError[index] = false;
          }
        } else {
          if (this.headerColumnWidthValidationError[index]) {
            this.headerColumnWidthError[index] = false;
          } else {
            this.headerColumnWidthValidationError[index] = false;
            this.headerColumnWidthError[index] = true;
          }
          this.headerTabValid = false;
        }
        // if (headerField['sortable'] && headerField['sortable'].toString()) {
        //if (headerField['sortable'].toString()!== undefined) {
        if (headerField['sortable'] !== undefined) {
          this.headerSortableError[index] = false;
        } else {
          this.headerSortableError[index] = true;
          this.headerTabValid = false;
        }
      }

      if (!this.headerDisplayOrderError[index] && !this.headerDisplayOrderDuplicateError[index] && !this.headerDataReferenceError[index] &&
        !this.headerDataReferenceDuplicateError[index] && !this.headerDisplayNameError[index] && !this.headerSortableError[index] 
        && !this.headerColumnWidthError[index] && !this.headerColumnWidthValidationError[index]) {
        this.headerTabValid = true;
      } else {
        this.headerTabValid = false;
        isheaderFieldsValid = false;
      }
    });

    //if (this.headerTabValid) {
      if (this.headerTabValid && isheaderFieldsValid) {
      this.configTabsStatus[1] = true;
    } else {
      this.configTabsStatus[1] = false;
    }
  }

  validateDetailTab = () => {
    let isDetailFieldValid: boolean = true;
    this.detailArray.forEach((detailField, index) => {
      if (detailField) {
        if (detailField['displayOrder']) {
          if (this.detailDisplayOrderDuplicateError[index]) {
            this.detailDisplayOrderError[index] = false;
          } else {
            this.detailDisplayOrderDuplicateError[index] = false;
          }
        } else {
          if (this.detailDisplayOrderDuplicateError[index]) {
            this.detailDisplayOrderError[index] = false;
          } else {
            this.detailDisplayOrderError[index] = true;
          }
          this.detailTabValid = false;
        }
        if (detailField['dataReference']) {
          if (this.detailDataReferenceDuplicateError[index]) {
            this.detailDataReferenceError[index] = false;
          } else {
            this.detailDataReferenceDuplicateError[index] = false;
            if (detailField['displayOrder'] === 2 && detailField['dataReference'] === 'default') {
              this.detailDataReferenceError[index] = true;
            }
          }
        } else {
          if (this.detailDataReferenceDuplicateError[index]) {
            this.detailDataReferenceError[index] = false;
          } else {
            this.detailDataReferenceDuplicateError[index] = false;
            this.detailDataReferenceError[index] = true;
          }
          this.detailTabValid = false;
        }
        if (detailField['displayName'] && detailField['displayName'] !== '') {
          this.detailDisplayNameError[index] = false;
        } else {
          this.detailDisplayNameError[index] = true;
          this.detailTabValid = false;
        }
      }
      if (!this.detailDisplayOrderError[index] && !this.detailDisplayOrderDuplicateError[index] && !this.detailDataReferenceError[index] &&
        !this.detailDataReferenceDuplicateError[index] && !this.detailDisplayNameError[index]) {
        this.detailTabValid = true;
      } else {
        this.detailTabValid = false;
        isDetailFieldValid = false;
      }
    });
    // if (this.detailTabValid) {
    if (this.detailTabValid && isDetailFieldValid) {
      this.configTabsStatus[2] = true;
    } else {
      this.configTabsStatus[2] = false;
    }
  }

  buildConfigPayload = () => {
    this.formSearchFields();
    this.formVirtualColumn();
    this.formIndexColumn();
    this.formHeaderResultFields();
    if (this.showDetail) {
      this.formDetailResultFields();
    }
    let payload;
    if (this.data === 'New' || this.data === 'New_Import') {
      const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      const userName = userDetails.userName;
      payload = {
        status: 'A',
        createdBy: userName,
        updatedBy: userName,
        configuration: {
          configNme: this.configurationName,
          commId: this.communityFullName,
          displayMessageType: this.messageTypeFullName,
          displayApplication: this.applicationFullName,
          primaryKeyRef: 'meta.unhSeqNbr',
          dbTblNme: {
            meta: this.configurationName,
            metaKey: 'META_ID',
            detail: this.configurationName + '_DETAIL',
            detailKey: 'META_ID_FK'
          },
          dbTblDef: [
            {
              tblName: this.configurationName,
              tblType: 'meta',
              columnDef: [
                'META_ID NUMBER(11,0) GENERATED ALWAYS AS IDENTITY MINVALUE 1 MAXVALUE 99999999999 INCREMENT BY 1 START WITH 1 CACHE 1000 NOORDER CYCLE NOKEEP NOSCALE NOT NULL ENABLE',
                'CREATED_TIMESTAMP NUMBER NOT NULL ENABLE',
                'META VARCHAR2(32767 BYTE) NOT NULL ENABLE'
              ],
              virtualColumns: this.virtualCoumn[0],
              indexColumns: this.indexColumn[0]
            },
            {
              tblName: this.configurationName + '_DETAIL',
              tblType: 'itemDetail',
              columnDef: [
                'DTL_ID_PK NUMBER(11,0) GENERATED ALWAYS AS IDENTITY MINVALUE 1 MAXVALUE 99999999999 INCREMENT BY 1 START WITH 1 CACHE 1000 NOORDER CYCLE NOKEEP NOSCALE NOT NULL ENABLE',
                'META_ID_FK NUMBER(11,0) NOT NULL ENABLE',
                'ITEM_DETAIL VARCHAR2(32767 BYTE)'
              ],
              virtualColumns: this.virtualCoumn[1] !== undefined ? this.virtualCoumn[1] : [],
              indexColumns: this.indexColumn[1] !== undefined ? this.indexColumn[1] : []
            }
          ],
          searchFields: this.searchFieldPayload,
          dataViews: {
            searchResult: {
              type: 'TABLE',
              asnDashboardEnabled: this.asnDashBoardEnabled === 'T' ? true : false,
              fields: this.headerFieldPayload
            },
            itemDetail: {
              type: 'TABLE',
              title: this.itemDetailTitle,
              fields: this.detailFieldPayload
            }
          }
        }
      };
    } else if (this.data === 'Edit' || this.data === 'Import') {
	  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      const userName = userDetails.userName;
      this.configData.updatedBy = userName;
      payload = this.configData;
      let existingVirtualColumns = this.configData?.configuration?.dbTblDef[0]?.virtualColumns;
      existingVirtualColumns = existingVirtualColumns.concat(this.configData?.configuration?.dbTblDef[1]?.virtualColumns);
      const existingHeaderPayload = this.configData?.configuration?.dataViews?.searchResult?.fields;
      const exisitngDetailPayload = this.configData?.configuration?.dataViews?.itemDetail?.fields;
      const virtuaColumn = this.virtualCoumn[0].concat(this.virtualCoumn[1]);
      const notToBeCheckedFields = ['meta.dlvyStat', 'meta.jsonMsgId', 'meta.javaMapTme', 'meta.unhSeqNbr', 'meta.segTerminator', 'meta.mrsShpKey'];
      let deletedFields = this.getDeletedFields(existingVirtualColumns, virtuaColumn, 'jsonTag');
      deletedFields = deletedFields.filter(x => notToBeCheckedFields.indexOf(x.jsonTag) < 0);
      const itemDetailDeletion = deletedFields.filter(x => x.jsonTblColumn === 'ITEM_DETAIL');
      let addedFields = this.getAddedFields(existingVirtualColumns, virtuaColumn, 'jsonTag');
      addedFields = addedFields.filter(x => (x.jsonTag !== 'meta.configNme'));
      let itemDetailAddition;
      if (
        (deletedFields !== undefined && deletedFields.length > 0) ||
        (addedFields !== undefined && addedFields.length > 0)
      ) {
        const arr = [];
        const itemDetailArr = [];
        addedFields.forEach(addField => {
          const obj = {};
          obj['virtualColumn'] = addField.virtualColumn;
          const dataReference = this.getIndexColumn(obj['virtualColumn']);
          obj['indexName'] = this.configurationName + '_' + dataReference.vcIndexName;
          addField.jsonTblColumn !== 'ITEM_DETAIL' ? arr.push(obj) : itemDetailArr.push(obj);
        });
        if (arr?.length > 0) {
          payload.configuration.dbTblDef[0].indexColumns = payload.configuration.dbTblDef[0].indexColumns.concat(arr);
        }
        if (itemDetailArr?.length > 0) {
          payload.configuration.dbTblDef[1].indexColumns = payload.configuration.dbTblDef[1].indexColumns.concat(itemDetailArr);
        }
        if (addedFields?.length > 0) {
          addedFields.forEach(val => val['columnStat'] = 'add');
          itemDetailAddition = addedFields.filter(x => x.jsonTblColumn === 'ITEM_DETAIL');
          addedFields = addedFields.filter(x => x.jsonTblColumn !== 'ITEM_DETAIL');
        }
        if (payload && payload?.configuration && payload?.configuration?.dbTblDef) {
          if (payload.configuration.dbTblDef[0] !== undefined && payload.configuration.dbTblDef[0].virtualColumns !== undefined) {
            payload.configuration.dbTblDef[0].virtualColumns = payload.configuration.dbTblDef[0].virtualColumns.concat(addedFields);
            payload.configuration.dbTblDef[0].virtualColumns.forEach(val => {
              deletedFields.forEach(newVal => {
                if (val.jsonTag === newVal.jsonTag) {
                  val['columnStat'] = 'remove';
                }
              });
            });
          }
          if (payload?.configuration?.dbTblDef[1] !== undefined && payload?.configuration?.dbTblDef[1]?.virtualColumns !== undefined) {
            if (
              itemDetailAddition?.length > 0 &&
              payload.configuration.dbTblDef[1].virtualColumns.length === 0 || itemDetailAddition?.length > 0 &&
              payload.configuration.dbTblDef[1].virtualColumns.length > 0
            ) {
              payload.configuration.dbTblDef[1].virtualColumns =
                payload.configuration.dbTblDef[1].virtualColumns.concat(itemDetailAddition);
            }
            if (itemDetailDeletion?.length > 0) {
              payload.configuration.dbTblDef[1].virtualColumns.forEach(val => {
                deletedFields.forEach(newVal => {
                  if (val.jsonTag === newVal.jsonTag) {
                    val['columnStat'] = 'remove';
                  }
                });
              });
            }
          }
        }
      }
      if (payload && payload?.configuration && payload?.configuration?.searchFields) {
        payload.configuration.searchFields = this.searchFieldPayload;
      }
      const addedHeaderFields = this.getAddedFields(existingHeaderPayload, this.headerFieldPayload, 'dataReference');
      let deletedHeaderFields = this.getDeletedFields(existingHeaderPayload, this.headerFieldPayload, 'dataReference');
      deletedHeaderFields = deletedHeaderFields.filter(x => x.displayName !== null);
      if (
        payload &&
        payload?.configuration &&
        payload.configuration?.dataViews &&
        payload.configuration.dataViews?.searchResult &&
        payload.configuration.dataViews.searchResult?.fields
      ) {
        payload.configuration.dataViews.searchResult.fields = this.headerFieldPayload;
      }
      const addedDetailFields = this.getAddedFields(exisitngDetailPayload, this.detailFieldPayload, 'dataReference');
      const deletedDetailFields = this.getDeletedFields(exisitngDetailPayload, this.detailFieldPayload, 'dataReference');

      if (
        (deletedDetailFields && deletedDetailFields.length > 0) ||
        (addedDetailFields && addedDetailFields.length > 0)
      ) {
        if (
          payload &&
          payload?.configuration &&
          payload.configuration?.dataViews &&
          payload.configuration.dataViews?.itemDetail &&
          payload.configuration.dataViews.itemDetail?.fields
        ) {
          payload.configuration.dataViews.itemDetail.fields = this.detailFieldPayload;
        }
      } else {
        payload.configuration.dataViews.itemDetail.fields = this.detailFieldPayload;
      }
    }
    if (this.auditNotes !== undefined && this.auditNotes !== null && this.auditNotes !== '') {
      payload['auditNotes'] = this.auditNotes;
    } else {
      if (this.data !== 'New') {
        delete this.configData.auditNotes;
      }
    }
    return payload;
  }

  getAddedFields = (existingPayload, newPayload, compareField) => {
    return newPayload?.filter(obj => (obj !== undefined &&
      !existingPayload?.some(obj2 => (obj2 !== undefined && obj[compareField] === obj2[compareField]))));
  }

  getDeletedFields = (existingPayload, newPayload, compareField) => {
    return existingPayload?.filter(obj => (obj !== undefined &&
      !newPayload?.some(obj2 => (obj2 !== undefined && obj[compareField] === obj2[compareField]))));
  }

  getIndexColumn = (virtualColumn) => {
    let dataReference = null;
    this.searchFieldsDataRefList?.forEach(element => {
      if (element.virtualColumn == virtualColumn) {
        dataReference = element;
      }
    });
    return dataReference;
  }

  formVirtualColumn = () => {
    this.virtualCoumn = [];
    const arr = [];
    const arrVirtual = [];
    const configObj = {
      jsonTblColumn: 'META',
      jsonTag: 'meta.configNme',
      virtualColumn: 'CONFIGURATION_NAME',
      datatype: 'VARCHAR2(100)',
      onError: 'NULL'
    };
    arr[0] = configObj;
    let searchTabData = this.searchTabData.map(x => Object.assign({}, x));
    searchTabData = searchTabData.splice(0, (searchTabData.length - 1));
    searchTabData.forEach(val => {
      this.searchFieldsDataRefList.forEach(field => {
        if (val.dataReference === field.jsonTag) {
            const beforeString = field.jsonTag.substring(0,field.jsonTag.indexOf('.'));
            if (beforeString === 'meta') {
            const obj = {};
            obj['jsonTblColumn'] = 'META';
            obj['jsonTag'] = field.jsonTag;
            obj['virtualColumn'] = field.virtualColumn;
            obj['datatype'] = field.vcDataTypeSize;
            obj['onError'] = 'NULL';
            arr.push(obj);
          } else {
            const itemDetailObj = {};
            itemDetailObj['jsonTblColumn'] = 'ITEM_DETAIL';
            itemDetailObj['jsonTag'] = field.jsonTag.substring(field.jsonTag.lastIndexOf('.') + 1);
            itemDetailObj['virtualColumn'] = field.virtualColumn;
            itemDetailObj['datatype'] = field.vcDataTypeSize;
            itemDetailObj['onError'] = 'NULL';
            arrVirtual.push(itemDetailObj);
          }
        }
      });
    });
    this.virtualCoumn[0] = arr;
    this.virtualCoumn[1] = arrVirtual;
  }

  formIndexColumn = () => {
    this.indexColumn = [];
    const arr = [];
    const arrDetailIndex = [];
    let searchTabData = this.searchTabData.map(x => Object.assign({}, x));
    searchTabData = searchTabData.splice(0, (searchTabData.length - 1));
    searchTabData.forEach(val => {
      this.searchFieldsDataRefList.forEach(field => {
        if (val.dataReference === field.jsonTag) {
            const beforeString = field.jsonTag.substring(0,field.jsonTag.indexOf('.'));
            if (beforeString === 'meta'){ 
            const obj = {};
            obj['indexName'] = this.configurationName + '_' + field.vcIndexName;
            obj['virtualColumn'] = field.virtualColumn;
            arr.push(obj);
          } else {
            const itemDetailObj = {};
            itemDetailObj['indexName'] = this.configurationName + '_' + field.vcIndexName;
            itemDetailObj['virtualColumn'] = field.virtualColumn;
            arrDetailIndex.push(itemDetailObj);
          }
        }
      });
    });
    this.indexColumn[0] = arr;
    this.indexColumn[1] = arrDetailIndex;
  }

  formSearchFields = () => {
    this.searchFieldPayload = [];
    const searchTabData = this.searchTabData.map(x => Object.assign({}, x));
    searchTabData.forEach(val => {
      this.searchFieldsDataRefList.forEach(field => {
        if (val.dataReference === field.jsonTag) {
          let obj = {};
          obj['displayName'] = val.displayName;
          obj['displayOrder'] = val.displayOrder;
          obj['dataReference'] = val.dataReference;
          obj['virtualColumn'] = field.virtualColumn;
          obj['dataValue'] = '';
          obj['formatter'] = field.defaultFormatter;
          obj['helpText'] = val?.helpText !== undefined ? val.helpText : '';
          this.searchFieldPayload.push(obj);
        }
      });
    });
  }

  formHeaderResultFields = () => {
    this.headerFieldPayload = [];
    this.headerArray.forEach((val, index) => {
      console.log(val);
      if ((this.data === 'New' || this.data === 'New_Import') && val['link']) {
        this.headerArray.forEach((newVal)=>{
          if(!newVal['link'] || newVal['link'] === undefined) {
            if(val['displayOrder'] == newVal['displayOrder']) {
              val['displayOrder'] = this.linkableDisplayOrder;
            }
          } else {
              var notDuplicateDisplayOrder = val['displayOrder'];
              this.linkableDisplayOrder = notDuplicateDisplayOrder;
          }
        });
      }
      console.log(this.resultFieldsDataRefList);
      this.resultFieldsDataRefList.forEach(field => {        
        if (val['dataReference'] === field.jsonTag) {
          if (this.ispopUpDataViewReference(field.jsonTag) === false && field.jsonTag !== 'meta.msgId') {
            const obj = {};
            obj['displayName'] = val['displayName'];
            obj['columnPosition'] = val['displayOrder'];
            obj['nonDisplayName'] = null;
            obj['dataReference'] = val['dataReference'];
            obj['sortable'] = val['sortable'];
            obj['formatter'] = field.defaultFormatter;
            obj['headerColumnWidth'] = val['headerColumnWidth'];
            obj['link'] = null;
            this.headerFieldPayload.push(obj);
          } else {
            //if (field.jsonTag === 'itemDetail.prtNbr') {
            // if (field.jsonTag === this.isItemdetail[0].jsonTag && this.isItemdetail[0].dataViewReference === true) {
              if (this.ispopUpDataViewReference(field.jsonTag) === true) {   
            const obj = {};
              obj['displayName'] = val['displayName'];
              obj['columnPosition'] = val['displayOrder'];
              obj['nonDisplayName'] = null;
              obj['dataReference'] = val['dataReference'];
              obj['sortable'] = val['sortable'];
              obj['formatter'] = field.defaultFormatter;
              obj['headerColumnWidth'] = val['headerColumnWidth'];
              if (val['link']) {
                obj['link'] = {
                  type: 'DATA_VIEW',
                  title: null,
                  dataViewReference: 'itemDetail',
                  payload: null
                };
              } else {
                obj['link'] = null;
              }
              this.headerFieldPayload.push(obj);
            } else if (field.jsonTag === 'meta.msgId') {
              const obj = {};
              obj['displayName'] = val['displayName'];
              obj['columnPosition'] = val['displayOrder'];
              obj['nonDisplayName'] = null;
              obj['dataReference'] = val['dataReference'];
              obj['sortable'] = val['sortable'];
              obj['formatter'] = field.defaultFormatter;
              obj['headerColumnWidth'] = val['headerColumnWidth'];
              obj['link'] = {
                type: 'MESSAGE_ID',
                title: null,
                dataViewReference: null,
                payload: ['common', val['messagePayloadType']]
              };
              this.headerFieldPayload.push(obj);
            }
          }
        }
      });
    });
  }

  formDetailResultFields = () => {
    this.detailFieldPayload = [];
    this.detailArray.forEach(val => {
      if (val['link']) {
        val['displayOrder'] = this.changeDetailDisplayOrder;
      }
      this.detailFieldsDataRefList.forEach(field => {
        if (val['dataReference'] === field.jsonTag) {
          const obj = {};
          obj['displayName'] = val['displayName'];
          obj['columnPosition'] = val['displayOrder'];
          obj['nonDisplayName'] = null;
          obj['dataReference'] = val['dataReference'];
          obj['formatter'] = field.defaultFormatter;
          this.detailFieldPayload.push(obj);
        }
      });
    });
  }

  closePopup = () => {
    if (this.data !== 'View') {
      const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
        {
          data: { msg: popup.cancelContent, exit: 'close' },
          disableClose: true,
        });

      confirmPopupInstance.afterClosed().subscribe(data => {
        if (data === 'close') {
          this.close();
          this.onPopUpClosed.emit();
        }
      });
    }
    else {
      this.close();
      this.onPopUpClosed.emit();
    }
  }
  ackConfirmexit = () => {
    this.dialogRef.close('close');
  }
  closeConfirm = () => {
    this.dialogRef.close();
  }

  getAuditNotes = (event) => {
    this.auditNotes = event.target.value;
  }

  softDeleteConfig = (event) => {
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popup.softDeletemessage, configName: this.configurationName, action: 'save' },
        disableClose: true,
      });
    confirmPopupInstance.afterClosed().subscribe(data => {
      if (data === 'OK') {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        const userName = userDetails.userName;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          body: {
            "auditNotes": this.auditNotes,
            "updatedBy": userName
          }

        };
        this.loadingService.openLoadingIndicator();
        this.apiService.deleteConfig(this.configData.configId, true, httpOptions).subscribe(res => {
          this.close();
          this.configResponse.emit(true);
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.softDeleteSuccess, 'success');
        }, (error) => {
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.errorNotification, 'error');
        });
      }
    });
  }

  hardDeleteConfig = (event) => {
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popup.hardDeleteMessage,configName: this.configurationName, action: 'save' },
        disableClose: true,
      });
    confirmPopupInstance.afterClosed().subscribe(data => {
      if (data === 'OK') {

        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        const userName = userDetails.userName;
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          body: {
            "auditNotes": this.auditNotes,
            "updatedBy": userName
          }

        };

        this.loadingService.openLoadingIndicator();
        this.apiService.deleteConfig(this.configData.configId, false, httpOptions).subscribe(res => {
          this.close();
          this.configResponse.emit(true);
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.hardDeleteSuccess, 'success');
        }, (error) => {
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.errorNotification, 'error');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dataServiceSubscription.unsubscribe();
  }

  ispopUpDataViewReference(dataReference) {
   let isItemdetail: Boolean;
    isItemdetail = false;
    this.resultFieldsDataRefList.forEach(results => {
      if (dataReference === results.jsonTag && results.popUpDataViewReference !== null) {
        isItemdetail = true;
       } 
    });
    return isItemdetail;
  }
}
