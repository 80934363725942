import { NgModule } from "@angular/core";
import { PaginatorDirective } from "./pagination.directive";

@NgModule({
    declarations: [PaginatorDirective],
    exports: [PaginatorDirective]
})

export class PaginatorModule {
}
