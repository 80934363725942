import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/loader/loader.service';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { popup } from '../../config/pop-up-content.json';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-popup',
  templateUrl: './admin-popup.component.html',
  styleUrls: ['./admin-popup.component.css']
})
export class AdminPopupComponent implements OnInit {
  [x: string]: any;
  userRoles: Array<any> = [];
  userRole: Array<any> = [];
  //roleName: string = 'COMMUNITY_USER';
  firstName: any;
  lastName: any;
  @Output() closeDivEmission = new EventEmitter();
  @Input() index: any;

  roleName: any;
  loggedInUserID: any;
  userId: any;
  roleId: any;
  userName: any;
  email: any;
  communityId: any;
  iamUid: any;
  applications: any[] = [];
  applicationsToAdd: Array<any> =[];
  applicationsToRemove: Array<any> =[];
  userHasNoApplication:any=true;
  selectedRole: any;
  communityName: any;
  community: any;
  selectedComm: any;
  loggedInCommName: any;
  communityApplications : Array<any> = [];

  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<AdminPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private loadingService: LoaderService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    const loginuserDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.loggedInUserID = loginuserDetails.userID;
    this.loggedInCommName = loginuserDetails.communityName;
    this.loggedInRoleName = loginuserDetails.roleName;
    this.loggedInCommID = loginuserDetails.communityId;
    this.userCommName = this.data.communityName;
    this.fetchUserRoles(this.data.communityId, this.loggedInUserID);
    this.roleName = !this.data.roleName ? "COMMUNITY_USER" : this.data.roleName;
    if(this.data.roleName === null)
    {
      this.roleName = this.data.roleName;
    }
    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;
    this.userId = this.data.userId;
    this.fetchUserApplication();
  }

  closePopup = () => {
    this.dialogRef.close();
  }

  fetchUserRoles(communityId, loggedInUserId) {
    this.loadingService.openLoadingIndicator();
    this.apiService.fetchUserRoles(communityId, loggedInUserId).subscribe((data: any = []) => {
      if (this.loggedInCommName === environment.communityName) {
        if ((this.loggedInCommID === this.data.communityId)) {
          let i = 0;
          data.roles.forEach(roles => {
            if (roles.roleName !== 'COMMUNITY_ADMIN') {
              this.userRoles[i] = roles;
              i++
            }
          });
        }else{
          this.userRoles = data.roles;
        }
      }
      else{
        this.userRoles = data.roles;
      }
      this.loadingService.closeLoadingIndicator();
    }, (error) => {
      if (error !== undefined) {
        this.loadingService.closeLoadingIndicator();
      }
    });
  }
  fetchUserApplication() {
       this.apiService.fetchUserApplications(this.userId, this.loggedInUserID, this.data.communityId).subscribe((data: any = []) => {
        this.communityApplications = data.communityApplications;
        if(this.communityApplications.length == 1) {
          this.applications = this.communityApplications[0].applications;
        } else {
          const communityApplication = this.communityApplications.find( x => x.communityName === this.loggedInCommName);
          this.applications = communityApplication ? communityApplication.applications : [];
        } 
        this.loadingService.closeLoadingIndicator();
      }, (error) => {
        if (error !== undefined) {
          this.loadingService.closeLoadingIndicator();
        }
      });
    
  }

  onChangeApp(event, applicationId) {
    let _add = [...this.applicationsToAdd];
    let _remove = [...this.applicationsToRemove];
    if(event.checked) {
      const application = this.applications.find( x => x.appIdPk === applicationId);
      application.hasUserAccess = true;
      _add.push(applicationId);
      const index = _remove.findIndex(x => x === applicationId);
      if(index !== -1) {
        _remove.splice(index,1);
      }
    } else {
      const application = this.applications.find( x => x.appIdPk === applicationId);
      application.hasUserAccess = false;
      _remove.push(applicationId);
      const index = _add.findIndex( x => x === applicationId);
      if(index !== -1) {
        _add.splice(index,1);
      }
    }
    this.applicationsToAdd = [..._add];
    this.applicationsToRemove = [..._remove];
  }
  onSelectRole = (event) => {
    this.selectedRole = event;
  }

  onSelectCommunity = (event) => {
    this.selectedComm = event;
    this.loggedInCommName = this.selectedComm;
    const communityApplication = this.communityApplications.find( x => x.communityName === this.loggedInCommName);
    this.applications = communityApplication ? communityApplication.applications : [];
  }

  saveUserDetails() {
    this.selectedRole = this.roleName;
	  this.applications.forEach(element => {
      if(element.hasUserAccess === true){
        this.userHasNoApplication=false;
        return;
      }
    });
    const role = this.userRoles.find(x => x.roleName === this.selectedRole);
    if(this.selectedRole !== undefined && this.selectedRole === null) {
      this.notifyService.showNotification(popup.roleNotSelected, 'info');
      this.loadingService.closeLoadingIndicator();
    }else if(this.userHasNoApplication) {
      this.notifyService.showNotification(popup.selectApplication, 'info');
      this.loadingService.closeLoadingIndicator();
    }else{
        this.loadingService.openLoadingIndicator();
        const param = { 'userId': this.data.userId, 'roleId': role.roleId, 'userName': this.data.userName, 'firstName': this.data.firstName, 'lastName': this.data.lastName, 'email':this.data.email, 'communityId':this.data.communityId, 'iamUID':this.data.iamUid , 'applicationsToAdd': this.applicationsToAdd, 'applicationsToRemove': this.applicationsToRemove };
        this.apiService.updateRolesAndAssignApplications(this.loggedInUserID, param).subscribe(res => {
        this.notifyService.showNotification(popup.updateUserRolesSuccess, 'success');
        this.loadingService.closeLoadingIndicator();
        this.dialogRef.close();
    },(error) => {
          if (error !== undefined) {
            this.loadingService.closeLoadingIndicator();
            this.notifyService.showNotification(popup.errorNotification, 'error');
            this.loadingService.closeLoadingIndicator();
            this.close();
        }
    });
    }
  }

  close = () => {
    this.closeDivEmission.emit(false);
    if (
      document.getElementsByClassName('expandible-row-div') !== undefined &&
      document.getElementsByClassName('expandible-row-div')[this.index] !== undefined
    ) {
      document.getElementsByClassName('expandible-row-div')[this.index]['style'].height = '0';
    }
  }
}