<div class="admin-popup">
    <div class="admin-header">
        <div class="user-application">
            <h2>User Access Configuration For {{firstName}} {{lastName}}</h2>
        </div>
        <div>
            <div class="roles-select">
                <span class="text">Roles</span>
                <mat-form-field
                    *ngIf="loggedInRoleName === 'OT_GLOBAL_ADMIN' || loggedInRoleName === 'COMMUNITY_ADMIN'">
                    <select matNativeControl (ngModelChange)="onSelectRole($event)" [(ngModel)]="roleName">
                        <option *ngFor="let role of userRoles" [ngValue]="role.roleName">
                            {{role.roleName}}
                        </option>
                    </select>
                </mat-form-field>
                <mat-form-field class="role-input" *ngIf="loggedInRoleName === 'COMMUNITY_USER'">
                    <input autocomplete="off" matInput type="text" disabled />
                    {{roleName || '&nbsp;'}}
                </mat-form-field>
            </div>

            <div class="community-select" *ngIf="communityApplications?.length > 1">
                <span class="text">Community</span>
                <mat-form-field>
                    <select matNativeControl (ngModelChange)="onSelectCommunity($event)" [(ngModel)]="loggedInCommName">
                        <option *ngFor="let communityApplication of communityApplications"
                            [ngValue]="communityApplication.communityName">
                            {{communityApplication.communityName}}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <div class="popupInfo">
                Please select Role from the drop-down list.
            </div>
        </div>
    </div>
    <div class="list-of-applications">
        <div class="list-left">
            <ul class="app-desc-profile">

                <ul class="inner-list" style="font-weight: bold;" *ngIf="applications.length > 0">
                    <li>Click To Enable</li>
                    <li>GM Application Name</li>
                    <li>Connect Profile Name</li>
                </ul>

                <ul class="inner-list" *ngFor="let app of applications | slice:0:12;">
                    <li>
                        <mat-checkbox (change)="onChangeApp($event, app.appIdPk)"
                            [disabled]="!(loggedInRoleName === 'OT_GLOBAL_ADMIN' || loggedInRoleName === 'COMMUNITY_ADMIN')"
                            [checked]="((app.hasUserAccess && applicationsToRemove.indexOf(app.appIdPk) === -1) || applicationsToAdd.indexOf(app.appIdPk) !== -1)">
                            {{app.applicationName}}</mat-checkbox>
                    </li>
                    <li>
                        <mat-form-field floatLabel="never">
                            <input autocomplete="off" matInput type="text" />{{app.description}}
                        </mat-form-field>
                    </li>
                    <li>
                        <mat-form-field floatLabel="never">
                            <input autocomplete="off" matInput type="text" />{{app.connectProfileName}}
                        </mat-form-field>
                    </li>
                </ul>
            </ul>
        </div>
        <div class="list-right">
            <ul class="app-desc-profile">
                <ul class="inner-list" style="font-weight: bold;" *ngIf="applications.length > 12">
                    <li>Click To Enable</li>
                    <li>GM Application Name</li>
                    <li>Connect Profile Name</li>
                </ul>

                <ul class="inner-list" *ngFor="let app of applications | slice:12;">
                    <li>
                        <mat-checkbox (change)="onChangeApp($event, app.appIdPk)"
                            [disabled]="!(loggedInRoleName === 'OT_GLOBAL_ADMIN' || loggedInRoleName === 'COMMUNITY_ADMIN')"
                            [checked]="((app.hasUserAccess && applicationsToRemove.indexOf(app.appIdPk) === -1) || applicationsToAdd.indexOf(app.appIdPk) !== -1)">
                            {{app.applicationName}}</mat-checkbox>
                    </li>
                    <li>
                        <mat-form-field floatLabel="never">
                            <input autocomplete="off" matInput type="text" />{{app.description}}
                        </mat-form-field>
                    </li>
                    <li>
                        <mat-form-field floatLabel="never">
                            <input autocomplete="off" matInput type="text" />{{app.connectProfileName}}
                        </mat-form-field>
                    </li>
                </ul>
            </ul>
        </div>
    </div>
    <div class="admin-popup-btns">
        <button *ngIf="loggedInRoleName === 'OT_GLOBAL_ADMIN' || loggedInRoleName === 'COMMUNITY_ADMIN'"
            (click)="saveUserDetails()">Save</button>
        <button (click)="closePopup()">Cancel</button>
    </div>

</div>