import { DataViews } from "./dataViews";
import { DbTblDef } from "./dbTblDef";
import { DbTblName } from "./dbTblNme";
import { SearchFields } from "./searchFields";

export class SearchRequestDto {
    createdBy: string;
    createdByDte: string;
    updatedBy: string;
    updatedByDte: string;
    configNme: string;
    commId: string;
    displayMessageType: string;
    displayApplication: string;
    primaryKeyRef: string;
    dbTblNme: DbTblName;
    dbTblDef: DbTblDef;
    searchFields: SearchFields[];
    dataViews: DataViews[];
}