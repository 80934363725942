import { Component, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title='EDI-search';
  username : string = "TESTUSER2";
  ssouid : string = "Test02";
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor() { 
    this.setTimeout();    
    this.userInactive.subscribe(() => {
      sessionStorage.clear()
      window.location.replace(environment.supplyPowerURL)
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3600000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  
  ngOnInit(): void {
}
}

