import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { SearchRequestDto } from '../model/searchRequestDto';
import { DbTblName } from '../model/dbTblNme';
import { SearchFields } from '../model/searchFields';
import { DatePipe } from '@angular/common';
import { DatePickerComponent, DatePickerDirective, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import moment from 'moment';
import { LoaderService } from '../loader/loader.service';
import { ItemdetailsComponent } from '../itemdetails/itemdetails.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageIdPopupComponent } from '../message-id-popup/message-id-popup.component';
import { AsnDetailsPopupComponent } from '../asn-details-popup/asn-details-popup.component';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { State } from '@ngrx/store';

export interface msgData {
  id: string;
  messgeType: string;
  messageState: string;
  externalSupplierCode: string;
  gMMGODELFORsupplierId: number;
}

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.css']
})

export class ManufacturerComponent implements AfterViewInit, OnInit {
  @ViewChild('applicationNameId', { static: false }) applicationNameId;
  @ViewChild('messageTypeId', { static: false }) messageTypeId;
  @ViewChild('partNumberId', { static: false }) partNumberId;
  @ViewChild('shipmentIds', { static: false }) shipmentIds;
  @ViewChild('controlNumberId', { static: false }) controlNumberId;
  @ViewChild('messageStateIds', { static: false }) messageStateIds;
  @ViewChild('supplierIds', { static: false }) supplierIds;
  @ViewChild('externalSupplierCodeId', { static: false }) externalSupplierCodeId;
  @ViewChild('messageIds', { static: false }) messageIds;
  @ViewChild('startDateId', { static: false }) startDateId;
  @ViewChild('endDateId') endDateId;
  @ViewChildren('ref') idList: QueryList<HTMLAllCollection>;
  @ViewChild('datePicker') datePicker: DatePickerComponent;
  @ViewChild('dateDirectivePicker') datePickerDirective: DatePickerDirective;
  minDate = moment(new Date().setDate(new Date().getDate() - 30)).format('MM-DD-YYYY');
  maxDate = moment(new Date());
  config: IDatePickerDirectiveConfig = {
    hours24Format: 'HH',
    showTwentyFourHours: false,
    firstDayOfWeek: 'mo',
    locale: 'en',
    closeOnSelect: true,
    closeOnSelectDelay: 100,
    showSeconds: false,
    format: "MM-DD-YYYY, LT",
    min: this.minDate,
    max: this.maxDate
  };


  requiredFields = ['meta.app', 'meta.msgtyp', 'meta.sentDte'];
  displayedColumns = [];
  dataSource = new MatTableDataSource<msgData>();
  public searchRequestDto: SearchRequestDto;
  public dbTblNme: DbTblName;
  appicationName: any;
  messageType: any = 'default';
  partNumber: any;
  shipmentId: any;
  controlNumber: any;
  messageState: any;
  supplierId: any;
  externalSupplierCode: any;
  messageId: any;
  startDate: any;
  endDate: any;
  columnArray: any[];
  xyz: any;
  pageIndex: Number = 0;

  applicationNameValue: any;
  messageTypeValue: any;
  partNumberValue: SearchFields;
  shipmentIdValue: any;
  controlNumberValue: SearchFields;
  messageStateValue: any;
  supplierIdValue: any;
  externalSupplierCodevalue: any;
  messageIdValue: any;
  startDateValue: any;
  endDateValue: any;
  docRefNumber: any;

  isRecordsExists: boolean = false
  searchFields: any;
  searchFieldsArray: SearchFields[];
  releaseNumber: any;
  searchModalValues: any;

  message: Array<any> = [];
  grantedApplications: Array<any> = [];
  grantedMessages: Array<any> = [];
  public msgs: any = [];
  public newData: any = [];
  fromDate: any;
  endDates: any;
  flag: any;
  messageTypeShortName: any;
  shipToValue: any;
  applicationName: any = 'default';
  messageName: string;
  event: any;
  tabledatas = [];
  sourceTable: string;
  itemDetailsValue: any;
  itemDetails = {};
  obj = {};
  dialogRef: any;
  apple: any;
  isConfigApiCalled: boolean;
  isPanelExpanded: boolean = false;
  arrowIcon: any = "assets/icons/left-back.png";
  arrowTitle: string;
  searchTabData: any;
  searchFieldsDataRefList: any;
  selectedApplication: any;
  selectedMessageType: any;
  defaultApplicationName: any;
  defaultMessageType: any;
  maxLengthValues: any;
  datatype: any;
  virtualcol: any;
  asnDashBoardEnable: boolean;
  searchResultcolumnWidth = {};
  constructor(public dialog: MatDialog, public apiService: ApiService, public datepipe: DatePipe, private loadingService: LoaderService, private _cd: ChangeDetectorRef) {
    // this.columnArray=this.fields;
    let end = new Date();
    end.setHours(23, 59, 0, 0);
    this.endDates = moment(new Date(end).toLocaleString()).format("MM-DD-YYYY, LT");
    let startDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.fromDate = moment(new Date(startDate.setHours(0, 0, 0)).toLocaleString()).format("MM-DD-YYYY, LT");
    this.searchRequestDto = new SearchRequestDto();
  }


  fetchRecords() {
    this.newData = [];
    this.constructSearchCriteria();
    this.loadingService.openLoadingIndicator();
    this.apiService.fetchRecords(this.searchRequestDto).subscribe((data: any = []) => {
      this.isRecordsExists = false;
      this.loadingService.closeLoadingIndicator();
      this.newData = [...data.messages];
      if(this.newData && this.newData.length > 0 && this.asnDashBoardEnable) {
        const subMessages = this.newData.slice(0,this.newData.length >= 40 ? 40 : this.newData.length).filter(x => x['CHILD_MESSAGE_ID'] === undefined);
        this.getMessageStatus(subMessages);
      }
      this.dataSource = new MatTableDataSource<msgData>(this.newData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.tableSort;
    }, (error) => {
      if (error) {
        this.isRecordsExists = true;
        this.dataSource = new MatTableDataSource<msgData>([]);
        this.loadingService.closeLoadingIndicator();
      }
    });
  }

  getMessageStatus = (subMessages: any) => {
    let payload = [];
    if(subMessages) {
      for(let i=0; i<subMessages.length; i++) {
        let unixTimestamp = 0;
        const message = subMessages[i];
        if(message['Delivered Date'] !== '-'){
          const dateStr = message['Delivered Date'];
          const [dateComponents, timeComponents] = dateStr.split(' ');
          const [month, day, year] = dateComponents.split('/');
          const [hours, minutes, seconds] = timeComponents.split(':');
          const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
          // Get Unix timestamp
          unixTimestamp = Math.floor(date.getTime() / 1000);
        }
        payload.push({
          'MrsShipmentKey': message['MRSShipmentKey'],
          'ShipperId':message['Shipment ID'],
          'MessageID':message['Message Id'],
          'DeliveredTimestamp':message['Delivered Date'] === '-' || message['Delivered Date'] === undefined || message['Delivered Date'] === null ? -1 : unixTimestamp        })
      }
    }
    const originalMessages = this.newData;
    const messageStatusesRequest = {"messageStatusesRequest": payload}
    this.apiService.getMessageState(messageStatusesRequest).subscribe((response: any) => {
      response.messageStatusesRequest.forEach(data => {
        const messageId = data['MessageID'];
        const shipperId = data['ShipperId'];
        const mrsShipmentKey = data['MrsShipmentKey'];
        const deliveryStatus = data['DeliveryStatus'];
        originalMessages
          .filter(originalMessage => originalMessage['Message Id'] === messageId && originalMessage['Shipment ID'] === shipperId && originalMessage['MRSShipmentKey'] === mrsShipmentKey)
            .forEach(element => {
              element['Message State'] =  deliveryStatus; 
            });
        });
    });
  }

  constructSearchCriteria = () => {
    this.searchFieldsArray = [];
    this.searchRequestDto.searchFields = [];
    if (this.applicationName && this.applicationNameId) {
      this.applicationNameValue = this.searchFields.find(x => x.dataReference == this.applicationNameId.nativeElement.id);
      this.applicationNameValue.dataValue = this.applicationName.trim();
      this.searchFieldsArray.push(this.applicationNameValue);
    }

    if (this.messageTypeId && this.messageType) {
      this.messageTypeValue = this.searchFields.find(x => x.dataReference == this.messageTypeId.nativeElement.id);
      this.messageTypeValue.dataValue = this.selectedMessageType['shortName']?.trim();
      this.searchFieldsArray.push(this.messageTypeValue);
    }
    this.searchFields.forEach(element => {
      if (this.idList !== undefined && this.idList.length > 0) {
        this.idList.forEach(htmlEle => {
          if (this.searchModalValues[element['dataReference']] !== undefined && this.searchModalValues[element['dataReference']] !== null && this.searchModalValues[element['dataReference']] !== "") {
            const val = this.searchFields.filter(x => x.dataReference == htmlEle['nativeElement'].id);
            if (val[0].dataReference === element['dataReference']) {
              val[0].dataValue = this.searchModalValues[element['dataReference']]?.trim();
              this.searchFieldsArray.push(val[0]);
            }
          }
        });
      }
    });

    if (this.fromDate != undefined && this.fromDate != '' && !this.flag) {
      // let updatedStartDate = this.datepipe.transform(this.fromDate, 'MM-dd-yyyy HH:mm');
      const splitdate = this.fromDate.split(',');
      let updatedStartDate = splitdate[0] + ' ' + moment(splitdate[1], ["HH:mm A"]).format("HH:mm");
      this.startDateValue = this.searchFields.filter(x => x.dataReference == this.startDateId.nativeElement.id && (x.displayName === 'Start Date/Time'));
      this.startDateValue[0].dataValue = updatedStartDate?.trim();
      this.searchFieldsArray.push(this.startDateValue[0]);
    }
    if (this.endDates != undefined && this.endDates != '' && !this.flag) {
      // let updatedEndDate = this.datepipe.transform(this.endDates, 'MM-dd-yyyy HH:mm');
      const splitdate = this.endDates.split(',');
      let updatedEndDate = splitdate[0] + ' ' + moment(splitdate[1], ["HH:mm A"]).format("HH:mm");
      this.endDateValue = this.searchFields.filter(x => x.dataReference == this.endDateId.nativeElement.id && (x.displayName === 'End Date/Time'));
      this.endDateValue[0].dataValue = updatedEndDate?.trim();
      this.searchFieldsArray.push(this.endDateValue[0]);
    }
    this.searchRequestDto.searchFields = this.searchFieldsArray;
  }

  ngOnInit(): void {
    this.loadingService.openLoadingIndicator();
    this.pageIndex = 0;
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.apiService.getMessageTypeAndApplicationName(userDetails.userID).subscribe((data: any = []) => {
      this.grantedApplications = data.listOfGrantedApplication;
      if (this.grantedApplications && this.grantedApplications.length > 0) {
        this.messageTypeShortName = data?.defaultMessageTypeShortName;
        this.applicationName = data.defaultApplication ? data.defaultApplication : this.grantedApplications[0].application;
        this.defaultApplicationName = this.applicationName;
        this.shipToValue = data.shipToReference ? data.shipToReference : '';
        this.updateSelectedApplication();
        this.messageType = data.defaultMsgType ? data.defaultMsgType : this.messageType;
        this.defaultMessageType = this.messageType;
        this.updatedSelectedMessageType();
        this.clearSearchResultColumnsAndData();
        this.callConfigApi();
        this._cd.detectChanges();
      }
      //For Generating Dynamic Values kept this array inside onInit
      this.searchModalValues = {};
      this.loadingService.closeLoadingIndicator();

    });
  }

  updateSelectedApplication = () => {
    this.selectedApplication = this.grantedApplications.find(app => { return app.application === this.applicationName });
    this.grantedMessages = this.selectedApplication.messageTypeAndDefn.sort((a,b) => (a.messageType > b.messageType) ? 1 : ((b.messageType > a.messageType) ? -1 : 0));
    this.selectedMessageType = this.grantedMessages[0];
    this.messageType = this.selectedMessageType.messageType;

  }

  updatedSelectedMessageType = () => {
    this.selectedMessageType = this.grantedMessages.find(message => { return message.messageType === this.messageType });
    this.messageType = this.selectedMessageType.messageType;
  }

  onApplicationChanged = () => {
    this.updateSelectedApplication();
    this.callConfigApi();
    this._cd.detectChanges();
    this.clearSearchResultColumnsAndData();
    this.flag = false;
  }

  onMessageTypeChanged = () => {
    this.updatedSelectedMessageType();
    this.callConfigApi();
    this._cd.detectChanges();
    this.clearSearchResultColumnsAndData();
    this.flag = false;
  }

  clearSearchResultColumnsAndData = () => {
    this.displayedColumns = [];
    if (this.dataSource != undefined && this.dataSource.data != undefined) {
      this.dataSource.data = [];
    }
  }

  // MatPaginator Inputs
  paginationInfo;
  pageSizeOptions: number[] = [5, 10, 25, 100];


  setPageSizeOptions = (setPageSizeOptionsInput: string) => {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('TableSort', { static: false }) tableSort: MatSort;

  ngAfterViewInit() {
    this.paginator.pageSize = 40;
    this.dataSource.paginator = this.paginator;
    this._cd.detectChanges();
  }

  public isSortingDisabled(columnText: string): boolean {
    if (columnText) {
      return false;
    }
    else {
      return true;
    }
  }

  callConfigApi() {
    if (this.messageType != undefined && this.applicationName != undefined) {
      // this.loadingService.openLoadingIndicator();
      this.apiService.getConfiguration(this.messageType, this.applicationName).subscribe((data: any = []) => {
        this.isConfigApiCalled = true;
        let config = data.configuration;
        if (config != undefined &&
          config.dataViews != undefined &&
          config.dataViews.searchResult != undefined &&
          config.dataViews.searchResult.fields != undefined) {
          this.columnArray = config.dataViews.searchResult.fields;
          this.columnArray = this.columnArray.filter(element => element.displayName != null)
        }
        this.columnArray.forEach(header => {          
          this.displayedColumns.push(header.displayName);
          this.searchResultcolumnWidth[header.displayName] = header['headerColumnWidth'];
          // To set default Header column Width to 210 
          // if(header['headerColumnWidth'] === 0){
          //   this.searchResultcolumnWidth[header.displayName] = 210+'px';
          // }else{
          //   this.searchResultcolumnWidth[header.displayName] = header['headerColumnWidth']+'px';
          // }          
        })        
        delete this.tableSort.active;
        delete this.isRecordsExists;
        this.searchRequestDto.createdBy = data.created_by;
        this.searchRequestDto.createdByDte = data.created_date;
        this.searchRequestDto.updatedBy = data.updated_by;
        this.searchRequestDto.updatedByDte = data.updated_date;
        this.searchRequestDto.configNme = config.configNme;
        this.searchRequestDto.commId = config.commId;
        this.searchRequestDto.displayApplication = config.displayApplication;
        this.searchRequestDto.displayMessageType = config.displayMessageType;
        this.searchRequestDto.primaryKeyRef = config.primaryKeyRef;
        this.searchRequestDto.dbTblNme = config.dbTblNme;
        this.searchRequestDto.searchFields = config.searchFields;
        this.searchFields = config.searchFields;
        this.asnDashBoardEnable = config.dataViews.searchResult.asnDashboardEnabled;
        this.searchFields.forEach(element => {
          const ref = element.dataReference;
          if (element.dataReference !== 'meta.app'
            && element.dataReference !== 'meta.msgtyp' && element.dataReference !== 'meta.sentDte') {
            this.searchModalValues[ref] = '';
          }
        });
        this.searchModalValues['meta.shpTo'] = this.shipToValue;
        //.sort((a, b) => (parseInt(a.displayOrder) > parseInt(b.displayOrder)) ? 1 : ((parseInt(a.displayOrder) === parseInt(b.displayOrder)) ? ((a.displayName < b.displayName) ? 1 : -1) : -1));
        this.searchRequestDto.dataViews = config.dataViews;
        this.searchRequestDto.dbTblDef = config.dbTblDef;
        this.sourceTable = this.searchRequestDto.dbTblNme.detail;
        this.itemDetailsValue = config.dataViews.itemDetail;
        const arr = [];
        config.dbTblDef.forEach(val => {
          if (val.tblType === "meta") {
            val.virtualColumns.forEach(virtualcol => {
              if (virtualcol.jsonTag !== 'meta.configNme' && virtualcol.jsonTag !== 'meta.app'
                && virtualcol.jsonTag !== 'meta.msgtyp' && virtualcol.jsonTag !== 'meta.sentDte') {
                const obj = {};
                this.datatype = virtualcol.datatype.replace(/\D/g, '');
                obj[virtualcol.jsonTag] = this.datatype.replace(2, '');
                arr.push(obj);
              }
            });
          } else {
            val.virtualColumns.forEach(virtualcol => {
              const obj = {};
              this.datatype = virtualcol.datatype.replace(/\D/g, '');
              obj['itemDetail.' + virtualcol.jsonTag] = this.datatype.replace(2, '');
              arr.push(obj);
            });
          }
        });
        this.maxLengthValues = Object.assign({}, ...arr);
      });

    }
  }

  getConfirmTextValue() {
    if ((this.searchModalValues['meta.cntrlNbr'] !== '') || (this.searchModalValues['meta.msgId'] !== '')) {
      this.flag = true;
      Object.entries(this.searchModalValues).forEach(([headKey, headVal]) => {
        if (((headKey !== 'meta.cntrlNbr') && (headKey !== 'meta.msgId')) && headVal !== '' && headVal !== undefined) {
          this.flag = false;
        }
      });
    } else {
      this.flag = false;
    }
  }

  clear() {
    this.searchModalValues = {};
    let end = new Date();
    end.setHours(23, 59, 0, 0);
    this.endDates = moment(new Date(end).toLocaleString()).format("MM-DD-YYYY, LT");
    let startDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.fromDate = moment(new Date(startDate.setHours(0, 0, 0)).toLocaleString()).format("MM-DD-YYYY, LT");
    this.applicationName = this.defaultApplicationName;
    this.updateSelectedApplication();
    this.messageType = this.defaultMessageType;
    this.updatedSelectedMessageType();
    this.flag = false;
    this.clearSearchResultColumnsAndData();
    this.callConfigApi();
    this._cd.detectChanges();
  }

  /**
   * Method is used to get field data and heading and call respective popup
   * @param data - data value of respective column (Ex: 'AQ1AMHRKHH', '23509123')
   * @param field - header value of of respective column (Ex: 'Message Id', 'Part Number' etc.,)
   * @param payloadData - payloadData contains the array of payload types (Ex: ['common', 'sender'])
   * @param terminator - Segment Terminator which is used to split payload into segements (Ex: ~, ')
   */
  getData(data: any, dataReference: any, field: any, payloadData: any) {
    
    if (payloadData['type'] === 'MESSAGE_ID') {
      const messageId = data['CHILD_MESSAGE_ID'] ? data['CHILD_MESSAGE_ID'] : data[field];
      this.openMessageIdPopup(messageId, payloadData, data['SegmentTerminator'], data['Control Number']);
    } else if (payloadData['type'] === 'DATA_VIEW') {
      this.openDataViewPopup(data[field], dataReference ,data['META_ID']);
    }
  }

  /**
   * Method is used to open popup and send data to respective popup component
   * @param data - data value of Message Id (Ex: 'AQ1AMHRKHH')
   */
  openMessageIdPopup = (data, payload, terminator, controlNumber) => {
    this.loadingService.openLoadingIndicator();
    this.apiService
      .getMessageIdData(data)
      .subscribe((messageData) => {
        const messageIdData = {};
        messageIdData['payload'] = payload['payload'][1];
        messageIdData['terminator'] = terminator;
        messageIdData['responseData'] = messageData;
        messageIdData['controlNumber'] = controlNumber;
        this.dialogRef = this.dialog.open(MessageIdPopupComponent, {
          data: messageIdData,
          disableClose: true,
          width: '70em',
          minHeight: 'calc(100vh - 230px)',
        });
        this.loadingService.closeLoadingIndicator();
      });
  }

  openDataViewPopup = (dataValue: any, dataReference: any, metaId: any) => {
    this.itemDetails['itemDetail'] = this.itemDetailsValue;
    this.loadingService.openLoadingIndicator();
    this.apiService.getDataValue(dataValue, dataReference, metaId, this.itemDetails, this.sourceTable).subscribe((data: any = []) => {
      const popUpData = {};
      popUpData['partTitle'] = this.itemDetailsValue.title;
      popUpData['apiResponse'] = data;
      this.loadingService.closeLoadingIndicator();
      this.dialog.open(ItemdetailsComponent, {
        data: popUpData,
        disableClose: true,
        width: '78em',
        minHeight: 'calc(100vh - 230px)'
      });
    });
  }

  /**
   * Method is used to bind icon on HTML based on message state value
   * @param fieldValue - Data value of column 'Message State' (Ex: 'FAILED', 'DELIVERED', 'ACCEPTED WITH ERRORS')
   * @param fieldName - Heading of particular column where this method is triggered
   * @returns 
   */
  showIcon = (fieldValue, fieldName) => {
    const msgType = this.selectedMessageType.messageType;
    if (this.asnDashBoardEnable && fieldName === 'Message State' && (fieldValue === 'FAILED' || fieldValue === 'ACCEPTED WITH ERRORS' || fieldValue === 'Failed' || fieldValue === 'Accepted With Errors')) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method is used to open popup and send data to respective popup component
   * @param fieldValue - Data value of column 'Message State' (Ex: 'FAILED', 'DELIVERED', 'ACCEPTED WITH ERRORS')
   * @param terminator - Segment Terminator which is used to split payload into segements (Ex: ~, ')
   */
  openAsnPopup = (fieldValue, terminator, shipmentKey, controlNumber) => {
    const messagePayload = this.columnArray.filter(column => column.displayName === 'Message Id');
    if (fieldValue === 'FAILED' || fieldValue === 'Failed') {
      const processedData = {};
      processedData['heading'] = "Functional Acknowledgement Information";
      processedData['className'] = "fa fa-times";
      processedData['messagePayload'] = messagePayload[0]['link'].payload;
      processedData['terminator'] = terminator;
      processedData['controlNumber'] = controlNumber;
      this.apiService.getFunctionalAcknowledgement(shipmentKey).subscribe((response: any) => {
        processedData['response'] = response;
        this.dialog.open(AsnDetailsPopupComponent, {
          data: processedData,
          disableClose: true,
          width: '80em',
          minHeight: 'calc(100vh - 220px)'
        })
      });
    } else if (fieldValue === 'ACCEPTED WITH ERRORS' || fieldValue === 'Accepted With Errors') {
      const processedData = {};
      processedData['heading'] = "Application Advice Information";
      processedData['className'] = "fa fa-check";
      processedData['messagePayload'] = messagePayload[0]['link'].payload;
      processedData['terminator'] = terminator;
      processedData['shipmentKey'] = shipmentKey;
      processedData['controlNumber'] = controlNumber;
      this.apiService.getApplicationAdvice(shipmentKey).subscribe((response: any) => {
        processedData['response'] = response;
        this.dialog.open(AsnDetailsPopupComponent, {
          data: processedData,
          disableClose: true,
          width: '80em',
          minHeight: 'calc(100vh - 220px)'
        });
      });
    }
  }

  switchIcon = () => {
    this.isPanelExpanded = !this.isPanelExpanded;
    if (this.isPanelExpanded) {
      this.arrowIcon = "assets/icons/right-back.png";
      this.arrowTitle = "Expand";
    } else {
      this.arrowIcon = "assets/icons/left-back.png";
      this.arrowTitle = "Collapse";
    }
  }

  dateTimeSelected = (event) => {
    if (this.datePickerDirective !== undefined) {
      this.datePickerDirective.api.close();
    }
  }

  onPaginateChange = (event) => {
    const pageNumber = event.pageIndex + 1;
    if(pageNumber != 1) {
      const maxLimit = (pageNumber * 40);
      const minLimit = (maxLimit - 40);
      const pageData = this.newData.slice(minLimit, this.newData.length >= maxLimit ? maxLimit : this.newData.length);
      const payload = {
        "messages" : pageData
      };
      this.apiService.getMessageState_v2(payload, this.asnDashBoardEnable).subscribe((data: any = []) => {
        const originalMessages = this.newData;
        data.messages.forEach(message => {
          const messageId = message['Message Id'];
          originalMessages
            .filter(x => (x['Message Id'] === messageId && x['Shipment ID'] === message['Shipment ID']))
              .forEach(originalMessage => {
                if(message['CHILD_MESSAGE_ID']) {
                  originalMessage['CHILD_MESSAGE_ID'] = message['CHILD_MESSAGE_ID'];
                }
                if(message['Message State']) {
                  originalMessage['Message State'] = message['Message State'];
                }
                if(message['Delivered Date']) {
                  originalMessage['Delivered Date'] = message['Delivered Date'];
                }
                originalMessage['MRSShipmentKey'] = message['MRSShipmentKey'];
          });
        });
        if(this.asnDashBoardEnable) {
           const subMessages = data.messages.filter(x => x['CHILD_MESSAGE_ID'] === undefined);
          this.getMessageStatus(subMessages);
        }
      },(error) => {
       console.log(error);
      });
    }
    this.pageIndex = pageNumber;
  }
  
  position = new FormControl('above');

}
