export const environment = {
    production: false,
    configURL: "https://edi-configuration.stg.covisint.com/edisearch/api",
    searchURL: "https://edi-search.stg.covisint.com/edisearch/api",
    userURL: "https://edi-user.stg.covisint.com/edisearch/api",
    securityURL: "https://edi-security.stg.covisint.com/edisearch/api",
    supplyPowerURL:"https://gmsp-ng.portal.stg.covisint.com/",
    dashBoardCommunityId:1,
    redirectURL: "https://sso-ng.stg.covisint.com/login.do",
    communityName:'Covisint'
  };
