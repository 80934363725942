import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class DataService {
    private configurationButtonNameChange = new BehaviorSubject<string>('');
    public configurationButtonNameChange$ = this.configurationButtonNameChange.asObservable();

    private configurationSaveClick = new BehaviorSubject<string>('');
    public configurationSaveClick$ = this.configurationSaveClick.asObservable();

    private configurationPayloadTransfer = new BehaviorSubject<any>('');
    public configurationPayloadTransfer$ = this.configurationPayloadTransfer.asObservable();

    getConfigurationButtonName = (name) => {
        this.configurationButtonNameChange.next(name);
    }

    getConfigurationSaveEvent = (name) => {
        this.configurationSaveClick.next(name);
    }

    getConfigurationPayload = (payload) => {
        this.configurationPayloadTransfer.next(payload);
    }
}
