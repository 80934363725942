<div class="left-search-right-result text-center">
    <div class="container">
        <mat-drawer-container>
            <mat-drawer mode="push" #drawer opened="true">
                <div class="left-search-fields">
                    <div class="user-form">
                        <form name="myForm" method="" class="form-input">
                            <h1>Search for a different user:</h1>
                            <div class="flex-d">
                                <div class="labels-input">
                                    <label>First Name</label>
                                    <mat-form-field>
                                        <input [(ngModel)]="firstName" name="firstName" autocomplete="off" matInput type="text"/>
                                    </mat-form-field>
                                </div>
                                <div class="labels-input">
                                    <label>Last Name</label>
                                    <mat-form-field>
                                        <input [(ngModel)]="lastName" name="lastName" autocomplete="off" matInput type="text" />
                                    </mat-form-field>
                                </div>
                                <div class="labels-input">
                                    <label>Email</label>
                                    <mat-form-field>
                                        <input [(ngModel)]="email" name="email" autocomplete="off" matInput type="text" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="admin-btns">
                                <button (click)="searchUsers()">Search</button>
                                <button (click)="clear()">Clear</button>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <button class="arrow" (click)="drawer.toggle();switchIcon()" title="Collapse">
                    <div class="expand-arrow">
                        <img src="{{arrowIcon}}" [attr.title]=arrowTitle>
                    </div>
                </button>
                <div class="tab-cont" [ngClass]="{'fullWidth': isPanelExpanded}">
                    <div class="mat-elevation-z8 table-responsive">
                        <table [dataSource]="dataSource" mat-table matSort class="admin">
                            <ng-container matColumnDef="firstName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                                <td mat-cell *matCellDef="let element"  (click)="cellClicked(element)"matTooltip="{{element['firstName']}}"
                                matTooltipPosition="above" matTooltipClass="custom-tooltip"> {{element['firstName']}}</td>
                            </ng-container>

                            <ng-container matColumnDef="lastName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                                <td mat-cell *matCellDef="let element"  (click)="cellClicked(element)"matTooltip="{{element['lastName']}}"
                                matTooltipPosition="above" matTooltipClass="custom-tooltip"> {{element['lastName']}} </td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                <td mat-cell *matCellDef="let element"  (click)="cellClicked(element)" matTooltip="{{element['email']}}"
                                matTooltipPosition="above" matTooltipClass="custom-tooltip"> {{element['email']}} </td>
                            </ng-container>

                            <ng-container matColumnDef="roleName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                                <td mat-cell *matCellDef="let element"  (click)="cellClicked(element)"> {{element['roleName']}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Delete">
                                <th mat-header-cell *matHeaderCellDef> Delete </th>
                                <td mat-cell *matCellDef="let element"><img *ngIf="element['roleName'] !== null" src="/assets/img/revoke.svg"(click)="deleteUser(element)"></td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
                        </table>
                        <table class="clearT">
                            <tr *ngIf=!isRecordsExists>
                                <td [attr.colspan]="columnNames.length">
                                    No Results Were Found
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- <mat-paginator appPagination [length]="50" [pageSizeOptions]="[40]" [hidePageSize]='true'
                    [hidden]=isRecordsExists [pageSize]="40">
                </mat-paginator> -->
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>