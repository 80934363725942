import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { popup } from '../../config/pop-up-content.json';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/loader/loader.service';
export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-config-popup',
  templateUrl: './config-popup.component.html',
  styleUrls: ['./config-popup.component.css']
})
export class ConfigPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() configResponse = new EventEmitter();
  dialogRefe: any;
  buttonText: any;
  configType = 'New';
  configPayload: any;
  buttonNameSub: Subscription;
  configPayloadSub: Subscription;
  isPopUpOpen = false;
  showButton = false;
  @ViewChild('config_back') configBack; 
  tabLabel: any;

  constructor(
    private dialogRef: MatDialogRef<ConfigPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private notifyService: NotificationService,
    private apiService: ApiService,
    private dataService: DataService,
    private loadingService: LoaderService
  ) {
    this.buttonNameSub = this.dataService.configurationButtonNameChange$.subscribe((item) => {
      this.buttonText = item;
    });

    this.configPayloadSub = this.dataService.configurationPayloadTransfer$.subscribe((payload) => {
      this.configPayload = payload;
    });
  }

  ngOnInit(): void {
    this.dataService.getConfigurationSaveEvent('');
    this.configPayload = {};
  }
  
  hideOrShowButton = (tabsVisibility : boolean) => {    
    this.showButton = tabsVisibility;
  } 

  closePopup = () => {
    // this.dialogRef.close();
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popup.cancelContent, exit: 'close' },
        disableClose: true,
      });

    confirmPopupInstance.afterClosed().subscribe(data => {
      if (data === 'close') {
        this.dialogRef.close();
      }
    });
  }
  openConfirmPopup = () => {
    this.tabLabel = this.tabLabel+1;
    this.dataService.getConfigurationSaveEvent(this.buttonText);
    if (this.buttonText === 'Save' && this.configPayload?.isValid && !this.isPopUpOpen) {
      this.showMessage();
    }
  }

  validateOnBackButtonClick = () => {
    this.tabLabel = this.tabLabel - 1;
    this.dataService.getConfigurationSaveEvent('Back');
  }

  showMessage = () => {
    this.isPopUpOpen = true;
    let configNme = this.configPayload.configPayload.configuration.configNme;
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popup.ackContent, configName:configNme, action: 'save' },
        disableClose: true,
      });
    confirmPopupInstance.afterClosed().subscribe(data => {
      this.isPopUpOpen = false;
      if (data === 'close') {
        this.dialogRef.close();
        this.notifyService.showNotification(popup.successContent, 'success');
      } else if (data === 'OK') {
        this.loadingService.openLoadingIndicator();
        this.apiService.saveNewConfiguration(this.configPayload?.configPayload).subscribe((response) => {
          this.dialogRef.close(true);
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.successContent, 'success');
        }, (error) => {
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.errorNotification, 'error');
        });
      } else if (data === undefined) {
        this.configPayload = {};
      }
    });
  }

  ngOnDestroy(): void {
    // this.buttonNameSub.unsubscribe();
    // this.configPayloadSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.tabLabel = 0;
  }
}
