<div class="message-id-container">
    <div class="header">
        <a class="closePop" (click)="closeModal()"><img src="/assets/img/close.svg"></a>
    </div>
    <div class="message-popup-body">
        <div class="data-div">
            <div *ngFor="let val of processedData" [ngClass]="val?.style === 'fullWidth' ? 'completeRow' : 'splitRow'" [ngSwitch]="val.value">
                <span class="heading">{{val.key}}: </span>
                <span class="data" *ngSwitchCase= "'null'"></span>
                <span class="data" *ngSwitchCase = null></span>
                <span class="data" *ngSwitchDefault>{{val.value}}</span>
            </div>
        </div>
        <div class="rawData" *ngIf="showPayload">
            <div *ngFor="let data of decryptedRawData">{{data}}</div>
        </div>
        <div class="error" *ngIf="showError">
            <div class="failureDiv">
                <span class="errorHeading">Failure Details:</span>
                </div>
                <div class="failureDetails" *ngIf="!errorExist">
                <div *ngFor="let val of failureDetails" >
                    <span class="headKey">{{val.key}}: </span>
                    <span class="dataVal">{{val.value}}</span>
                </div>
                </div>
                <div class="errorMsgs" *ngIf="errorExist">
                    <span class="errorMsg">{{errorMsg}}</span>
                </div>
        </div>
    </div>
    <div class="footer" *ngIf="showPayload">
        <a class="download" [href]="fileData" download="{{fileName}}.dat" (click)="downloadData()">{{buttonText}}</a>
    </div>
</div>