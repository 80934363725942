import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { PaginatorModule } from '../manufacturer/pagination.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import {MatSidenavModule} from '@angular/material/sidenav';
import { UserPreferenceComponent } from './user-preference.component';
import { OrderByPipeModule } from '../pipes/application-pipes.module';
const routes: Routes = [
    { path: '', component: UserPreferenceComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatPaginatorModule,
        PaginatorModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatSortModule,
        MatSidenavModule,
        OrderByPipeModule
    ],
    declarations: [
        
    ],
    exports: []
})

export class UserPreferenceModule {
    static rootComponent = UserPreferenceComponent;
}
