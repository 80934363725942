import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader/loader.service';
import { ApiService } from '../services/api.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.css']
})
export class UserPreferenceComponent implements OnInit {

  constructor(private apiService: ApiService, private loadingService: LoaderService, private notifyService: NotificationService) { }
  grantedApplications: Array<any> = [];
  grantedMessages: Array<any> = [];
  applicationName: any;
  messageType: any;
  selectedApplication: any;
  selectedMessageType: any;
  shipTo: any;
  defaultApplication: any;
  defaultMessageType: any;
  defaultShipTo: any;
  isDisabled: boolean = true;
 
  ngOnInit(): void {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.apiService.getMessageTypeAndApplicationName(userDetails.userID).subscribe((data: any = []) => {
      this.grantedApplications = data.listOfGrantedApplication;
      this.defaultApplication = data.defaultApplication ? data.defaultApplication : this.grantedApplications[0].application;
      this.applicationName =  this.defaultApplication;
      this.updateSelectedApplication();
      this.defaultMessageType = data.defaultMsgType ? data.defaultMsgType : this.messageType;
      this.messageType = this.defaultMessageType;
      this.updatedSelectedMessageType();
      this.defaultShipTo = data.shipToReference;
      this.shipTo = data.shipToReference;
      this.loadingService.closeLoadingIndicator()
    });
  }
  
  updateSelectedApplication = () => {
    this.selectedApplication = this.grantedApplications.find(app => {return app.application === this.applicationName});
    this.grantedMessages = this.selectedApplication.messageTypeAndDefn.sort((a,b) => (a.messageType > b.messageType) ? 1 : ((b.messageType > a.messageType) ? -1 : 0));
    this.selectedMessageType = this.grantedMessages[0];
    this.messageType = this.selectedMessageType.messageType;
    this.isDisabled = false;
  }

  updatedSelectedMessageType = () => {
    this.selectedMessageType = this.grantedMessages.find(message => {return message.messageType === this.messageType});
    this.messageType = this.selectedMessageType.messageType;
  }

  saveUserPreferences = () => {
    this.loadingService.openLoadingIndicator();
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    const userId = userDetails.userID;
    const payload = { 'userId': userId, 'applicationId': this.selectedApplication.applicationId, 'messageTypeId': this.selectedMessageType.messageTypeId, 'shipToPreference': this.shipTo };
    this.apiService.saveUserPreferences(payload, userId).subscribe((response: any) => {
      if (response.preferenceId != null || response.preferenceId != undefined) {
        this.notifyService.showNotification("User preferences updated successfully!", 'success');
        this.defaultApplication = this.selectedApplication.application;
        this.defaultMessageType = this.selectedMessageType.messageType;
        this.defaultShipTo = this.shipTo;
      } else {
        this.notifyService.showNotification("Something went wrong !", 'success');
      }
      this.loadingService.closeLoadingIndicator();
    });
  }

  clear() {
    this.applicationName =  this.defaultApplication;
    this.updateSelectedApplication();
    this.messageType = this.defaultMessageType;
    this.updatedSelectedMessageType();
    this.shipTo = this.defaultShipTo;
  }
}
