import { AfterViewInit, Component, OnInit, Output, ViewChild, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AdminPopupComponent } from './admin-popup/admin-popup.component';
import { ApiService } from '../services/api.service';
import { LoaderService } from '../loader/loader.service';
import { userRequestDto } from '../model/userlistDto';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmPopupComponent } from '../configuration-screen/confirm-popup/confirm-popup.component';
import { popup } from '../config/pop-up-content.json';
import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';


export class UserData {
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})

export class AdminComponent implements OnInit {
  columnNames: any;
  public newData: any = [];
  public newData2: any = [];
  isRecordsExists: boolean = false;
  public userRequestDto: userRequestDto;
  dataSource1: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  arrowIcon: any = "assets/icons/left-back.png";
  isPanelExpanded: boolean = false;
  arrowTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  ssoId: string;
  payload = {};
  @Output() closeDivEmission = new EventEmitter();
  @Input() index: any;
  dataSource = new MatTableDataSource();
  loggedInUserId : Number = 0;
  communityName: any;
  role: any;
  loggedInCommID: any;

  constructor(public dialog: MatDialog, private apiService: ApiService, private loadingService: LoaderService, private notifyService: NotificationService) {
  }
  cellClicked(user) {
    const dialogRef = this.dialog.open(AdminPopupComponent,
      {
        data: user,
        width: '100vw',
        maxWidth: '100vw',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(data => {
        this.fetchUsers();
      });
  }
  ngOnInit(): void {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.loggedInCommID = userDetails.communityId;
    this.loggedInUserId = userDetails.userID;
    this.role = userDetails.roleName;
    this.communityName = userDetails.communityName;
    if(this.role === 'COMMUNITY_USER' && this.communityName === environment.communityName){
      this.columnNames = ['firstName', 'lastName', 'email', 'roleName'];
      this.fetchUsers(); 
    } else{
    this.columnNames = ['firstName', 'lastName', 'email', 'roleName', 'Delete'];
    this.fetchUsers();}
  }

  fetchUsers() {
    this.newData = [];
    this.loadingService.openLoadingIndicator();
    this.apiService.fetchUsers(this.loggedInUserId).subscribe((data: any = []) => {
      this.dataSource1 = data.users;
      if (data.users.length > 0) {
        this.isRecordsExists = true;
      } else {
        this.isRecordsExists = false;
      }
      this.loadingService.closeLoadingIndicator();
      this.newData = data.users;
      let i = 0;
      if (this.communityName === environment.communityName && this.role === 'COMMUNITY_ADMIN') {
        this.newData.forEach(user => {
          if ((user.communityId === this.loggedInCommID) && (user.roleName !== "OT_GLOBAL_ADMIN" && user.roleName !== "COMMUNITY_TSD")) {
            this.newData2[i] = user;
            i++;
          }
        });
        this.dataSource = new MatTableDataSource<UserData>(this.newData2);
      }
      else{
        this.dataSource = new MatTableDataSource<UserData>(this.newData);
      }
      // this.dataSource = new MatTableDataSource<UserData>(this.newData);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data: any, column: string): string => {
          if (typeof data[column] === 'string') {
            return data[column].toLocaleLowerCase();
          }
          return data[column];
        };
    }, (error) => {
      if (error) {
        this.isRecordsExists = false;
        this.dataSource = new MatTableDataSource<UserData>([]);
        this.loadingService.closeLoadingIndicator();
      }
    });
  }

  constructUserDto() {
    if (this.firstName != undefined) {
      this.payload['firstName'] = this.firstName;
    }
    if (this.lastName != undefined) {
      this.payload['lastName'] = this.lastName;
    }
    if (this.email != undefined) {
      this.payload['email'] = this.email;
    }
  }

  searchUsers() {
    this.constructUserDto();
    this.newData = [];
    this.newData2= [];
    this.loadingService.openLoadingIndicator();
    this.apiService.searchUsers(this.payload, this.loggedInUserId).subscribe((data: any = []) => {
      this.dataSource = data.users;
      if (this.communityName === environment.communityName && this.role === 'COMMUNITY_ADMIN') {
        if (data.users.length > 0){
          data.users.forEach(searchedUsers => {
            if (((searchedUsers.communityId === this.loggedInCommID) && (searchedUsers.roleName !== "OT_GLOBAL_ADMIN" && searchedUsers.roleName !== "COMMUNITY_TSD"))) {
               this.isRecordsExists = true;
            } else {
              this.isRecordsExists = false;
            }
          });
        }           
        else{
          this.isRecordsExists = false;
        }
      } else {
        if (data.users.length > 0) {
          this.isRecordsExists = true;
        } else {
          this.isRecordsExists = false;
        }
      }
      this.loadingService.closeLoadingIndicator();
      this.newData = data.users;
      let i = 0;
      if (this.communityName === environment.communityName && this.role === 'COMMUNITY_ADMIN') {
        this.newData.forEach(user => {
          if ((user.communityId === this.loggedInCommID) && (user.roleName !== "OT_GLOBAL_ADMIN" && user.roleName !== "COMMUNITY_TSD")) {
            this.newData2[i] = user;
            i++;
          }
        });
        this.dataSource = new MatTableDataSource<UserData>(this.newData2);
      }
      else{
        this.dataSource = new MatTableDataSource<UserData>(this.newData);
      }
      // this.dataSource = new MatTableDataSource<UserData>(this.newData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data: any, column: string): string => {
        if (typeof data[column] === 'string') {
          return data[column].toLocaleLowerCase();
        }
        return data[column];
      };
    }, (error) => {
      if (error) {
        this.isRecordsExists = false;
        this.dataSource = new MatTableDataSource<UserData>([]);
        this.loadingService.closeLoadingIndicator();
      }
    });
  }
  switchIcon = () => {
    this.isPanelExpanded = !this.isPanelExpanded;
    if (this.isPanelExpanded) {
      this.arrowIcon = "assets/icons/right-back.png";
      this.arrowTitle = "Expand";
    } else {
      this.arrowIcon = "assets/icons/left-back.png";
      this.arrowTitle = "Collapse";
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  clear() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.ssoId = '';
  }

  close = () => {
    this.closeDivEmission.emit(false);
    if (
      document.getElementsByClassName('expandible-row-div') !== undefined &&
      document.getElementsByClassName('expandible-row-div')[this.index] !== undefined
    ) {
      document.getElementsByClassName('expandible-row-div')[this.index]['style'].height = '0';
    }
  }
  deleteUser = (event) => {
    const confirmPopupInstance = this.dialog.open(ConfirmPopupComponent,
      {
        data: { msg: popup.deleteUserMessage, action: 'save' },
        disableClose: true,
      });
    confirmPopupInstance.afterClosed().subscribe(data => {
      if (data === 'OK') {
        this.loadingService.openLoadingIndicator();
        this.apiService.deleteUser(event.userId).subscribe(res => {
          this.close();
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.deleteUserSuccess, 'success');
          this.fetchUsers();
        }, (error) => {
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.errorNotification, 'error');
        });
      }
    });
  }
}