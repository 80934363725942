<div class="asn-details-popup-container">
    <div class="header">
        <a class="closePop" (click)="closePopup()"><img src="/assets/img/close.svg"></a>
    </div>
    <div class="body">
        <div class="asn-heading">ASN Details </div>
        <div class="asn-data" >
            <div *ngFor="let val of asnDetailsData" class="dataRow" [ngSwitch]="val.value">
                <span class="headKey" *ngIf="val.key !== undefined">{{val.key}}: </span>
                <span class="dataVal" *ngSwitchCase= "'null'"></span>
                <span class="dataVal" *ngSwitchCase= null></span>
                <span class="dataVal" (click)="openPopUp(val.value, data.messagePayload[0])" [ngStyle]="{'cursor':(val.key === 'Message Id' && val.value !== ' ' && val.value !== null)  ? 'pointer' : 'auto' }" [ngClass]="(val.key === 'Message Id' && val.value !== ' ' && val.value !== null) ? 'underline' : null" *ngSwitchDefault>{{val.value}} 
                </span>
            </div>
        </div>
        <div class="asn-heading">{{data.heading}}<i class="{{data.className}}"
                 [ngClass]="(data.className.indexOf('times') > 0) ? 'red': 'green' "></i></div>
        <div class="asn-data">
            <div *ngFor="let val of nonAsnDetailsData" class="dataRow" [ngSwitch]="val.value">
                <span class="headKey" *ngIf="val.key !== undefined">{{val.key}}: </span>
                <span class="dataVal" *ngSwitchCase= "'null'"></span>
                <span class="dataVal" *ngSwitchCase= null></span>
                <span class="dataVal" (click)="openPopUp(val.value, data.messagePayload[1])" [ngStyle]="{'cursor':(val.key === 'Message Id' && val.value !== ' ' && val.value !== null)  ? 'pointer' : 'auto' }" [ngClass]="(val.key === 'Message Id' && val.value !== ' ' && val.value !== null) ? 'underline' : null" *ngSwitchDefault>{{val.value}} 
                </span>
            </div>
        </div>
    
        <div class="mat-elevation-z8 table-responsive" *ngIf="data.className=='fa fa-check'">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="asn-details">
                <ng-container *ngFor="let columnName of columnNames; let i=index;" [matColumnDef]="columnName">
                    <th mat-header-cell *matHeaderCellDef>{{columnName}}</th>
                    <td mat-cell *matCellDef="let element" class="expandible-row icon">
                    {{element[columnName]}}
                </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element;let i=dataIndex;" [attr.colspan]="columnNames.length">
                        <div class="expandible-row-div" *ngIf="showDiv[i]" [attr.id]="i">                            
                            <table>
                                <tr *ngFor="let note of notes[i]">
                                    <td class="expandible-table-col">Notes:</td>
                                    <td>{{note}}</td>                                    
                                </tr>
                            </table>                            
                        </div>
                    </td>  
                 </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row class="main-rows" *matRowDef="let row; let i = index; columns: columnNames;"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
        </div>
    </div>
</div>