<div class="confirm-popup-container">
    <div class="confirm-popup-body" *ngIf="!data.isAlert">
        <p>{{data.msg}}</p>
        <p>{{data.configName}}</p>
        <form *ngIf="data.action">
            <div>
                <mat-form-field>
                    <input autocomplete="off" matInput type="text" name="first"  [(ngModel)]="confirmText" (keyup)="getConfirmTextValue($event)">
                </mat-form-field>
            </div>
            <div *ngIf="isInvalidInput" class="alert-ok">
                Please type "OK"
            </div>
            
        </form>
        <div class="confirm-btns text-right">
            <button type="button" (click)="ackConfirm()" *ngIf="data.action">Enter</button>
            <button type="button" (click)="ackConfirmexit()" *ngIf="data.exit">Ok</button>
            <button type="button" (click)="closeConfirm()">Cancel</button>
        </div>
    </div>
    <div  class="confirm-popup-body" *ngIf="data.isAlert">
        <p>{{data.message}}</p>
        <div class="confirm-btns text-right">
            <button type="button" (click)="closeConfirm()">OK</button>
        </div>
    </div>
</div>