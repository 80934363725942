<div class="information-container">
  <div>
    <div class="information-header">
      <h4 class="information-title">About EDI Search</h4>
    </div>
    <div class="information-body">
        <ul>
          <li>
            <div class="top-header">
            <div class="logo-wrap">
              <div class="logo">
                <img src="assets/img/logo.svg">
              </div>
              <div class="logo-title">
                <p> &nbsp;| EDI Search</p>
              </div>
            </div>
          </div>
          </li>        
        <div class="tab">
          <li class="tab-copyright">Copyright : &nbsp;&nbsp; © 2022 Opentext. All rights Reserved.</li>
          <li class="tab-build-version">Build Version : &nbsp;&nbsp; v2.0.0</li>
          <li>Build Number : &nbsp;&nbsp; #1   </li>
        </div>
      </ul>

      </div>
      <div class="information-footer">
        <p class="information-title">Patent Information</p>        
      </div>
      <div class="patent">
        <p>One or more patent may cover this product. For more information please visit the <a class="bolded" (click)="redirectTo()">patent</a> site.
        </p>
      </div>
      <div class="information-footer">
        <button (click)="hide()">Close</button>
      </div>
    
  </div>
</div>