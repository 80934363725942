import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dialogData } from '../itemdetails/itemdetails.component';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<InformationComponent>
    ) { }

  ngOnInit(): void {
  }

  hide() {
    this.dialogRef.close();
  }
  redirectTo(){
    window.open("https://www.opentext.com/about/copyright-information/opentext-patent-information");
  }

}
