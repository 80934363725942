import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigPopupComponent } from '../../configuration-screen/config-popup/config-popup.component';
export interface DialogData {
  msg: any;
  action: any;
  exit: any;
  isAlert: any;
  message: any;
}
@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopupComponent implements OnInit {
  confirmText: any;
  isInvalidInput: boolean = false;
  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, public dialogRefp: MatDialogRef<ConfigPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  ngOnInit(): void {
  }

  closeConfirm = () => {
    this.dialogRef.close();
  }
  ackConfirmexit = () => {
    this.dialogRef.close('close');
  }
  ackConfirm = () => {
    if (this.confirmText !== 'OK') {
      this.isInvalidInput = true;
    } else {
      this.isInvalidInput = false;
      this.dialogRef.close('OK');
    }
  }
  getConfirmTextValue = (event) => {
    this.confirmText = event.target.value;
    if(this.isInvalidInput && this.confirmText === 'OK') {
      this.isInvalidInput = false;
    }
  }

}

