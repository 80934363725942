<div class="left-search-right-result text-center">
    <div class="container">
        <mat-drawer-container>
            <mat-drawer mode="push" #drawer opened="true">
                <div class="left-search-fields">
                    <div class="search-form">
                        <form name="myForm" method="" class="form-input">
                            <div class="search-btns">
                                <button (click)="fetchRecords()">Search</button>
                                <button (click)="clear()">Clear</button>
                            </div>
                            <div class="flex-d">
                            <div class="labels-input">
                                <div class="label-name">
                                    <label>Application Name<span class="red-star">*</span><img
                                            src="assets/icons/abt-b.svg"
                                            matTooltip="Select the GM application (i.e. MGO NA) from the drop down list."
                                            matTooltipPosition="right" matTooltipClass="custom-tooltip"></label>
                                </div>
                                <mat-form-field>
                                    <select matNativeControl #applicationNameId [(ngModel)]="applicationName" (ngModelChange)='onApplicationChanged()' name="meta.app" id="meta.app">
                                    	<option disabled="disabled" value="default" selected>Select Application</option>
                                        <option *ngFor="let app of grantedApplications" [ngValue]="app.application">
                                            {{app.application}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <div class="labels-input">
                                <div class="label-name">
                                    <label>Message Type <span class="red-star">*</span><img src="assets/icons/abt-b.svg"
                                            matTooltip="Select the message from the drop down list."
                                            matTooltipPosition="right" matTooltipClass="custom-tooltip"></label>
                                </div>
                                <mat-form-field>
                                    <select matNativeControl #messageTypeId [(ngModel)]="messageType" (ngModelChange)='onMessageTypeChanged()' name="meta.msgtyp" id="meta.msgtyp">
                                        <option disabled="disabled" value="default" selected>Select Message Type</option>
                                        <option *ngFor="let msg of grantedMessages | orderBy :'messageType' " [ngValue]="msg.messageType">
                                            {{msg.messageType}}
                                        </option>
                                    </select>
                                </mat-form-field>
                            </div>


                            <!-- <div> -->
                                <div class="labels-input" *ngFor="let field of searchFields">
                                    <div class="label-name"
                                        *ngIf="field.dataReference !== 'meta.app' && field.dataReference !== 'meta.msgtyp'">
                                        <label>{{field.displayName}} <span class="red-star"
                                                *ngIf="requiredFields.indexOf(field.dataReference) > -1">*</span><img
                                                src="assets/icons/abt-b.svg" matTooltip="{{field.helpText}}"
                                                matTooltipPosition="right" matTooltipClass="custom-tooltip"></label>
                                    </div>
                                    <mat-form-field *ngIf="requiredFields.indexOf(field.dataReference) === -1">
                                        <input autocomplete="off" matInput type="text" #ref
                                            [(ngModel)]="searchModalValues[field.dataReference]"
                                            (keyup)="getConfirmTextValue()" autocomplete="off"
                                            name="{{field.dataReference}}" id="{{field.dataReference}}" [maxLength]="maxLengthValues[field.dataReference]"/>
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="field.dataReference === 'meta.sentDte' && field.displayName === 'Start Date/Time'">
                                        <input autocomplete="off" matInput #startDateId name="startDate" id="meta.sentDte"
                                            [(ngModel)]="fromDate" [disabled]="flag" mode="daytime" [dpDayPicker]="config" [min]="minDate" readonly="readonly"
                                            [max]="maxDate" theme="dp-material" /><i class="cal-icon fa fa-calendar"></i>
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="field.dataReference === 'meta.sentDte' && field.displayName === 'End Date/Time'">
                                        <input autocomplete="off" matInput #endDateId name="endDate" id="meta.sentDte"
                                            [(ngModel)]="endDates" [disabled]="flag" mode="daytime" [dpDayPicker]="config" readonly="readonly"
                                            theme="dp-material" /><i class="cal-icon fa fa-calendar"></i>
                                    </mat-form-field>
                                </div>
                            <!-- </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <button class="arrow" (click)="drawer.toggle();switchIcon()" title="Collapse">
                    <div class="expand-arrow">
                        <img src="{{arrowIcon}}"  [attr.title]=arrowTitle>
                    </div>
                </button>
                <div class="tab-cont" [ngClass]="{'fullWidth': isPanelExpanded}">
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="manfac" #TableSort="matSort">
                            <ng-container [matColumnDef]="col.displayName" *ngFor="let col of columnArray; index as i">
                                <th class="header-column-display-name" [ngStyle]="{'width':i === columnArray.length - 1?searchResultcolumnWidth[col.displayName] + 20 + 'px' : searchResultcolumnWidth[col.displayName] + 'px', 'padding': '0px 10px'}"
                                 mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled(col.sortable)"> {{col.displayName}} </th>
                                <td [ngStyle]="{'width':i === columnArray.length - 1?searchResultcolumnWidth[col.displayName] + 20 + 'px' : searchResultcolumnWidth[col.displayName] + 'px', 'padding': '0px 10px', 'text-align': 'left'}" 
                                mat-cell *matCellDef="let element" [ngSwitch]="element[col.displayName]"> <!--<span>{{element[col.displayName]}}</span>-->
                                        <span matTooltip="{{element[col.displayName]}}"
                                        matTooltipPosition="above" matTooltipClass="custom-tooltip" *ngSwitchCase= "'null'" > </span>
                                        <span matTooltip="{{element[col.displayName]}}"
                                        matTooltipPosition="above" matTooltipClass="custom-tooltip" *ngSwitchCase= null > </span>
                                        <span *ngSwitchDefault [ngStyle]="{'cursor':(col.link != null  && col.displayName != 'null' ) ? 'pointer' : 'auto' }">
                                            <span matTooltip="{{element[col.displayName]}}" *ngIf="col.link === null"  matTooltipPosition="above" matTooltipClass="custom-tooltip"  >{{element[col.displayName]}}</span>
                                            <span matTooltip="{{element[col.displayName]}}" (click)="getData(element, col.dataReference, col.displayName, col.link)" [ngClass]="(col.link != null  && col.displayName != 'null' ) ? 'underline' : null" *ngIf="col.link != null && col.displayName === 'Message Id' && element['CHILD_MESSAGE_ID'] === undefined"  matTooltipPosition="above" matTooltipClass="custom-tooltip" >{{element[col.displayName]}}</span>
                                            <span matTooltip="{{element[col.displayName]}}" (click)="getData(element, col.dataReference, col.displayName, col.link)" [ngClass]="(col.link != null  && col.displayName != 'null' ) ? 'underline' : null" *ngIf="col.link != null && col.displayName === 'Message Id' && element['CHILD_MESSAGE_ID'] !== undefined"  matTooltipPosition="above" matTooltipClass="custom-tooltip" >{{element['CHILD_MESSAGE_ID']}}</span>
                                            <span matTooltip="{{element[col.displayName]}}" (click)="(col.link != null  && col.displayName != 'null' ) ? getData(element, col.dataReference, col.displayName, col.link) : null" [ngClass]="(col.link != null  && col.displayName != 'null' ) ? 'underline' : null" *ngIf="col.link != null && col.displayName !== 'Message Id'"  matTooltipPosition="above" matTooltipClass="custom-tooltip" >{{element[col.displayName]}}</span>
                                        </span>
                                    <img src="/assets/img/magnifier-lens.png" alt="messageState"
                                        *ngIf="showIcon(element[col.displayName], col.displayName)" class="icon"
                                        (click)="openAsnPopup(element[col.displayName], element['SegmentTerminator'], element['MRSShipmentKey'], element['Control Number'])" />
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <table class="clearT">
                            <tr *ngIf=isRecordsExists>
                                <td [attr.colspan]="displayedColumns.length">
                                    No Results Were Found
                                </td>
                            </tr>
                        </table>
                    </div>
                    <mat-paginator appPagination [length]="50" [pageSizeOptions]="[40]" [hidePageSize]='true'
                        [hidden]=isRecordsExists [pageSize]="40" (page)="onPaginateChange($event)">
                    </mat-paginator>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>