import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule, Routes } from "@angular/router";
import { SeachnavModule } from "../seachnav/Searchnav.module";
import { ManufacturerComponent } from "./manufacturer.component";
import { PaginatorModule } from "./pagination.module";
import { DpDatePickerModule } from "ng2-date-picker";
import { OrderByPipeModule } from "../pipes/application-pipes.module";


const routes: Routes = [
    { path: '', component: ManufacturerComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatPaginatorModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        SeachnavModule,
        FormsModule,
        MatSortModule,
        MatTooltipModule,
        PaginatorModule,
        DpDatePickerModule,
        OrderByPipeModule
    ],
    declarations: [ManufacturerComponent],
    exports: [ManufacturerComponent]
})

export class ManufacturerModule {
    static rootComponent = ManufacturerComponent;
}
