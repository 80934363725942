<div class="item-details-container">
    <div class="item-details-header">
        <h4 class="item-details-title">{{data.partTitle}}</h4>
    </div>
    <div class="item-details-body">
        <div class="mat-elevation-z8 table-responsive">
            <table mat-table [dataSource]="dataSource" class="item-details">
                <ng-container *ngFor="let columnName of columnNames" [matColumnDef]="columnName">
                    <th mat-header-cell *matHeaderCellDef>{{columnName}}</th>
                    <td mat-cell *matCellDef="let row">{{row[columnName]}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            </table>
        </div>
    </div>
    <div class="item-details-footer">
        <button (click)="hide()">Close</button>
    </div>
</div>