import { Injectable, OnInit, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  fetchRecords(searchRequestDto: any) {
    return this.http.post(environment.searchURL + "/search/v1", searchRequestDto);
  }

  getConfiguration(messageType: string, applicationName: string) {
    const params = new HttpParams().set('messageType', messageType).set('application', applicationName);
    return this.http.get(environment.configURL + "/configuration/v1", { params });
  }

  getDataValue(dataValue: string, dataReference: string, metaId: any, itemDetailDTO: any, sourceTable: string) {
    const params = new HttpParams().set('sourceTable', sourceTable).set('metaId', metaId).set('dataReference', dataReference).set('dataValue',dataValue);
    return this.http.post(environment.searchURL + "/itemdetail/v1",itemDetailDTO, { params } );
  }

  getMessageIdData(messageId: any) {
    const params = new HttpParams().set('messageId', messageId);
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/v1", { params });
  }

  getFunctionalAcknowledgement(mrsShipKey) {
    const params = new HttpParams().set('shipmentKey', mrsShipKey);
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/functionalAck/v1", { params });
  }

  getApplicationAdvice(mrsShipKey) {
    const params = new HttpParams().set('shipmentKey', mrsShipKey);
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/applicationAdvice/v1", { params });
  }

  getApplicationAdviceTableData(mrsShipKey) {
    const params = new HttpParams().set('shipmentKey', mrsShipKey);
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/applicationAdviceDetailTable/v1", { params });
  }

  getMessageTypeAndApplicationName(userId) {
    return this.http.get(environment.userURL + `/userapplications/v1/${userId}`);
  }

  getMessagePurposeCode(messagePurposeCode: any) {
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/applicationAdvice/lookup/v1?label=TL_TRACK_X12_MSG_PURP_" + messagePurposeCode);
  }

  getAppAdviceErrorDescription(errorCode: any) {
    return this.http.get(environment.searchURL + "/search/v1/messagedetail/applicationAdvice/lookup/v1?label=MGOASN_" + errorCode + "&pageLabelId=1");
  }

  getMessageState = (messageDetailsRequestDto: any) => {
    return this.http.post(environment.searchURL + '/search/v1/messagedetail/statuses/v1', messageDetailsRequestDto);
  }

  getMessageState_v2 = (messagesDTO: any, isASNDashBoardEnabled: any) => {
    const param = new HttpParams().set('isASNDashBoardEnabled', isASNDashBoardEnabled);
    return this.http.post(environment.searchURL + '/search/v1/messagedetail/statuses/v2?' + param ,  messagesDTO);
  }

  getConfigurationList = () => {
    return this.http.get(environment.configURL + '/configurations/v1').pipe(map((response) => response));
  }

  getDataReferenceList = () => {
    return this.http.get(environment.configURL + '/configuration/reference/v1').pipe(map((response) => response));
  }

  saveNewConfiguration = (payload) => {
    return this.http.post(environment.configURL + '/configuration/v1', payload).pipe(map((response) => response));
  }

  getCommunity = () => {
    return this.http.get(environment.configURL + "/v1/configurations/community");
  }

  getApplication = (comId) => {
    return this.http.get(environment.configURL + "/v1/configurations/application/" + comId);
  }

  getMessage = () => {
    return this.http.get(environment.configURL + "/v1/configurations/messagetype");
  }

  getConfigurationStatus = (configName) => {
    return this.http.get(environment.configURL + "/configuration/validate/v1/" + configName);
  }

  getEditSaveConfiguration = (payload) => {
    return this.http.put(environment.configURL + '/configuration/v1', payload).pipe(map((response) => response));
  }

  getAuditNotes = (configId) => {
    return this.http.get(environment.configURL + "/configuration/v1/" + configId);
  }

  deleteConfig = (configId, deleteVal,httpOptions) => {
    return this.http.delete(environment.configURL + `/configuration/delete/v1/${configId}` + '?&isSoftDelete=' + deleteVal,httpOptions).pipe(map((response) => response));
  }

  importConfiguration = (fileData, configurationName, applicationName, messageType) => {
    const params = new HttpParams().set('configurationName', configurationName).set('applicationName', applicationName).set('messageType', messageType)
    return this.http.post(environment.configURL +'/configuration/v1/upload',fileData, {params}).pipe(map((response) => response));
  }
  saveUserPreferences = (userPrefereneDto, userId) => {
    return this.http.post(environment.userURL +`/preference/v1/${userId}`,userPrefereneDto).pipe(map((response) => response));
  }

  fetchUsers(userId) {
    return this.http.get(`${environment.userURL}/user?loggedInUserId=${userId}`);
  }

  searchUsers = (userDto, loggedInUserId) => {
    return this.http.put(`${environment.userURL}/user/search?loggedInUserId=${loggedInUserId}`,userDto).pipe(map((response) => response));
  }

  fetchAllExceptions = () => {
    return this.http.get(`${environment.searchURL}/audit/exception/v1/exceptions`);
  }

  fetchExceptionDetails = (exceptionId) => {
    return this.http.get(`${environment.searchURL}/audit/exception/v1/exceptions/${exceptionId}`);
  }

  searchExceptions = (exceptionDto) => {
    return this.http.post(environment.searchURL +`/audit/exception/v1/exceptions/search`,exceptionDto).pipe(map((response) => response));
  }

  deleteUser = (userId) => {
    return this.http.delete(environment.userURL + `/user/${userId}`).pipe(map((response) => response));
  }

  fetchUserRoles = (communityId,loggedInUserId) => {
    return this.http.get(`${environment.userURL}/roles/v1/${loggedInUserId}?communityId=${communityId}`);
  }
  fetchUserApplications= (userId,loggedInUserId,communityId) => {
    return this.http.get(`${environment.userURL}/applications/user/${loggedInUserId}?communityId=${communityId}&userId=${userId}`);
  }

  updateRolesAndAssignApplications= (loggedInUserID, param) => {
    return this.http.post(`${environment.userURL}/userapplications/v1?loggedInUserId=${loggedInUserID}`, param).pipe(map((response) => response));
  }

  updateUserRole = (payload, userId) => {
    const param = new HttpParams().set('userId', userId);
    return this.http.put(`${environment.userURL}/user?${param}`, payload).pipe(map((response) => response));
  }

  downloadMessageDefinition = (configurationId) => {
    return this.http.get(`${environment.configURL}/configuration/v1/download/${configurationId}`, {responseType: 'blob'});
  }

  validateIDToken = (payload) => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true, 
      observe: 'response' as 'response'
    };  
    return this.http.post(`${environment.securityURL}/oauth/token`, payload, httpOptions).pipe(map((response) => response));
  }
}
