import { createReducer, on } from "@ngrx/store";
import { resetEdiSearchDetails, saveEdiSearchDetails } from "../actions/edi-search.action";
import { ediSearchInitialState } from '../initial-state/edi-search.state';

const ediSearchReducer = createReducer(
    ediSearchInitialState,
    on(saveEdiSearchDetails, (state, { payload }) => ({ ...state, ...payload })),
    on(resetEdiSearchDetails, (state) => ({}))
);

// tslint:disable-next-line: typedef
export function ediSearchDetailsReducer(state = ediSearchInitialState, action) {
    return ediSearchReducer(state, action);
}
