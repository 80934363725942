import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoaderComponent } from "./loader.component";

@Injectable()

  export class LoaderService{
      constructor(public dialog:MatDialog){
        
      }
      dialogref:any;
      openLoadingIndicator(){
          this.dialogref=this.dialog.open(LoaderComponent,{
              height:"auto",
              width:"50em",
              data:{},
              disableClose:true,
          })
      }
      closeLoadingIndicator(){
        if(this.dialogref){
            this.dialogref.close();
        }
      }
  }