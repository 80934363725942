import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';

const Materialcomponents = [
  MatSidenavModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule
];


@NgModule({
  imports: [ Materialcomponents ],
  exports: [ Materialcomponents ]
})
export class SeachnavModule { 
 
}
