import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotifierComponent } from '../notifier/notifier.component';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  defaultDuration  = 10000;

  constructor(private snackBar: MatSnackBar) { }
  showNotification(displayMessage: string, messageType: 'info' | 'success' | 'error', duration?: number) {
    if (typeof duration !== 'undefined'){
      this.defaultDuration = duration;
    }
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: displayMessage,
        type: messageType,
      },
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.defaultDuration,
      panelClass: messageType
    });
  }
  showConfigNotification(displayMessage: string, configNme: string, messageType: 'info' | 'success' | 'error'){
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: displayMessage,
        config:configNme,
        type: messageType,
      },
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.defaultDuration,
      panelClass: messageType
    });
  }
}
