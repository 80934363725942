import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

export interface modalData {
    payload: any;
    terminator: any;
    responseData: any;
    messageType: any;
    controlNumber: any;
}

@Component({
    selector: 'app-message-id-popup',
    templateUrl: './message-id-popup.component.html',
    styleUrls: ['./message-id-popup.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class MessageIdPopupComponent implements OnInit {
    fileData: any;
    processedData = [];
    decryptedRawData = [];
    fileName: any;
    docTypeValue: any;
    docFormatValue: any;
    dataHeaders = [];
    showPayload: boolean;
    showError: boolean;
    errorMsg: any = '';
    buttonText: any;
    payloadType: any;
    payloadObject = {
        common: 'InBytesCommonPayload',
        receiver: 'InBytesReceiverPayload',
        sender: 'InBytesSenderPayload'
    };
    failureDetails: any = [];
    exceptionDetails: any = [];
    senderInform: any = []
    errorExist: boolean;

    constructor(private dialogRef: MatDialogRef<MessageIdPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: modalData,
        private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
        this.fileName = this.data.responseData['MessageID'];
        const headingData = {
            MessageID: "Message Id",
            ControlNumber: "Control Number",
            SenderName: "Sender Name",
            SenderCode: "Sender Code",
            ReceiverName: "Receiver Name",
            ReceiverCode: "Receiver Code",
            CommonDocType: '',
            CommonFormat: '',
            CommonStandardVersion: "Document Format/ Type/ Version"
        };
        this.payloadType = this.payloadObject[this.data.payload];
        if (
            this.data !== undefined &&
            this.data.responseData !== undefined &&
            this.data.responseData['ExceptionInstanceEntity']
        ) {
            this.showError = true;
            this.showPayload = false;
            if (this.data.responseData.ErrorDetails == null) {
                this.errorMsg = this.data.responseData['ExceptionInstanceEntity'].CustomerMessage + '\n' + this.data.responseData['ExceptionInstanceEntity'].Message;
                this.errorExist = true;
            }
            else if(this.data.responseData['ExceptionInstanceEntity'].ExtendedData !== null) {
                this.errorExist = false;
                this.senderInform.push(
                    { key: 'SENDER INFORMATION' },
                    { key: 'SENDER INTERCHANGE ID', value: this.data.responseData.ErrorDetails.senderInformation.senderId !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.senderId : '' },
                    { key: 'CONTROL NUMBER', value: this.data.responseData.ErrorDetails.senderInformation.senderControlNumber !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.senderControlNumber : '' },
                    { key: 'SENDER APPLICATION DOC ID', value: this.data.responseData.ErrorDetails.senderInformation.senderApplDocId !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.senderApplDocId : '' },
                    { key: 'SHIP FROM ID', value: this.data.responseData.ErrorDetails.senderInformation.shipFromId !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.shipFromId : '' },
                    { key: 'SHIP TO ID', value: this.data.responseData.ErrorDetails.senderInformation.shipToId !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.shipToId : '' },
                    { key: 'SUPPLIER ID', value: this.data.responseData.ErrorDetails.senderInformation.supplierId !== 'null' ? this.data.responseData.ErrorDetails.senderInformation.supplierId : '' },
                    { key: 'RECEIVER INFORMATION' },
                    { key: 'RECEIVER INTERCHANGE ID', value: this.data.responseData.ErrorDetails.receiverInformation.receiverId !== 'null' ? this.data.responseData.ErrorDetails.receiverInformation.receiverId : '' },
                    { key: 'MESSAGE DETAILS' },
                    { key: 'MESSAGE TYPE', value: this.data.responseData.ErrorDetails.messageInformation.messageType !== 'null' ? this.data.responseData.ErrorDetails.messageInformation.messageType : '' },
                    { key: 'RECEIVED TIME', value: this.data.responseData.ErrorDetails.messageInformation.dateTimeStamp !== 'null'? this.data.responseData.ErrorDetails.messageInformation.dateTimeStamp : '' }
                );
                for (let i = 0; i < this.data.responseData.ErrorDetails.errorDetails.length; i++) {
                    this.exceptionDetails.push(

                        { key: 'EXCEPTION DETAILS' },
                        { key: 'ERROR CODE', value: this.data.responseData.ErrorDetails.errorDetails[i].errorCode !== 'null' ? this.data.responseData.ErrorDetails.errorDetails[i].errorCode : '' },
                        { key: 'TYPE OF ERROR', value: this.data.responseData.ErrorDetails.errorDetails[i].typeOfError !== 'null'? this.data.responseData.ErrorDetails.errorDetails[i].typeOfError : '' },
                        { key: 'LOCATION OF BAD DATA', value: this.data.responseData.ErrorDetails.errorDetails[i].locationOfBadData !== 'null'? this.data.responseData.ErrorDetails.errorDetails[i].locationOfBadData : '' },
                        { key: 'LINE NUMBER', value: this.data.responseData.ErrorDetails.errorDetails[i].lineNumber !== 'null' ? this.data.responseData.ErrorDetails.errorDetails[i].lineNumber : '' },
						{ key: 'BAD DATA', value: this.data.responseData.ErrorDetails.errorDetails[i].badData !== 'null'? this.data.responseData.ErrorDetails.errorDetails[i].badData : '' },
                        { key: 'PREVIOUS VALID SEGMENT', value: this.data.responseData.ErrorDetails.errorDetails[i].previousValidSegment !== 'null' ? this.data.responseData.ErrorDetails.errorDetails[i].previousValidSegment : '' },
                        { key: 'STATUS', value: this.data.responseData.ErrorDetails.errorDetails[i].status !== 'null' ? this.data.responseData.ErrorDetails.errorDetails[i].status : '' }

                    );
                }
            }
            else
            {
                for (let i = 0; i < this.data.responseData.ErrorDetails.errorDetails.length; i++) {
                    this.exceptionDetails.push(

                        { key: 'EXCEPTION DETAILS' },
                        { key: 'ERROR CODE', value: this.data.responseData.ErrorDetails.errorDetails[i].errorCode !== 'null' ? this.data.responseData.ErrorDetails.errorDetails[i].errorCode : '' },
                        { key: 'TYPE OF ERROR', value: this.data.responseData.ErrorDetails.errorDetails[i].typeOfError !== 'null'? this.data.responseData.ErrorDetails.errorDetails[i].typeOfError : '' },
                        { key: 'ERROR MESSAGE', value: this.data.responseData.ErrorDetails.errorDetails[i].actionMessage !== 'null'? this.data.responseData.ErrorDetails.errorDetails[i].actionMessage : ''}
                    );
                }

            }
            if(this.data.responseData['ExceptionInstanceEntity'].ExtendedData !== null)
            {
            this.failureDetails = this.senderInform.concat(this.exceptionDetails);
            this.buttonText = 'Close';
            }
            else{
                this.failureDetails = this.exceptionDetails;
                this.buttonText = 'Close'; 
            }
        } else if (
            this.data !== undefined &&
            this.data.responseData[this.payloadType] !== undefined
        ) {
            headingData['ReprocessedAt'] = 'Reprocessed Date/Time';
            headingData['ReprocessedBy'] = 'Reprocessed By';
            this.showPayload = true;
            this.showError = false;
            if(this.data.responseData[this.payloadType] !== this.data.responseData['InBytesCommonPayload'])
            {
                this.payloadType = 'InBytesCommonPayload';
            }
            let l = 4 - (this.data.responseData[this.payloadType].length % 4);
            while (l < 4 && l-- > 0) {
                this.data.responseData[this.payloadType] += '=';
            }
            this.data.responseData[this.payloadType] = this.data.responseData[this.payloadType].replace(/-/g, '+').replace(/_/g, '/');
            if (this.data !== undefined && this.data.terminator !== undefined && this.data.terminator !== '/r' && this.data.terminator !== '/n') {
                this.decryptedRawData = atob(this.data.responseData[this.payloadType]).split(this.data.terminator);
                this.buttonText = 'DOWNLOAD DATA';
            } else {
                this.decryptedRawData.push(atob(this.data.responseData[this.payloadType]));
                this.buttonText = 'DOWNLOAD DATA';
            }
        }
        Object.entries(headingData).forEach(([headKey, headVal]) => {
            Object.entries(this.data.responseData).forEach(([datakey, dataVal]) => {
                if (this.data.responseData[headKey] !== undefined) {
                    if (headKey === datakey && (headKey !== 'CommonDocType' && headKey !== 'CommonFormat' && headKey !== 'CommonStandardVersion')) {
                        if (headKey === 'SenderCode') {
                            const data = {};
                            data['key'] = headVal;
                            if (this.data.responseData['SenderQualifier'] !== "null" && this.data.responseData['SenderQualifier'] !== null) {
                                data['value'] = this.data.responseData['SenderQualifier'] + ' ' + dataVal;
                            }
                            else {
                                this.data.responseData['SenderQualifier'] = '';
                                data['value'] = this.data.responseData['SenderQualifier'] + ' ' + dataVal;
                            }
                            this.processedData.push(data);
                            this.dataHeaders.push(headKey);
                        } else if (headKey === 'ReceiverCode') {
                            const data = {};
                            data['key'] = headVal;
                            if (this.data.responseData['ReceiverQualifier'] !== 'null' && this.data.responseData['ReceiverQualifier'] !== null) {
                                data['value'] = this.data.responseData['ReceiverQualifier'] + ' ' + dataVal;
                            }
                            else {
                                this.data.responseData['ReceiverQualifier'] = '';
                                data['value'] = this.data.responseData['ReceiverQualifier'] + ' ' + dataVal;
                            }
                            this.processedData.push(data);
                            this.dataHeaders.push(headKey);
                        } else {
                            const data = {};
                            data['key'] = headVal;
                            if(headKey === "ControlNumber")
                            {
                                data['value'] = this.data.responseData['ControlNumber'] !== null ? this.data.responseData['ControlNumber'] : '' ;
                                //Commented out the overide of Control Number, might have to revert in future if business desires
                                //data['value'] = this.data.controlNumber !== null ? this.data.controlNumber : ' ' ;
                            }
                            else
                            {
                            data['value'] = dataVal !== null ? dataVal : ' ';
                            }
                            this.processedData.push(data);
                            this.dataHeaders.push(headKey);
                        }
                    } else if (headKey === datakey && (headKey === 'CommonStandardVersion')) {
                        const data = {};
                        data['key'] = headVal;
                        data['value'] = this.docFormatValue + ' / ' + this.docTypeValue + ' / ' + dataVal;
                        data['style'] = "fullWidth"
                        this.processedData.push(data);
                        this.dataHeaders.push(headKey);
                    } else {
                        if (headKey === datakey) {
                            if (headKey === 'CommonDocType') {
                                this.docTypeValue = dataVal;
                                this.dataHeaders.push(headKey);
                            }
                            if (headKey === 'CommonFormat') {
                                this.docFormatValue = dataVal;
                                this.dataHeaders.push(headKey);
                            }
                        }
                    }
                } else {
                    if (this.dataHeaders.indexOf(headKey) === -1) {
                        const data = {};
                        data['key'] = headVal;
                        data['value'] = '';
                        this.processedData.push(data);
                        this.dataHeaders.push(headKey);
                    }
                }
            });
        });
    }

    /**
     * Method is used to close modal
     */
    closeModal = () => {
        this.dialogRef.close();
    }

    /**
     * Method is used to decode the payload and download payload using blob and domsanitizer
     */
    downloadData = () => {
        if (this.data !== undefined && this.data.responseData[this.payloadType] !== undefined) {
            const decryptedData = atob(this.data.responseData[this.payloadType]);
            if (decryptedData !== undefined) {
                const blob = new Blob([decryptedData]);
                this.fileData = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
            }
        }
    }
}
