import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { PaginatorModule } from '../manufacturer/pagination.module';
import { ConfigFieldsComponent } from './config-fields/config-fields.component';
import { ConfigPopupComponent } from './config-popup/config-popup.component';
import { ConfigurationScreenComponent } from './configuration-screen.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
const routes: Routes = [
    { path: '', component: ConfigurationScreenComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatTabsModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatPaginatorModule,
        PaginatorModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatSortModule
    ],
    declarations: [
        ConfigurationScreenComponent,
        ConfigFieldsComponent,
        ConfigPopupComponent
    ],
    exports: [ConfigurationScreenComponent]
})

export class ConfigurationScreenModule {
    static rootComponent = ConfigurationScreenComponent;
}
