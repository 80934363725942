import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '../loader/loader.service';
import { ApiService } from '../services/api.service';
import { NotificationService } from '../services/notification.service';
import { ConfigPopupComponent } from './config-popup/config-popup.component';
import { popup } from '../config/pop-up-content.json';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-configuration-screen',
  templateUrl: './configuration-screen.component.html',
  styleUrls: ['./configuration-screen.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationScreenComponent implements OnInit, AfterViewInit {

  tableIndex: any = 0;
  columnNames: any;
  dataSource: any;
  expandedDetail: any;
  clickedEle: any;
  dialogRef: any;
  rowNum: any;
  searchValue: any;
  pageSize = 40;
  tableData: any;
  configurationData = [];
  showDiv = [];
  configResponse: any;
  isConfigurationAvailable: boolean;
  isFetching: boolean
  fileUploaded: any;
  importIndex: any;
  selectedConfigIdToImport: any;
  searchFieldsDataRefList: any;
  resultFieldsDataRefList: any;
  detailFieldsDataRefList: any;
  isEdit: boolean = false;
  isRowExpanded: boolean = false;  
  pageIndex: Number = 1;
  elementIdInConfigRes: any;
  renderedData: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileUpload') fileUpload;
  allOpenConfigIndex =[];
  expandedIndex = 0;
  previousPageIndex: any;
  tempPage: Number;

  constructor(
    private dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private apiService: ApiService,
    private loadingIndicator: LoaderService,
    private notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {
	this.pageIndex = 0;
  this.tempPage = this.pageIndex;
    this.fetchDataReferenceList();
    this.loadingIndicator.openLoadingIndicator();
    this.isFetching = true;    
    this.apiService.getConfigurationList().subscribe((response: any) => {
      if (response !== undefined && response['messageDefinitionDTOs'] !== undefined) {
        if (response['messageDefinitionDTOs'].length > 0) {
          this.isConfigurationAvailable = true;
          this.configResponse = response['messageDefinitionDTOs'];
          this.buildConfigData(response['messageDefinitionDTOs']);
        } else {
          this.isConfigurationAvailable = false;
        }
      } else {
        this.isConfigurationAvailable = false;
      }
      this.isFetching = false;
      this.loadingIndicator.closeLoadingIndicator();
    });
    this.columnNames = ['Configuration Name', 'Last Updated By', 'Last Updated Date', 'Status', 'Import', 'Export', 'View', 'Edit'];
  }

  onPaginateChange = (event) => {
    this.pageIndex = event.pageIndex;
      if(this.tempPage !== this.pageIndex){        
       this.showDiv[this.rowNum] = false;
      }else {                
        this.showDiv[this.rowNum] = true;
     } 
}

  fetchDataReferenceList = async () => {
    if (sessionStorage.getItem('DataReferenceList') === undefined || sessionStorage.getItem('DataReferenceList') === null) {
      const response = await this.apiService.getDataReferenceList().toPromise();
      sessionStorage.setItem('DataReferenceList', JSON.stringify(response));
    }
  }

  processDataRefResponse = (response) => {
    if (response !== undefined && response !== null && response !== '') {
      sessionStorage.setItem('DataReferenceList', JSON.stringify(response));
      this.searchFieldsDataRefList = response?.dataReference?.search ? (response.dataReference.search.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
      this.resultFieldsDataRefList = response?.dataReference?.result ? (response.dataReference.result.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
      this.detailFieldsDataRefList = response?.dataReference?.dataView ? (response.dataReference.dataView.sort((a, b) => (a.jsonTag > b.jsonTag) ? 1 : -1)) : '';
    }
  }

  ngAfterViewInit(): void {
    this._cd.detectChanges();
  }


  buildConfigData = (configData) => {
    configData.forEach(val => {
      const obj = {};
      obj['Configuration Name'] = val?.configuration?.configNme;
      obj['Last Updated By'] = (val?.updatedBy !== undefined && val?.updatedBy !== null) ? val.updatedBy : val?.createdBy;
      obj['Last Updated Date'] = (val?.updatedDate !== undefined && val?.updatedDate !== null) ? val.updatedDate : val.createdDate;
      obj['Configuration Id'] = (val?.configId !== undefined && val?.configId !== null) ? val.configId : val.configId;
      obj['Status'] = (val?.status !== undefined && val?.status !== null) ? val.status : val.Status;
      this.configurationData.push(obj);
    });
    this.dataSource = new MatTableDataSource(this.configurationData);
    this.dataSource.sort = this.sort;
    this.tableData = this.configurationData;
    if (this.dataSource.data.length > 0) {
      this.isConfigurationAvailable = true;
    } else {
      this.isConfigurationAvailable = false;
    }
    this.paginator.pageSize = 40;
    this.dataSource.paginator = this.paginator;
  }

  getElement = (data, filteredIndex, configId?: any) => {    
    this.tempPage = this.pageIndex;
	  this.elementIdInConfigRes = configId;
    this.loadingIndicator.openLoadingIndicator();
    this.clickedEle = data;
    this.dataSource.connect().subscribe(d => this.renderedData = d);
    this.tableIndex = this.fetchDataIndex();
    this.renderedData.forEach((element, index) => {
      if (configId === element['Configuration Id']) {
        filteredIndex = index;
      }
    });
    if (data === 'Import') {
      this.fileUpload.nativeElement.value = '';
      document.getElementById('file-upload').click();
      this.importIndex = filteredIndex;
    } else {      
      this.isEdit = !this.isEdit;
      this.expandRow(filteredIndex);
      if(!this.allOpenConfigIndex.some(presentIndex => presentIndex === filteredIndex)){
        this.allOpenConfigIndex.push(filteredIndex);
      }
      if(this.allOpenConfigIndex.length > 1){  
          this.getCloseEvent('F',this.allOpenConfigIndex[this.expandedIndex]); 
          this.allOpenConfigIndex.splice(this.expandedIndex, 1);   
        }
      this.isRowExpanded = true;   
      }
    this.loadingIndicator.closeLoadingIndicator();
  }

  getImportElement = (data, filteredIndex, configurationId) => {
    this.selectedConfigIdToImport = configurationId;
    this.getElement(data, filteredIndex, configurationId);
  }

  expandRow = (filteredIndex) => {
    const rowIndex = filteredIndex % 40;
    this.rowNum = rowIndex;
    this.showDiv[rowIndex] = true;
    setTimeout(() => {
      if (
        document.getElementsByClassName('expandible-row-div') !== undefined &&
        document.getElementsByClassName('expandible-row-div')[this.rowNum] !== undefined
      ) {
        document.getElementsByClassName('expandible-row-div')[this.rowNum]['style'].height = 'auto';
      }
    }, 1000);
  }


  getCloseEvent = (eventType, index) => {    
    if(eventType !== 'F'){
      this.allOpenConfigIndex.splice(0, this.allOpenConfigIndex.length);
    }     
    this.showDiv[index] = false;
    this.isRowExpanded = false;
  }

  openPopup = () => {
    this.dialogRef = this.dialog.open(ConfigPopupComponent,
      {
        data: {},
        width: '110em',
        maxWidth: '100vw',
        disableClose: true
      });
    this.dialogRef.afterClosed().subscribe(data => {
      if (data === true) {
        this.apiService.getConfigurationList().subscribe((response: any) => {
          if (response !== undefined && response['messageDefinitionDTOs'] !== undefined) {
            this.configurationData = [];
            this.isConfigurationAvailable = true;
            this.configResponse = response['messageDefinitionDTOs'];
            this.buildConfigData(response['messageDefinitionDTOs']);
          }
        });
      }
    });
  }

  readFile = (eve) => {
    const file = eve.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = () => {
      console.log(fileReader.result);
    };
    fileReader.readAsText(file);
  }

  applyFilter = (event) => {
    const filterValue: any = this.searchValue as HTMLInputElement;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.tableData = this.dataSource.filteredData;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onFileUpload = (event) => {
    if (event.length > 0) {
      if (event[0].type === "application/json") {
        this.fileUploaded = event[0];
        const formData = new FormData();
        formData.append('file', this.fileUploaded);
        this.loadingIndicator.openLoadingIndicator();
        const selectedIndex = this.configResponse.findIndex(config => { return config.configId === this.selectedConfigIdToImport });
        const importConfigName = this.configResponse[selectedIndex].configuration.configNme;
        const displayApplication = this.configResponse[selectedIndex].configuration.displayApplication;
        const displayMessageType = this.configResponse[selectedIndex].configuration.displayMessageType;
        this.apiService.importConfiguration(formData, importConfigName, displayApplication, displayMessageType).subscribe((res) => {          
          this.isEdit = !this.isEdit;
          this.expandRow(this.importIndex);
          this.configResponse[selectedIndex].configuration = res;
          this.loadingIndicator.closeLoadingIndicator();
          this.notifyService.showNotification(popup.importSuccessMessage, 'success');
        }, (error) => {
          this.loadingIndicator.closeLoadingIndicator();
          if (error !== undefined && error.error !== undefined && error.error.message !== null) {
            this.notifyService.showNotification(error.error.message, 'error');
          }

        });
      }
      else {
        this.loadingIndicator.closeLoadingIndicator();
        this.notifyService.showNotification(popup.importFailureMessage, 'error');
      }
    }
  }

  getConfigurationList = (event) => {
    if (event) {
      this.apiService.getConfigurationList().subscribe((response: any) => {
        if (response !== undefined && response['messageDefinitionDTOs'] !== undefined) {
          this.configurationData = [];
          this.isConfigurationAvailable = true;
          this.configResponse = response['messageDefinitionDTOs'];
          this.buildConfigData(response['messageDefinitionDTOs']);
        }
      });
    }
  }
  fetchDataIndex = () => {
    let dataIndex;
    this.configResponse.forEach((ele, index) => {
      if (this.elementIdInConfigRes === ele['configId']) {
        dataIndex = index;
      }
    });
    return dataIndex;
  }

  downloadMessageDefinition = (configurationId) => {
    const selectedIndex = this.configResponse.findIndex(config => { return config.configId === configurationId });
    const exportConfigName = this.configResponse[selectedIndex].configuration.configNme;
    this.apiService.downloadMessageDefinition(configurationId).subscribe((response: any) => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      fileSaver.saveAs(blob, `${exportConfigName}.json`);
    }), (error: any) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  onPopUpClosed = () => {
    this.isEdit = !this.isEdit;
    this.tempPage = -1;
  }
}
