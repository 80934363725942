import { Component, Input, OnInit, Inject } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

export interface dialogData {
  partTitle: any;
  apiResponse: any;
}

@Component({
  selector: 'app-itemdetails',
  templateUrl: './itemdetails.component.html',
  styleUrls: ['./itemdetails.component.css']
})

export class ItemdetailsComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  columnNames: string[];
  //columnValues: any= [];;

  constructor(
    private dialogRef: MatDialogRef<ItemdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dialogData
  ) { }

  ngOnInit() {
    this.dataSource = this.data.apiResponse.itemDetails;
    this.columnNames = Object.keys(this.data.apiResponse.itemDetails[0]);
    this.data?.apiResponse?.itemDetails.forEach(element => {
      this.columnNames.forEach(colname => {
        if (element[colname] && element[colname] === 'null') {
          element[colname] = '';
        }
      })
    });
  }
  hide() {
    this.dialogRef.close();
  }
}