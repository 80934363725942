<div class="configuration-screen-container">
    <div class="sticky-header">
        <div class="configuration_heading">
            Message Type Definition
        </div>
        <div class="configuration_search_container">
            <div class="search_bar">
                <span class="search_text">Search for configuration by name or user:</span>
                <input autocomplete="off" type="text" [(ngModel)]="searchValue" (keyup)="applyFilter($event)"
                    class="form-control">
            </div>
            <div class="paginator">
                <mat-paginator appPagination [pageSizeOptions]="[40]" [hidePageSize]='true'
                    [hidden]="!isConfigurationAvailable" [pageSize]="pageSize" (page)="onPaginateChange($event)">
                </mat-paginator>
            </div>
            <div class="add_button_container">
                <button [disabled]="isRowExpanded" class="add_button" type="button" (click)="openPopup()">Add New Configuration</button>
            </div>
        </div>
    </div>

    <div class="configuration_body">
        <div class="container">
            <div class="config_table">
                <table mat-table [dataSource]="dataSource" matSort class="config_data" multiTemplateDataRows>
                    <ng-container matColumnDef="Configuration Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Configuration Name </th>
                        <td mat-cell *matCellDef="let element"> {{element['Configuration Name']}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Last Updated By">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated By </th>
                        <td mat-cell *matCellDef="let element"> {{element['Last Updated By']}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Last Updated Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated Date </th>
                        <td mat-cell *matCellDef="let element"> {{element['Last Updated Date'] | date:'MM/dd/yyyy hh:mm:ss a ':'EST5EDT'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element"> {{element['Status']}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Import">
                        <th mat-header-cell *matHeaderCellDef> Import </th>
                        <td mat-cell *matCellDef="let element">
                            <input #fileUpload autocomplete="off" type="file" id="file-upload"
                                (change)="onFileUpload($event.target.files)" />
                            <img src="/assets/img/file-import.svg" class="expandible-row icon"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element; getImportElement('Import', tableData.indexOf(element), element['Configuration Id'])" />
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Export">
                        <th mat-header-cell *matHeaderCellDef> Export </th>
                        <td mat-cell *matCellDef="let element">
                            <img src="/assets/img/file-export.svg" class="icon"
                                (click)="downloadMessageDefinition(element['Configuration Id'])" />
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="View">
                        <th mat-header-cell *matHeaderCellDef> View </th>
                        <td mat-cell *matCellDef="let element">
                            <img src="/assets/img/magnifier-lens.png" class="expandible-row icon"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element; getElement('View', tableData.indexOf(element),  element['Configuration Id'])" />
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Edit">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let element;let i = dataIndex;">
                            <img src="/assets/img/edit.svg" class="expandible-row icon"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element; getElement('Edit', tableData.indexOf(element),  element['Configuration Id'])" />
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element;let i=dataIndex;" [attr.colspan]="columnNames.length">
                            <div class="expandible-row-div" *ngIf="showDiv[i]" [attr.id]="i">
                                <app-config-fields [data]="clickedEle" [index]="rowNum"
                                    [configData] = "configResponse[tableIndex]"
                                    (closeDivEmission)="getCloseEvent($event, i)"
                                    (configResponse)="getConfigurationList($event)" (onPopUpClosed) = "onPopUpClosed()"></app-config-fields>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames;let i = dataIndex;"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                </table>
                <table class="clearT">
                    <tr *ngIf="!isConfigurationAvailable && !isFetching">
                        <td [attr.colspan]="columnNames.length">
                            No Results Were Found
                        </td>
                    </tr>
                    <tr *ngIf="tableData && tableData.length === 0" >
                        <td [attr.colspan]="columnNames.length">
                            No Results Were Found
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>