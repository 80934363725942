<div class="userPreference">
    <h1>Please Select Your Preferences:</h1>
    <div class="labels-input">
        <div class="label-name">
            <label>Application</label>
            <span class="red-star">*</span>
        </div>
        <mat-form-field floatLabel="never">
            <select matNativeControl [(ngModel)]="applicationName"  placeholder="Select Application" (change) = "updateSelectedApplication()">
                <option *ngFor="let app of grantedApplications" [value]="app.application">
                    {{app.application}}
                </option>
            </select>
        </mat-form-field>
    </div>

    <div class="labels-input">
        <div class="label-name">
            <label>Message Type</label>
            <span class="red-star">*</span>
        </div>
        <mat-form-field floatLabel="never">
            <select matNativeControl [(ngModel)]="messageType" [disabled]="isDisabled" [ngClass] = "{'field-color': isDisabled}" placeholder="Select Message Type" (change) = "updatedSelectedMessageType()">
                <option *ngFor="let msg of grantedMessages | orderBy :'messageType'" [value]="msg.messageType">
                    {{msg.messageType}}
                </option>
            </select>
        </mat-form-field>
    </div>

    <div class="labels-input">
        <div class="label-name">
            <label>Ship To</label>
        </div>
        <mat-form-field>
            <input autocomplete="off" [disabled]="isDisabled" [ngClass] = "{'field-color': isDisabled}" [(ngModel)]="shipTo" matInput type="text" #ref />
        </mat-form-field>
    </div>

    <div class="search-btns">
        <button (click)="saveUserPreferences()" [disabled]="isDisabled">Save</button>
        <button (click)="clear()" [disabled]="isDisabled">Clear</button>
    </div>

</div>