import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { popup } from '../config/pop-up-content.json';
import { LoaderService } from '../loader/loader.service';
import { NotificationService } from './notification.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthHtppInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);  

  constructor(private apiService: ApiService, private loadingService: LoaderService, private notifyService: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails && !req.url.includes('/oauth/token')) {
      req = this.addToken(req, userDetails);
    }
    return next.handle(req).pipe(catchError(error => {
    if (!req.url.includes('/oauth/token') && error instanceof HttpErrorResponse && error.status === 401 && error.error["error_description"].includes("Access token expired")) {
          return this.handle401Error(req, next);
      } else if(req.url.includes('/oauth/token')){              
        if(error.error.code === "400143" || error.error.code === "400142"){
            this.notifyService.showNotification(popup.userAuthenticationError, 'error',1800000);
          } else if(error.error.code === "400144"){              
            window.location.href = `${environment.redirectURL}`;
          } else {
            this.notifyService.showNotification(popup.errorNotification, 'error',1800000);
          }
          this.loadingService.closeLoadingIndicator();
      }else {
        if (error !== undefined && error.error.code !== "400123") { 
          this.loadingService.closeLoadingIndicator();
          this.notifyService.showNotification(popup.unableToProcessSystem, 'error');
        }
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const idTokenPayload = {
        "idToken": ""
      };

      return this.apiService.validateIDToken(idTokenPayload).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          const userDetails = response.body;
          sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
          sessionStorage.setItem("isUserLoggedIn", "true");
          this.refreshTokenSubject.next(userDetails);
          return next.handle(this.addToken(request, userDetails));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(userDetails => userDetails != null),
        take(1),
        switchMap(userDetails => {
          return next.handle(this.addToken(request, userDetails));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, userDetails: any) {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + userDetails['access_token'],
        iamUid: userDetails['iamUid']
      }
    });
  }
}