<div class="config-fields_container">
    <div class="heading" *ngIf="data !== 'New' && data !== 'New_Import'">
        <div class="heading_text">{{data}}</div>
        <div class="closeContent" (click)="closePopup()"><img src="/assets/img/close.svg"></div>
    </div>
    <div class="configuration_div">
        <div class="community_field">
            <span class="text">Community<span class="red-star">*</span></span>
            <mat-form-field [ngClass]="{'disableField': (data === 'View' || data === 'Edit' || data === 'Import')}">
                <select matNativeControl [(ngModel)]="communityId" (ngModelChange)="getCommunityValue($event)" required>
                    <option disabled="disabled" value="default" selected>Select Community</option>
                    <option *ngFor="let app of communityArray" [ngValue]="app.shortName">
                        {{app.shortName}} - {{app.communityName}}
                    </option>
                </select>
            </mat-form-field>
        </div>
        <div class="application_field">
            <span class="text">Application<span class="red-star">*</span></span>
            <mat-form-field [ngClass]="{'disableField': (data === 'View' || data === 'Edit' || data === 'Import')}">
                <select matNativeControl [(ngModel)]="applicationName" (ngModelChange)="getApplicationValue($event)"
                    required>
                    <option disabled="disabled" value="default" selected>Select Application</option>
                    <option *ngFor="let app of applicationArray" [ngValue]="app.shortName">
                        {{app.shortName}}
                    </option>
                </select>
            </mat-form-field>
        </div>
        <div class="message_type_field">
            <span class="text">Message Type<span class="red-star">*</span></span>
            <mat-form-field [ngClass]="{'disableField': (data === 'View' || data === 'Edit' || data === 'Import')}">
                <select matNativeControl [(ngModel)]="messageType" (ngModelChange)="getMessageValue($event)" required>
                    <option disabled="disabled" value="default" selected>Select Message Type</option>
                    <option *ngFor="let app of messageTypeArray" [ngValue]="app.shortName">
                        {{app.shortName}}
                    </option>
                </select>
            </mat-form-field>
            <!-- <mat-form-field appearance="fill" [ngClass]="{'disableField': (data === 'View')}" (change)="getMessageValue($event)">
                <input autocomplete="off" type="text" matInput   formControlName="myControl" [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]=displayFunction>
                    <mat-option *ngFor="let app of messageTypeArray" [Value]="app.shortName">
                        {{app.shortName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->
        </div>
    </div>
   <input #importFile autocomplete="off" type="file" id="import-file" (change)="onFileUpload($event.target.files)" />
    <div class="create_options" *ngIf="data === 'New' && isConfigValid && !showTabs">
        <div class="create-config">
            <button type="button" class="config_button" (click)="createConfig()"><i class="fa fa-plus-circle"></i>Create</button>
        </div>
        <div class="import-config">
            <button type="button" class="config_button" (click)="importConfig()"><img src="/assets/img/import.svg">Import</button>
        </div>
    </div>
    <div class="tabs_div" *ngIf="showTabs">
        <div class="delete_tabs" *ngIf="data !== 'New' && data !== 'View' && configStatus !== undefined">
            <button *ngIf="configStatus === 'Active'" type="button" class="soft_delete_button"
                (click)="softDeleteConfig($event)">Soft Delete</button>
            <button *ngIf="configStatus === 'Inactive'" type="button" class="hard_delete_button"
                (click)="hardDeleteConfig($event)">Hard Delete</button>
        </div>
        <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedTab">
            <mat-tab label="Search" disabled>
                <div class="search_fields_div">
                    <div class="search_heading">
                        <span class="field_name marginLeft_15">Field Name</span>
                        <span class="display_order marginLeft_15">Display Order</span>
                        <span class="data_ref marginLeft_15">Data Reference</span>
                        <span class="display_name marginLeft_15">Display Name</span>
                        <span class="help_text marginLeft_15">Help Text</span>
                    </div>
                    <div class="search_fields_wrapper">
                        <div class="search_fields_container"
                            *ngFor="let reqField of staticRequiredFields; let i = index;">
                            <div class="search_field_row">
                                <div class="field_name marginLeft_15">
                                    <span class="search_field_text">{{reqField}}<span class="red-star">*</span></span>
                                </div>
                                <div class="display_order marginLeft_15">
                                    <input autocomplete="off" type="number" ngDefaultControl class="disableField"
                                        value="{{i+1}}" maxlength="2" required />
                                </div>
                                <div class="data_ref marginLeft_15">
                                    <mat-form-field ngDefaultControl class="disableField">
                                        <select matNativeControl [(ngModel)]="reqFieldDataReference[i]">
                                            <option disabled="disabled" value="default" selected>Select Data Ref
                                            </option>
                                            <option *ngFor="let item of searchFieldsDataRefList"
                                                [ngValue]="item.jsonTag">
                                                {{item.jsonTag}}
                                            </option>
                                        </select>
                                    </mat-form-field>
                                </div>
                                <div class="display_name marginLeft_15">
                                    <input autocomplete="off" type="text" ngDefaultControl class="disableField" (keypress)="allowOnlyCharacters($event)" (paste)="allowOnlyCharacters($event)" [(ngModel)]="reqStaticDisplayName[i]" />
                                </div>
                                <div class="help_text marginLeft_15">
                                    <input autocomplete="off" type="text" ngDefaultControl [(ngModel)]="reqStatichelpText[i]" readonly/>
                                </div>
                            </div>
                        </div>
                        <div class="search_fields_container" *ngFor="let item of searchFieldsArray;let i = index;">
                            <div class="search_field_row" *ngIf="rowCount > -1">
                                <div class="field_name marginLeft_15">
                                    <span class="search_field_text">Search Field {{i + 1}}</span>
                                </div>
                                <div class="display_order marginLeft_15">
                                    <input autocomplete="off" type="number" #dynamicDisplayOrderEle ngDefaultControl
                                        id="dynamicDisplayOrder_{{i}}" (blur)="getDynamicDisplayOrder($event, i)"
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': (dynamicFieldDisplayOrderError[i] || dynamicFieldDisplayOrderDuplicateError[i])}"
                                        (keypress)="allowOnlyNumbers($event)" [(ngModel)]="dynamicFieldDisplayOrder[i]"
                                        maxlength = "1" 
                                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required (keyup)="checkDuplicateValues($event, i, 'search')" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="dynamicFieldDisplayOrderError[i]">Display
                                            Order is required.</span>
                                        <span class="errorMsgText"
                                            *ngIf="dynamicFieldDisplayOrderDuplicateError[i]">Duplicate Display Order is
                                            not allowed.</span>
                                    </div>
                                </div>
                                <div class="data_ref marginLeft_15">
                                    <mat-form-field ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': dynamicFieldDataReferenceError[i] || dynamicFieldDataReferenceDuplicateError[i]}">
                                        <select matNativeControl [(ngModel)]="dynamicFieldDataReference[i]"
                                            (ngModelChange)="getDynamicDataRef($event, i)">
                                            <option disabled="disabled" value="default" selected>Select Data Ref
                                            </option>
                                            <option *ngFor="let item of searchFieldsDataRefList"
                                                [ngValue]="item.jsonTag">
                                                {{item.jsonTag}}
                                            </option>
                                        </select>
                                    </mat-form-field>
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="dynamicFieldDataReferenceError[i]">Data
                                            Reference is required.</span>
                                        <span class="errorMsgText"
                                            *ngIf="dynamicFieldDataReferenceDuplicateError[i]">Duplicate Data Reference
                                            is not allowed.</span>

                                    </div>
                                </div>
                                <div class="display_name marginLeft_15">
                                    <input autocomplete="off" type="text" ngDefaultControl
                                        (keypress)="allowOnlyCharacters($event)" (paste)="allowOnlyCharacters($event)"
                                        [(ngModel)]="dynamicFieldDisplayName[i]" id="dynamicDisplayName_{{i}}"
                                        (blur)="getDynamicDisplayName($event, i)"
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': dynamicFieldDisplayNameError[i]}" 
                                        maxlength="25" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="dynamicFieldDisplayNameError[i]">Display Name
                                            is required.</span>
                                    </div>
                                </div>
                                <div class="help_text marginLeft_15">
                                    <input autocomplete="off" type="text" ngDefaultControl maxlength="120" id="dynamicHelpText_{{i}}"
                                        (blur)="getDynamicHelpText($event, i)" [(ngModel)]="dynamicFieldHelpText[i]"
                                        [ngClass]="{'disableField': (data === 'View')}" />
                                </div>
                                <div class="add_remove_div" *ngIf="data !== 'View'">
                                    <button class="add_search_field marginLeft_15" (click)="addSearchField()"
                                        title="Add Search Field" *ngIf="i < 6 && formStatus[i] && i===rowCount"><i
                                            class="fa fa-plus"></i></button>
                                            <button class="remove_search_field marginLeft_15" (click)="removeSearchField(i, false)"
                                        title="Remove Search Field" *ngIf="i > 0 || (data === 'Edit')"><i
                                            class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="search_fields_container" *ngIf="rowCount < 0">
                            <button class="add_search_field marginLeft_15" id="add_search_button"
                                title="Add Search Field" (click)="addSearchField()"><i class="fa fa-plus"></i></button>
                        </div>
                        <div class="search_fields_container" *ngFor="let field of staticSearchFields;let i = index;">
                            <div class="field_name marginLeft_15">
                                <span class="search_field_text">{{field}}<span class="red-star">*</span></span>
                            </div>
                            <div class="display_order marginLeft_15">
                                <input autocomplete="off" id="displayOrder_{{i}}" #displayOrderEle class="disableField"
                                    [ngClass]="{'errorField': displayOrderError[i]}" ngDefaultControl
                                    (blur)="getDisplayOrder($event, i)" [(ngModel)]="displayOrder[i]" type="number"
                                    required (keypress)="allowOnlyNumbers($event)" maxlength = "2"
                                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                                <div class="errorMsg">
                                    <span class="errorMsgText" *ngIf="displayOrderError[i]">Display Order is
                                        required.</span>
                                </div>
                            </div>
                            <div class="data_ref marginLeft_15">
                                <mat-form-field ngDefaultControl class="disableField"
                                    [ngClass]="{'errorField': dataReferenceError[i]}">
                                    <select matNativeControl (click)="checkError($event, i)"
                                        (ngModelChange)="getDataReference($event, i)" [(ngModel)]="dataReference[i]">
                                        <option disabled="disabled" value='default' selected>Select Data Ref</option>
                                        <option *ngFor="let item of searchFieldsDataRefList" [ngValue]="item.jsonTag">
                                            {{item.jsonTag}}
                                        </option>
                                    </select>
                                </mat-form-field>
                                <div class="errorMsg">
                                    <span class="errorMsgText" *ngIf="dataReferenceError[i]">Data Reference is
                                        required.</span>
                                </div>
                            </div>
                            <div class="display_name marginLeft_15">
                                <input autocomplete="off" type="text" ngDefaultControl id="displayName_{{i}}"
                                    (keypress)="allowOnlyCharacters($event)" (paste)="allowOnlyCharacters($event)" class="disableField"
                                    [ngClass]="{'errorField': displayNameError[i]}" (blur)="getDisplayName($event, i)"
                                    [(ngModel)]="displayName[i]" />
                                <div class="errorMsg">
                                    <span class="errorMsgText" *ngIf="displayNameError[i]">Display Name is
                                        required.</span>
                                </div>
                            </div>
                            <div class="help_text marginLeft_15">
                                <input autocomplete="off" type="text" ngDefaultControl maxlength="120"
                                    [ngClass]="{'disableField': (data === 'View'), 'errorField': helpTextError[i]}"
                                    (blur)="getHelpText($event, i)" [(ngModel)]="helpText[i]" />
                                <div class="errorMsg">
                                    <span class="errorMsgText" *ngIf="helpTextError[i]">Help Text is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Header" disabled>
                <div class="header_fields_div">
                    <div class="header_div_heading">
                        <span class="header_field_name marginLeft_15">Field Name</span>
                        <span class="header_display_order marginLeft_6">Display Order</span>
                        <span class="header_data_ref marginLeft_15">Data Reference</span>
                        <span class="header_display_name marginLeft_15">Display Name</span>
                        <span class="header_column_width marginLeft_15">Column Width</span>
                        <span class="header_sortable marginLeft_15">Sortable</span>
                        <span class="header_link_data_ref marginLeft_10">Link Data Reference</span>
                    </div>
                    <div class="header_fields_wrapper">
                        <div class="header_fields_container" *ngFor="let item of headerArray;let i = index;">
                            <div class="header_row">
                                <div class="header_field_name marginLeft_15">
                                    <span class="search_field_text">Results Column {{i + 1}}</span>
                                </div>
                                <div class="header_display_order marginLeft_6">
                                    <input autocomplete="off" ngDefaultControl #headerDisplayOrderEle type="number"
                                        (blur)="getHeaderDisplayOrder($event, i)" [(ngModel)]="headerDisplayOrder[i]"
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': (headerDisplayOrderError[i] || headerDisplayOrderDuplicateError[i])}"
                                        required (keypress)="allowOnlyNumbers($event)" maxlength = "2"
                                        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                         (keyup)="checkDuplicateValues($event, i, 'header')" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="headerDisplayOrderError[i]">Display Order is
                                            required.</span>
                                        <span class="errorMsgText" *ngIf="headerDisplayOrderDuplicateError[i]">Duplicate
                                            Display Order is not allowed.</span>
                                    </div>
                                </div>
                                <div class="header_data_ref marginLeft_15">
                                    <mat-form-field ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View' || disableLink[i] || headerTabStaticFields.indexOf(headerDataReference[i]) != -1), 'errorField': (headerDataReferenceError[i] || headerDataReferenceDuplicateError[i])}">
                                        <select matNativeControl (ngModelChange)="getHeaderDataRefValue($event, i)"
                                            [(ngModel)]="headerDataReference[i]">
                                            <option disabled="disabled" value='default' selected>Select Data Ref
                                            </option>
                                            <option *ngFor="let item of resultFieldsDataRefList"
                                                [ngValue]="item.jsonTag">
                                                {{item.jsonTag}}
                                            </option>
                                        </select>
                                    </mat-form-field>
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="headerDataReferenceError[i]">Data Reference is
                                            required.</span>
                                        <span class="errorMsgText"
                                            *ngIf="headerDataReferenceDuplicateError[i]">Duplicate Data Reference is not
                                            allowed.</span>
                                    </div>
                                </div>
                                <div class="header_display_name marginLeft_15">
                                    <input autocomplete="off" ngDefaultControl id="headerDisplayName_{{i}}" type="text"
                                        (blur)="getHeaderDisplayName($event, i)" [(ngModel)]="headerDisplayName[i]"
                                        (keypress)="allowOnlyCharacters($event)" (paste)="allowOnlyCharacters($event)"
                                        [ngClass]="{'disableField': (data === 'View' || headerTabStaticFields.indexOf(headerDataReference[i]) != -1), 'errorField': headerDisplayNameError[i]}" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="headerDisplayNameError[i]">Display Name is
                                            required.</span>
                                    </div>
                                </div>
                                <div class="header_column_width marginLeft_15">
                                    <input autocomplete="off" ngDefaultControl id="headerColumnWidth{{i}}" type="text"
                                    (blur)="getHeaderColumnWidth($event, i)" [(ngModel)]="headerColumnWidth[i]"
                                     (keypress)="allowOnlyNumbers($event)" maxlength="3"
                                    [ngClass]="{'disableField': (data === 'View' || headerTabStaticFields.indexOf(headerColumnWidth[i]) != -1), 'errorField': headerColumnWidthError[i]}" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="headerColumnWidthError[i]">Column Width is
                                            required.</span>
                                            <span class="errorMsgText" *ngIf="headerColumnWidthValidationError[i]">Column Width should be greater than 20px.</span>
                                    </div>
                                </div>
                                <div class="header_sortable marginLeft_15" [ngSwitch]="headerDataReference[i]">
                                    <mat-radio-group ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': headerSortableError[i]}"
                                        (change)="getHeaderSortableValue($event, i)" [(ngModel)]="headerSortable[i]" *ngSwitchCase= "'meta.dlvyStat'">
                                        <mat-radio-button value="true"disabled="disabled">True</mat-radio-button>
                                        <mat-radio-button [value]="headerSortable[i]" [checked] disabled="disabled">False</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': headerSortableError[i]}"
                                        (change)="getHeaderSortableValue($event, i)" [(ngModel)]="headerSortable[i]" *ngSwitchCase= "'meta.dlvyDte'">
                                        <mat-radio-button value="true"disabled="disabled">True</mat-radio-button>
                                        <mat-radio-button [value]="headerSortable[i]" [checked] disabled="disabled">False</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': headerSortableError[i]}"
                                        (change)="getHeaderSortableValue($event, i)" [(ngModel)]="headerSortable[i]"  *ngSwitchDefault>
                                        <mat-radio-button value="true">True</mat-radio-button>
                                        <mat-radio-button value="false">False</mat-radio-button>
                                    </mat-radio-group>
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="headerSortableError[i]">Sortable value is
                                            required.</span>
                                    </div>
                                </div>
                                <div class="header_link_data_ref marginLeft_10">
                                    <mat-checkbox class="link_checkbox"
                                        *ngIf="isLinkSelected[i] && headerDataReference[i] !=='meta.msgId'"
                                        [ngClass]="{'disableField': (data === 'View' ||  !isLinkSelected[i] || showDetail || configStatus === 'Active' || configStatus === 'Inactive'),
                                                    'notDisabledField': headerLinkReference[i], 'viewLinkDisabled': (data === 'View' || configStatus === 'Active' || configStatus === 'Inactive')}"
                                        (change)="getHeaderLinkReference($event, i)"
                                        [(ngModel)]="headerLinkReference[i]" [checked]="headerLinkReference[i]">
                                    </mat-checkbox>
                                    <mat-radio-group ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': headerLinkDataRefPayloadTypeError[i]}"
                                        (change)="getHeaderLinkDataRefPayloadTypeValue($event, i)"
                                        [(ngModel)]="headerLinkdataRefPayloadType[i]"
                                        *ngIf="headerDataReference[i] === 'meta.msgId'">
                                        <mat-radio-button value="sender">Inbound</mat-radio-button>
                                        <mat-radio-button value="receiver" style="margin-left: 10px;">Outbound
                                        </mat-radio-button>
                                    </mat-radio-group>

                                </div>
                                <div class="add_remove_div" *ngIf="data !== 'View'">
                                    <button class="add_search_field marginLeft_15" (click)="addHeaderField()"
                                        title="Add Result Column"
                                        *ngIf="i < (headerLength-1) && headerStatus[i] && i===headerCount"><i
                                            class="fa fa-plus"></i></button>
                                    <button class="remove_search_field marginLeft_15" (click)="removeHeaderField(i)"
                                        title="Remove Result Column" *ngIf="(headerTabStaticFields.indexOf(headerDataReference[i]) == -1 && !disableLink[i] || duplicateStaticValue[i])"><i
                                            class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="search_fields_container" *ngIf="headerCount < 0">
                            <button class="add_search_field marginLeft_15" id="add_header_button"
                                title="Add Result Column" (click)="addHeaderField()"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Detail" *ngIf="showDetail" disabled>
                <div class="detail_fields_div">
                    <div class="detail_div_heading">
                        <span class="detail_field_name marginLeft_15">Field Name</span>
                        <span class="detail_display_order marginLeft_15">Display Order</span>
                        <span class="detail_data_ref marginLeft_15">Data Reference</span>
                        <span class="detail_display_name marginLeft_15">Display Name</span>
                    </div>
                    <div class="detail_fields_wrapper">
                        <div class="detail_fields_container" *ngFor="let item of detailArray;let i = index;">
                            <div class="detail_row">
                                <div class="detail_field_name marginLeft_15">
                                    <span class="search_field_text">Results Column {{i + 1}}</span>
                                </div>
                                <div class="detail_display_order marginLeft_15">
                                    <input autocomplete="off" type="number" #detailDisplayOrderEle
                                        (blur)="getDetailDisplayOrder($event, i)" [(ngModel)]="detailDisplayOrder[i]"
                                        id="detailDisplayOrder_{{i}}"
                                        [ngClass]="{'disableField': (data === 'View'), 'errorField': (detailDisplayOrderError[i] || detailDisplayOrderDuplicateError[i])}"
                                        required (keypress)="allowOnlyNumbers($event)" maxlength = "2"
                                        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        (keyup)="checkDuplicateValues($event, i, 'detail')" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="detailDisplayOrderError[i]">Display Order is
                                            required.</span>
                                        <span class="errorMsgText" *ngIf="detailDisplayOrderDuplicateError[i]">Duplicate
                                            Display Order is not allowed.</span>
                                    </div>
                                </div>
                                <div class="detail_data_ref marginLeft_15">
                                    <mat-form-field ngDefaultControl
                                        [ngClass]="{'disableField': (data === 'View'||item.linkable), 'errorField': (detailDataReferenceError[i] || detailDataReferenceDuplicateError[i])}">
                                        <select matNativeControl (ngModelChange)="getDetailDataRefValue($event, i)"
                                            [(ngModel)]="detailDataReference[i]">
                                            <option disabled="disabled" value="default" selected>Select Data Ref
                                            </option>
                                            <option *ngFor="let item of detailFieldsDataRefList"
                                                [ngValue]="item.jsonTag">
                                                {{item.jsonTag}}
                                            </option>
                                        </select>
                                    </mat-form-field>
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="detailDataReferenceError[i]">Data Reference is
                                            required.</span>
                                        <span class="errorMsgText"
                                            *ngIf="detailDataReferenceDuplicateError[i]">Duplicate Data Reference is not
                                            allowed.</span>
                                    </div>
                                </div>
                                <div class="detail_display_name marginLeft_15">
                                    <input autocomplete="off" type="text" id="detailDisplayName_{{i}}"
                                        (blur)="getDetailDisplayName($event, i)" [(ngModel)]="detailDisplayName[i]"
                                        (keypress)="allowOnlyCharacters($event)" (paste)="allowOnlyCharacters($event)"
                                        [ngClass]="{'disableField': (data === 'View'||item.linkable), 'errorField': detailDisplayNameError[i]}" 
                                        maxlength="25" />
                                    <div class="errorMsg">
                                        <span class="errorMsgText" *ngIf="detailDisplayNameError[i]">Display Name is
                                            required.</span>
                                    </div>
                                </div>
                                
                                <div class="add_remove_div" *ngIf="data !== 'View'">
                                    <button class="add_search_field marginLeft_15" (click)="addDetailField()"
                                        title="Add Result Column"
                                        *ngIf="i < (detailLength - 1) && detailStatus[i] && i===detailCount"><i
                                            class="fa fa-plus"></i></button>
                                    <button class="remove_search_field marginLeft_15" (click)="removeDetailField(i)"
                                        title="Remove Result Column"
                                        *ngIf="!item.linkable"><i class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="search_fields_container" *ngIf="detailCount < 0">
                            <button class="add_search_field marginLeft_15" id="add_detail_button"
                                (click)="addDetailField()" title="Add Search Field"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Audit Notes" disabled>
                <div class="audit-notes-container">
                    <div class="audit_notes_div" *ngIf="data !== 'View'">
                        <h1>Audit Notes:</h1>
                        <mat-form-field [ngClass]="{'disableField': (data === 'View')}" #auditNoteRef>
                            <textarea matInput maxlength="200" [(ngModel)]="auditNotes"
                                (blur)="getAuditNotes($event)"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="existing-logs" *ngIf="data !== 'New'">
                        <h1>Existing Notes:</h1>
                        <p>{{logs}}</p>
                    </div>
                    <div class="clear"></div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="footer-section" *ngIf="data === 'Import' || data === 'Edit' || (data === 'View' && buttonText === 'Next')">
        <button type="button" class="back_button" *ngIf= "tabLabel != undefined && tabLabel > 0" (click)="validateOnBackButtonClick()">Back</button>
        <button type="button" class="save_button" (click)="validateOnButtonClick(buttonText)">{{buttonText}}</button>
    </div>

    <div class="footer-section" *ngIf="(data === 'View' && buttonText != 'Next')">
        <button type="button" class="back_button" *ngIf= "tabLabel != undefined && tabLabel > 0" (click)="validateOnBackButtonClick()">Back</button>
    </div>
</div>